import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';


const WebmeglerBoardsSinglePreview: React.FC<IExtendedPreviewPropsModel> = ({
  employeeAvatar,
  employeeEmail,
  employeeName,
  employeePhone,
}): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer?.creativeFormsState?.boards?.single?.media);
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (employeeAvatar instanceof File) {
      const url = URL.createObjectURL(employeeAvatar);
      setAvatarUrl(url);
      return () => URL.revokeObjectURL(url);
    } else {
      setAvatarUrl(employeeAvatar);
    }
  }, [employeeAvatar]);


  return (
    <Grid container sx={{ overflow: 'hidden', display: 'block', width: '400px', boxShadow: 'none' }}>
      {media?.preSignedUrl ? (
        <Grid
          item
          xs={12}
          sx={{
            backgroundImage: `url(${media?.preSignedUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: '400px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h1"
            top='-50px'
            sx={{
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              padding: '0 190px',
              fontFamily: 'Saol Display, serif',
              marginBottom:'145px'
            }}
          >
            SOLGT
          </Typography>
        </Grid>
      ) : (
        <EmptyImageCard />
      )}

      <Grid item xs={12} sx={{ backgroundColor: '#0a1119', position: 'relative', height:'400px' }}>
        <Box sx={{ position: 'absolute', top: '-25px', left: '50%', transform: 'translateX(-50%)' }}>
          <img
            style={{ width: '50px', height: '50px' }}
            src="/images/boards/boards_eie_logo.png"
            alt="logo"
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ maxWidth: '300px', width: '300px', height: '400px', backgroundSize: 'cover' }}
            src={avatarUrl || undefined}
            alt="employee avatar"
          />
        </Box>
        <Typography sx={{ display: 'flex', justifyContent: 'center', marginTop: '-80px' }}>
          <Typography>
            <Typography variant="h3" color="white" display="flex" justifyContent="center" fontFamily='Maison Neue'>
              {employeeName}
            </Typography>
            <Typography color="white" display='flex' justifyContent='center' fontFamily='Maison Neue'>
              {employeeEmail} / {employeePhone}
            </Typography>
            <Typography color="white" display="flex" justifyContent="center" fontFamily='Maison Neue'>
              EIE eiendomsmegling
            </Typography>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default WebmeglerBoardsSinglePreview;
