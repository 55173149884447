import { useParams } from 'react-router-dom';
import UserDetails from '../../../../shared/components/users/user-profile/UserDetails';
import React from 'react';
import {
  useAdminCreateUserProfileMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from '@/shared/services/api/user-profiles.api';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const AdminUserProfilePage: React.FC = (): JSX.Element => {
  const { userId } = useParams();
  const [createUserProfile, { isLoading: adminCreatingUserProfileLoading }] = useAdminCreateUserProfileMutation();
  const [adminUpdateUserProfile, { isLoading: adminUpdatingUserProfileLoading, isSuccess: adminUpdateSuccess }] =
    useUpdateUserProfileMutation();

  const { t } = useTranslation();

  const {
    data: profileDataFromAdmin,
    isLoading: adminUserProfileLoading,
    isFetching: adminUserProfileLoadingFetching,
  } = useGetUserProfileQuery({ id: userId! }, { skip: !userId });

  const handleSubmit = async (values: FormData) => {
    if (profileDataFromAdmin?.id) {
      await toast.promise(adminUpdateUserProfile({ id: userId!, values }), {
        success: t('updatedSuccessfully'),
        error: t('errorWhileUpdating'),
        loading: t('updating'),
      });
      return;
    }

    toast.promise(createUserProfile({ id: userId!, values }), {
      success: t('createdSuccessfully'),
      error: t('errorWhileCreating'),
      loading: t('creating'),
    });
  };

  return (
    <div>
      <UserDetails
        updateSuccess={adminUpdateSuccess}
        userProfile={profileDataFromAdmin}
        loading={adminUserProfileLoading}
        actionLoading={adminUpdatingUserProfileLoading}
        handleSubmitFn={handleSubmit}></UserDetails>
    </div>
  );
};

export default AdminUserProfilePage;
