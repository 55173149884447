import { IOfficePlatformConfigsModel } from './../../../shared/models/offices/office-platform-configs.model';
import { api } from '../../../shared/services/api/api';
import { IOfficeMetaConfigFormValues } from '../../pages/office-platform-configs/components/MetaConfigForm';
import { IOfficeBidtheatreConfigFormValues } from '@/admin/pages/office-platform-configs/components/BidtheatreConfigForm';
import { IOfficeBoardsConfigFormValues } from '@/admin/pages/office-platform-configs/components/BoardsConfigForm';

const baseURL = 'admin/office-platform-configs';

export const officesPlatformConfigsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlatformConfigsByOfficesId: build.query<IOfficePlatformConfigsModel, { id: string }>({
      query: ({ id }) => ({
        url: `admin/offices/${id}/platform-configurations`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'OfficePlatformsConfigs', id: arg.id }],
    }),
    createOfficeMetaConfig: build.mutation<void, { values: IOfficeMetaConfigFormValues; officeId: string }>({
      query: ({ values, officeId }) => ({
        url: `${baseURL}/meta`,
        method: 'POST',
        body: { ...values, officeId },
      }),
      invalidatesTags: ['OfficePlatformsConfigs'],
    }),
    updateOfficeMetaConfig: build.mutation<void, { values: IOfficeMetaConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/meta/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
    createOfficeBidtheatreConfig: build.mutation<void, { values: IOfficeBidtheatreConfigFormValues; officeId: string }>({
      query: ({ values, officeId }) => ({
        url: `${baseURL}/bidtheatre`,
        method: 'POST',
        body: { ...values, officeId },
      }),
      invalidatesTags: ['OfficePlatformsConfigs'],
    }),
    updateOfficeBidtheatreConfig: build.mutation<void, { values: IOfficeBidtheatreConfigFormValues; officeId: string }>({
      query: ({ values, officeId }) => ({
        url: `${baseURL}/bidtheatre`,
        method: 'POST',
        body: {...values,officeId},
      }),
    }),
    createOfficeBoardsConfig: build.mutation<void, { values: IOfficeBoardsConfigFormValues; officeId: string}>({
      query: ({ values, officeId }) => ({
        url: `${baseURL}/dooh`,
        method: 'POST',
        body: { ...values, officeId },
      }),
      invalidatesTags: ['OfficePlatformsConfigs'],
    }),
    updateOfficeBoardsConfig: build.mutation<void, { values: IOfficeBoardsConfigFormValues; officeId: string}>({
      query: ({ values, officeId }) => ({
        url: `${baseURL}/dooh`,
        method: 'POST',
        body: {...values,officeId },
      }),
    }),
  }),
});

export const {
  useCreateOfficeMetaConfigMutation,
  useGetPlatformConfigsByOfficesIdQuery,
  useUpdateOfficeMetaConfigMutation,
  useCreateOfficeBidtheatreConfigMutation,
  useUpdateOfficeBidtheatreConfigMutation,
  useCreateOfficeBoardsConfigMutation,
  useUpdateOfficeBoardsConfigMutation
} = officesPlatformConfigsApi;
