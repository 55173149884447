import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { IDeltaStatsEntryModel } from '../../models/advertisements/statistics/delta-stats-entry.model';
import IDeltaStatsModel from '../../models/advertisements/statistics/delta-stats.model';
import { AdsClick, Newspaper, Visibility } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface IDeltaSummaryStatsProps {
  deltaStats: IDeltaStatsModel;
}

function createStatsValues(deltaStats: IDeltaStatsModel) {
  const { regular, bySites } = deltaStats;
  const res = [];

  if (regular?.length > 0) {
    res.push(...generateObject(regular));
  }

  if (bySites?.length > 0) {
    res.push(...generateObject(bySites));
  }

  return res;
}

function generateObject(stats: IDeltaStatsEntryModel[]) {
  return stats.map((el) => {
    const {
      name,
      entries: { clicks, impressions },
    } = el;

    return { name, clicks, impressions };
  });
}

const DeltaSummaryStats: FC<IDeltaSummaryStatsProps> = (props: IDeltaSummaryStatsProps): JSX.Element => {
  const { deltaStats } = props;
  const stats = createStatsValues(deltaStats);

  const { t } = useTranslation();

  return (
    <div>
      {(deltaStats?.regular || deltaStats.bySites) && (
        <TableContainer component={Paper}>
          <Table size="small" aria-label="delta-table">
            <TableHead sx={{ backgroundColor: '#162831' }}>
              <TableRow>
                <TableCell sx={{ color: '#fff' }} align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <Newspaper sx={{ fontSize: '15px', mr: 0.5 }} />
                    {t('name')}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#fff' }} align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <AdsClick sx={{ fontSize: '15px', mr: 0.5 }} />
                    {t('clicks')}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#fff' }} align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <Visibility sx={{ fontSize: '15px', mr: 0.5 }} />
                    {t('impressions')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(stats)
                .slice(0, 5)
                .map((row, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.clicks}</TableCell>
                    <TableCell align="left">{row.impressions}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DeltaSummaryStats;
