import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MaterialReactTable from 'material-react-table';
import React, { FC } from 'react';
import orderOverviewTableColumns from '@/shared/constants/order-overview-table-columns';
import { IOrderOverviewModel } from '@/shared/models/orders/order-overview.model';
import { IBasePaginatedModel } from '@/shared/models/base.paginated.model';
import { OnChangeFn } from '@tanstack/table-core/src/types';
import { PaginationState } from '@tanstack/table-core/build/lib/features/Pagination';
import { IBaseOrderOverviewActionButtonsProps } from '@/shared/components/order-overviews-tables/BaseOrderOverviewActionButtons';
import CommonButton from '@/shared/components/CommonButton';
import { useTranslation } from 'react-i18next';
import { MRT_Localization_NO } from 'material-react-table/locales/no';
import { TableChart } from '@mui/icons-material';

export interface IRTQOrdersTableProps {
  actionButtonsRenderFn: React.FC<IBaseOrderOverviewActionButtonsProps>;
  tableDescription: string;
  data: IBasePaginatedModel<IOrderOverviewModel> | null;
  isError: boolean;
  refetchFn: () => void;
  isLoading: boolean;
  isFetching: boolean;
  pagination: PaginationState;
  setPagination: OnChangeFn<PaginationState>;
  columnFilters: any[];
  setColumnFilters: (filters: any) => void;
  sorting: any[];
  setSorting: (sorting: any) => void;
  search: string;
  setSearch: (search: string) => void;
  exportBtnClick?: () => void;
}

const RTQOrdersTable: FC<IRTQOrdersTableProps> = (props: IRTQOrdersTableProps): JSX.Element => {
  const {
    actionButtonsRenderFn: ActionButtons,
    tableDescription,
    data,
    isError,
    refetchFn,
    isLoading,
    isFetching,
    pagination,
    setPagination,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
    search,
    setSearch,
    exportBtnClick,
  } = props;

  const { t, i18n } = useTranslation();

  orderOverviewTableColumns.forEach((col) => {
    if (col.header) {
      col.header = t(col.id as string);
    }
  });

  return (
    <Box>
      <Typography color="secondary" variant="h4" sx={{ mb: '10px' }}>
        {tableDescription}
      </Typography>
      <MaterialReactTable
        columns={orderOverviewTableColumns}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            size: 100, //make actions column wider
          },
        }}
        data={data?.items ?? []}
        localization={MRT_Localization_NO}
        initialState={{ showColumnFilters: false }}
        manualFiltering
        manualPagination
        manualSorting
        muiSearchTextFieldProps={{ color: 'secondary' }}
        muiTableHeadCellFilterTextFieldProps={{ color: 'secondary' }}
        muiTopToolbarProps={{ color: 'secondary' }}
        muiLinearProgressProps={{
          color: 'secondary',
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: 'error',
                children: 'Error loading data',
              }
            : undefined
        }
        // @ts-ignore
        onColumnFiltersChange={setColumnFilters}
        // @ts-ignore
        onSortingChange={setSorting}
        onGlobalFilterChange={setSearch}
        onPaginationChange={setPagination}
        enableRowActions
        enableSorting={false}
        positionActionsColumn="first"
        // @ts-ignore
        renderRowActions={({ row }) => <ActionButtons row={row} />}
        enableRowVirtualization
        renderTopToolbarCustomActions={() => (
          <Box>
            <Tooltip arrow title="Refresh Data">
              <IconButton onClick={refetchFn}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
            {exportBtnClick && (
              <CommonButton startIcon={<TableChart />} onClick={exportBtnClick}>
                Export data
              </CommonButton>
            )}
          </Box>
        )}
        // @ts-ignore
        rowCount={data?.totalCount ?? 0}
        state={{
          columnFilters,
          globalFilter: search,
          isLoading: isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isFetching,
          showSkeletons: false,
          sorting,
          density: 'compact',
        }}
      />
    </Box>
  );
};

const queryClient = new QueryClient();

const OverviewOrdersTableProvider: React.FC<IRTQOrdersTableProps> = ({
  actionButtonsRenderFn,
  tableDescription,
  refetchFn,
  data,
  isError,
  isLoading,
  isFetching,
  pagination,
  setPagination,
  columnFilters,
  setColumnFilters,
  sorting,
  setSorting,
  search,
  setSearch,
  exportBtnClick,
}): JSX.Element => (
  <QueryClientProvider client={queryClient}>
    <RTQOrdersTable
      tableDescription={tableDescription}
      actionButtonsRenderFn={actionButtonsRenderFn}
      data={data}
      isError={isError}
      refetchFn={refetchFn}
      isLoading={isLoading}
      isFetching={isFetching}
      pagination={pagination}
      setPagination={setPagination}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      sorting={sorting}
      setSorting={setSorting}
      search={search}
      setSearch={setSearch}
      exportBtnClick={exportBtnClick}
    />
  </QueryClientProvider>
);

export default OverviewOrdersTableProvider;
