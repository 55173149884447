import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ICarouselPreviewProps } from '../carousel-preview.props';
import EmptyImageCard from '../EmptyImageCard';

const MetaCarouselPreview: React.FC<ICarouselPreviewProps> = ({ items }): JSX.Element => {
  const matches = useMediaQuery('(min-width:600px)');

  return (
    <Box component="div" sx={{ maxWidth: '632px', height: '100%', p: 1 }}>
      {items?.length > 0 ? (
        <div>
          <Swiper slidesPerView={matches ? 2 : 1} spaceBetween={30} style={{ cursor: 'grab' }}>
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <Grid container>
                  <Grid item xs={12}>
                    {item.creativeMedia?.preSignedUrl ? (
                      <img
                        src={item.creativeMedia?.preSignedUrl}
                        style={{
                          width: '100%',
                          height: '250px',
                          objectFit: 'cover',
                          borderRadius: '15px 15px 0 0',
                        }}
                        alt="carousel"></img>
                    ) : (
                      <EmptyImageCard />
                    )}
                  </Grid>
                  <Grid container sx={{ mt: '-7px' }}>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        backgroundColor: '#f2f3f5',
                        p: 2,
                        borderRadius: '0 0 15px 15px',
                        mb: 2,
                      }}>
                      <Typography variant="h6" fontWeight="bold">
                        {item?.headline?.length > 0 ? item.headline : 'Headline'}
                      </Typography>
                      <Typography variant="caption">
                        {item?.description?.length > 0 ? item?.description : 'Description'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default MetaCarouselPreview;
