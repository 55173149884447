import {IBasePaginatedModel} from '../../../shared/models/base.paginated.model';
import {IPaginatedQueryParams} from '../../../shared/models/paginated-query-params';
import {TypeIdsDefinitions, api} from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IAdvertisementOverviewAdminModel } from '@/shared/models/advertisements/admin/advertisement-overview-admin.model';

const baseUrl = 'admin/advertisements';

export const advertisementsAdminApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedAdvertisementsAdmin: build.query<IBasePaginatedModel<IAdvertisementOverviewAdminModel>, IPaginatedQueryParams>({
      query: ({page, pageSize, search}) => ({
        url: baseUrl,
        params: {page, pageSize, search},
      }),
      providesTags: (result) =>
        providesList(
          result?.items ?? [],
          'AdvertisementsAdmin',
          TypeIdsDefinitions.partialListTypeId
        ),
    }),
    putAdvertisementInProgressStatus: build.mutation<void, {id: string}>({
      query: ({id}) => ({
        url: `${baseUrl}/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ['AdvertisementsAdmin']
    }),
  }),
});

export const {
  useGetPaginatedAdvertisementsAdminQuery,
  usePutAdvertisementInProgressStatusMutation
} = advertisementsAdminApi;
