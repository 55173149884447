import { IRoute } from "../../shared/models/route.model";
import BudgetsDataTable from "../pages/budgets/BudgetsTablePage";
import CreateEditBudgetPage from "../pages/budgets/CreateEditBudgetPage";

export const ROUTE_BUDGETS_DECLARATIONS = Object.freeze({
  AdminBudgetsByCompanyPage: "companies/:companyId/budgets",
  AdminBudgetsNewPage: "budgets/new",
  AdminBudgetsEditPage: "budgets/:budgetId/edit",
});

export const BudgetsRoutes: IRoute[] = [
  {
    path: ROUTE_BUDGETS_DECLARATIONS.AdminBudgetsByCompanyPage,
    component: BudgetsDataTable,
  },
  {
    path: ROUTE_BUDGETS_DECLARATIONS.AdminBudgetsNewPage,
    component: CreateEditBudgetPage,
  },
  {
    path: ROUTE_BUDGETS_DECLARATIONS.AdminBudgetsEditPage,
    component: CreateEditBudgetPage,
  },
];
