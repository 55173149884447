import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditBudgetForm from './components/CreateEditBudgetForm';

const CreateEditBudgetPage = (): JSX.Element => {
  const { budgetId } = useParams();

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          <CreateEditBudgetForm isUpdating={!!budgetId}></CreateEditBudgetForm>
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default CreateEditBudgetPage;
