import React, { FunctionComponent } from 'react';
import { IDeltaMediaConfigModel } from '../../../../shared/models/companies/platform-configs/delta-media-config.model';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Card, CardHeader, Grid, IconButton, Typography } from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CommonButton from '../../../../shared/components/CommonButton';
import CommonTextField from '../../../../shared/components/CommonTextField';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  useCreateDeltaConfigMutation,
  useUpdateDeltaConfigMutation,
} from '../../../services/http/companies-platform-configs.api';
import toast from 'react-hot-toast';

export interface IDeltaMediaConfigFormProps {
  initialValues?: IDeltaMediaConfigModel | null;
  companyId: string;
}

export interface IDeltaConfigFormValues {
  clientId: string;
  apiKey: string | null;
  mediaAgencyId: string | null;
  deltaUiTemplateConfigs: IDeltaUiTemplateFormValues[];
}

interface IDeltaUiTemplateFormValues {
  templateId: string;
  templateTitle: string;
}

const DeltaMediaConfigForm: FunctionComponent<IDeltaMediaConfigFormProps> = (
  props: IDeltaMediaConfigFormProps
): JSX.Element => {
  const { companyId, initialValues } = props;
  const [createDeltaConfig, { isLoading: deltaCreationLoading }] = useCreateDeltaConfigMutation();
  const [updateDeltaConfig, { isLoading: deltaUpdateLoading }] = useUpdateDeltaConfigMutation();

  const { getFieldProps, touched, errors, handleSubmit, handleChange, getFieldMeta, setFieldValue } = useFormik({
    initialValues: {
      clientId: initialValues?.clientId || '',
      apiKey: initialValues?.apiKey || '',
      mediaAgencyId: initialValues?.mediaAgencyId || '',
      deltaUiTemplateConfigs: initialValues?.deltaUiTemplateConfigs.map((el) => ({
        templateId: el.templateId,
        templateTitle: el.templateTitle,
      })) || [{ templateId: '', templateTitle: '' }],
    },
    validationSchema: yup.object().shape({
      clientId: yup.string().required('Client id is required'),
      apiKey: yup.string(),
      mediaAgencyId: yup.string(),
      deltaUiTemplateConfigs: yup
        .array()
        .of(
          yup.object().shape({
            templateId: yup.string().required('Template id is required'),
            templateTitle: yup.string().required('Template title is required'),
          })
        )
        .min(1)
        .required(),
    }),
    onSubmit: async (values: IDeltaConfigFormValues) => {
      !initialValues?.id
        ? await createDeltaConfig({ values, companyId }).unwrap()
        : await updateDeltaConfig({ values, id: initialValues.id }).unwrap();
      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });

  const addValues = async () => {
    await setFieldValue('deltaUiTemplateConfigs', [
      ...getFieldMeta('deltaUiTemplateConfigs').value,
      {
        templateId: '',
        templateTitle: '',
      },
    ]);
  };

  const removeValues = async (index: number) => {
    await setFieldValue(
      'deltaUiTemplateConfigs',
      getFieldMeta('deltaUiTemplateConfigs').value.filter((_: IDeltaUiTemplateFormValues, idx: number) => {
        return index !== idx;
      })
    );
  };

  return (
    <div>
      <div>
        <Box>
          <Box
            sx={{
              width: 100,
              mb: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <NewspaperIcon fontSize="large" color="secondary" />
            <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
              Delta
            </Typography>
          </Box>
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <CommonTextField
              {...getFieldProps('clientId')}
              label="Client Id"
              type="text"
              required
              error={Boolean(touched.clientId && errors.clientId)}
              helperText={touched.clientId && (errors?.clientId as string)}
            />
            <CommonTextField
              {...getFieldProps('apiKey')}
              sx={{ mt: '15px' }}
              label="Api key"
              type="text"
              error={Boolean(touched.apiKey && errors.apiKey)}
              helperText={touched.apiKey && (errors?.apiKey as string)}
            />
            <CommonTextField
              {...getFieldProps('mediaAgencyId')}
              sx={{ mt: '15px' }}
              label="Media Agency Id"
              type="text"
              error={Boolean(touched.mediaAgencyId && errors.mediaAgencyId)}
              helperText={touched.mediaAgencyId && (errors?.mediaAgencyId as string)}
            />
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }} color="secondary">
              Ui id and template name
            </Typography>
            <Grid container spacing={3}>
              {getFieldMeta('deltaUiTemplateConfigs').value.map((el: IDeltaUiTemplateFormValues, key: number) => {
                return (
                  <Grid item xs={12} md={4} key={key}>
                    <div>
                      <Card variant="outlined" sx={{ mt: 3, pr: 2, pl: 2, pb: 2 }}>
                        <CardHeader
                          action={
                            <Box
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                              }}>
                              <IconButton onClick={() => removeValues(key)}>
                                <CancelIcon />
                              </IconButton>
                            </Box>
                          }></CardHeader>
                        <CommonTextField
                          sx={{ mt: '15px' }}
                          type="text"
                          {...getFieldProps(`deltaUiTemplateConfigs[${key}].templateId`)}
                          label="Template id"
                          onChange={handleChange}
                          required
                          error={Boolean(
                            touched?.deltaUiTemplateConfigs?.[key]?.templateId &&
                              (errors?.deltaUiTemplateConfigs as any[])?.[key]?.templateId
                          )}
                          helperText={
                            touched?.deltaUiTemplateConfigs?.[key]?.templateId &&
                            (errors?.deltaUiTemplateConfigs as any[])?.[key]?.templateId
                          }
                        />
                        <CommonTextField
                          sx={{ mt: '15px' }}
                          type="text"
                          {...getFieldProps(`deltaUiTemplateConfigs[${key}].templateTitle`)}
                          label="Template title"
                          required
                          error={Boolean(
                            touched?.deltaUiTemplateConfigs?.[key]?.templateTitle &&
                              (errors?.deltaUiTemplateConfigs as any[])?.[key]?.templateTitle
                          )}
                          helperText={
                            touched?.deltaUiTemplateConfigs?.[key]?.templateTitle &&
                            (errors?.deltaUiTemplateConfigs as any[])?.[key]?.templateTitle
                          }
                        />
                      </Card>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 2,
                }}>
                <CommonButton onClick={() => addValues()} type="button">
                  Add values
                </CommonButton>
                <CommonButton type="submit" disabled={deltaCreationLoading || deltaUpdateLoading}>
                  {initialValues ? 'Update' : 'Create'}
                </CommonButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </div>
  );
};
export default DeltaMediaConfigForm;
