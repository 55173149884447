import { string } from 'yup/lib/locale';
import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { IUserModel } from '../../../shared/models/user.model';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IUserCreateFormValues } from '../../pages/users/create-edit/components/CreateEditUserForm';

const baseUrl = 'admin/users';

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsersPaginated: build.query<IBasePaginatedModel<IUserModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search }) => ({
        url:
          search?.length === 0
            ? `${baseUrl}?page=${page}&pageSize=${pageSize}`
            : `${baseUrl}?page=${page}&pageSize=${pageSize}&search=${search}`,
      }),
      providesTags: (result) => providesList(result?.items ?? [], 'Users', TypeIdsDefinitions.listTypeId),
    }),
    addUser: build.mutation<void, IUserCreateFormValues>({
      query: (values: IUserCreateFormValues) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['Users'],
    }),
    getUserById: build.query<IUserModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
    }),
    editUser: build.mutation<void, { values: IUserCreateFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['Users'],
    }),
    updatePassword: build.mutation<void, { id: string; password: string }>({
      query: ({ id, password }) => ({
        url: `${baseUrl}/${id}/update:password`,
        method: 'PUT',
        body: { password },
      }),
    }),
  }),
});

export const {
  useGetUsersPaginatedQuery,
  useAddUserMutation,
  useGetUserByIdQuery,
  useEditUserMutation,
  useUpdatePasswordMutation,
} = usersApi;
