import { useUpdateCompanyBrandingTitleMutation } from '@/admin/services/http/company-branding.api';
import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import { FunctionComponent } from 'react';
import * as yup from 'yup';
import CommonButton from '../../../../shared/components/CommonButton';
import CommonTextField from '../../../../shared/components/CommonTextField';
import { ICompanyBrandingModel } from '@/shared/models/companies/branding/company-branding.model';
import { useTranslation } from 'react-i18next';

interface ICompanyBrandingTitleFormProps {
  initialValues: ICompanyBrandingModel;
}

export interface ICompanyBrandingTitleFormValues {
  title: string;
}

const CompanyBrandingTitleForm: FunctionComponent<ICompanyBrandingTitleFormProps> = (
  props: ICompanyBrandingTitleFormProps
): JSX.Element => {
  const { initialValues } = props;

  const { t } = useTranslation();

  const [updateCompanyBrandingTitle, { isLoading }] = useUpdateCompanyBrandingTitleMutation();

  const { getFieldProps, touched, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      title: initialValues?.title || '',
    }!,
    validationSchema: yup.object().shape({
      title: yup.string().required('Title is required'),
    }),
    onSubmit: async (values: ICompanyBrandingTitleFormValues) => {
      await updateCompanyBrandingTitle({ id: initialValues.id, values });
    },
  });

  return (
    <form noValidate onSubmit={(e) => handleSubmit(e)}>
      <CommonTextField
        sx={{ mt: 2 }}
        type="text"
        {...getFieldProps('title')}
        label={t('brandingTitle')}
        onChange={handleChange}
        required
        error={Boolean(touched.title && errors.title)}
        helperText={touched.title && (errors?.title as string)}
      />
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mt: 2,
          }}>
          <CommonButton disabled={isLoading} type="submit">
            {t('update')}
          </CommonButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default CompanyBrandingTitleForm;
