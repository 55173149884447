import {
  checkRoleFromAccessTokenWithCb,
  checkTokenPresented,
  setupAccessTokenFromLS,
  SymfonyRolesEnum,
} from '@/auth/services/accesstoken.handler';
import { IGuardPropsModel } from '@/shared/models/guard-props.model';
import { useAppSelector } from '@/store/hooks/redux';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AdminGuard: React.FC<IGuardPropsModel> = ({ children }): JSX.Element => {
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenRole = (token: string): void => {
      checkRoleFromAccessTokenWithCb(token, SymfonyRolesEnum.ADMIN, () => navigate('/sign-in'));
    };

    let isAdminSubscription = true;
    if (isAdminSubscription) {
      if (checkTokenPresented(accessToken)) {
        checkTokenRole(accessToken! satisfies string);
        return;
      }

      let credentials = setupAccessTokenFromLS();
      if (credentials) {
        checkTokenRole(credentials.accessToken);
        return;
      }
    }

    return () => {
      isAdminSubscription = false;
    };
  }, [accessToken, navigate]);

  return children;
};

export default AdminGuard;
