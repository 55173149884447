import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import AgentDetails from '@/statistics/components/AgentDetails';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useSelectHeaderImage } from '@/statistics/hooks/select-header-image';
import { useTranslation } from 'react-i18next';

export interface IEieHeaderProps {
  order: ISingleOrderWithDetailsModel;
}

const EieHeader: FC<IEieHeaderProps> = (props: IEieHeaderProps): JSX.Element => {
  const { order } = props;
  const headerImg = useSelectHeaderImage(order);
  const [timeWhenStart, setTimeWhenStart] = useState<string | null>();

  const { t } = useTranslation();

  useEffect(() => {
    const adToUse = order.advertisements[0];
    setTimeWhenStart(moment(adToUse.adSet.startTime).format('DD.MM.YYYY'));
  }, [order]);

  return (
    <Grid
      container
      item
      sx={{
        height: '500px',
        backgroundImage: `url(${headerImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        alignItems: 'center',
      }}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: 'rgba(22,40,49,0.9)',
        }}>
        <Grid container item>
          <Grid
            order={{ xs: 2, md: 1 }}
            item
            xs={12}
            md={4}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}>
            <Box py={2} textAlign={'center'}>
              <Typography fontWeight={100} color={'#fff'} variant="h2">
                {order.propertyAddress}
              </Typography>
              <Typography color={'#fff'} fontWeight={100} variant="h3">
                {t('campaignStarted')} {timeWhenStart}
              </Typography>
            </Box>
          </Grid>
          <Grid
            order={{ xs: 1, md: 2 }}
            direction={'column'}
            item
            xs={12}
            md={4}
            justifyContent={'center'}
            alignItems={'center'}
            display={'flex'}>
            <Box sx={{ width: '20%', mt: '10px' }}>
              <img src="/images/boards/boards_eie_logo.png" width="100%" />
            </Box>
            <Box>
              <Typography m={0} p={0} color={'#fff'} fontWeight={100} variant="h5">
                EIE eiendomsmegling
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} py={2} order={3}>
            {order.userDetails && <AgentDetails {...order.userDetails}></AgentDetails>}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EieHeader;
