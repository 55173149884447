import { useParams } from 'react-router-dom';
import { useGetPlatformConfigsByCompanyIdQuery } from '../../services/http/companies-platform-configs.api';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import { Grid } from '@mui/material';
import MetaConfigForm from './components/MetaConfigForm';
import Loader from '../../../shared/components/Loader';
import DeltaMediaConfigForm from './components/DeltaMediaConfigForm';
import SnapChatConfigForm from './components/SnapChatConfigForm';
import FinnConfigForm from './components/FinnConfigForm';
import OGDisplayConfigForm from './components/OGDisplayConfigForm';
import BidTheatreConfigForm from './components/BidTheatreConfigForm';

const PlatformConfigsListPage: React.FC = (): JSX.Element => {
  const { id } = useParams();
  const { data, isLoading } = useGetPlatformConfigsByCompanyIdQuery({
    id: id!,
  });

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <AdminBaseFormWrapper>
            <Grid container>
              <Grid item xs={12}>
                <MetaConfigForm initialValues={data?.metaConfig} companyId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <DeltaMediaConfigForm initialValues={data?.deltaMediaConfig} companyId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <SnapChatConfigForm initialValues={data?.snapChatConfig} companyId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <FinnConfigForm initialValues={data?.finnConfig} companyId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <OGDisplayConfigForm initialValues={data?.ogDisplayConfig} companyId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <BidTheatreConfigForm initialValues={data?.bidtheatreConfig} companyId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
        </div>
      )}
    </div>
  );
};

export default PlatformConfigsListPage;
