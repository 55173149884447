import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditBudgetItemForm from './components/CreateEditBudgetItemForm';

const CreateEditBudgetItemPage = (): JSX.Element => {
  const { budgetItemId } = useParams();

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          <CreateEditBudgetItemForm isUpdating={!!budgetItemId}></CreateEditBudgetItemForm>
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default CreateEditBudgetItemPage;
