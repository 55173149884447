import React from 'react';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useAddUserMutation, useEditUserMutation, useGetUserByIdQuery } from '@/admin/services/http/users.api';
import { Box } from '@mui/system';
import Chip from '@mui/material/Chip';
import { useParams } from 'react-router-dom';
import Loader from '@/shared/components/Loader';
import CommonTextField from '@/shared/components/CommonTextField';
import { SymfonyRole } from '@/shared/models/role.model';
import { cloneDeep } from 'lodash';
import { useGetAllOfficesAdminQuery } from '@/admin/services/http/offices.api';
import { useTranslation } from 'react-i18next';
import { RefreshOutlined } from '@mui/icons-material';
import { toast } from 'react-hot-toast';

const baseRoles: SymfonyRole[] = ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_OFFICE_ADMIN', 'ROLE_COMPANY_ADMIN'];

export interface CreateEditUserFormProps {
  isUpdating: boolean;
}

export interface IUserCreateFormValues {
  email: string;
  roles: string[];
  officeId: string;
  active: boolean;
}

const validationSchema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  roleIds: yup.array().of(yup.string()),
  // officeId: yup.string(),
});

const CreateEditUserForm: React.FC<CreateEditUserFormProps> = ({ isUpdating }): JSX.Element => {
  const { userId } = useParams();

  const { t } = useTranslation();

  const [addUser, { isLoading: userAddLoading }] = useAddUserMutation();
  const [editUser, { isLoading: editUserLoading }] = useEditUserMutation();
  const { data: officeData, isLoading: officeDataLoading } = useGetAllOfficesAdminQuery();

  const { data: userData, isLoading: userDataLoading } = useGetUserByIdQuery(
    { id: userId ?? '' },
    { skip: !isUpdating }
  );

  const handleSubmit = async (values: IUserCreateFormValues) => {
    if (userId) {
      await toast.promise(editUser({ values, id: userId }).unwrap(), {
        loading: t('updating'),
        error: t('errorWhileUpdating'),
        success: t('updatedSuccessfully'),
      });
      return;
    }

    await toast.promise(addUser(values).unwrap(), {
      success: t('createdSuccessfully'),
      error: t('errorWhileCreating'),
      loading: t('creating'),
    });
  };

  const initialValues: IUserCreateFormValues = {
    email: userData?.email ?? '',
    roles: userData?.roles ?? [],
    officeId: userData?.officeId || '',
    active: userData?.active ?? true,
  };

  const handleRoleChange = (values: IUserCreateFormValues, e: SelectChangeEvent<string[]>, setFieldValue: Function) => {
    const { value } = e.target;
    const mappedValue = value as string;
    const deepCopy = cloneDeep(values.roles);
    const alreadyInList = deepCopy.findIndex((el) => el === mappedValue);

    if (alreadyInList !== -1) {
      deepCopy.splice(alreadyInList, 1);
      setFieldValue('roles', deepCopy);
      return;
    }

    deepCopy.push(mappedValue);
    setFieldValue('roles', deepCopy);
  };

  const dataLoadingFinished = () => !userDataLoading && !officeDataLoading;

  return (
    <div>
      {dataLoadingFinished() ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IUserCreateFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                type="text"
                name="email"
                label={t('email')}
                value={values.email}
                onChange={handleChange}
                required
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="role_id_selector">{t('role')}</InputLabel>
                <Select
                  labelId="role_id_selector"
                  id="role_id_selector"
                  value={values.roles}
                  onChange={(e) => handleRoleChange(values, e, setFieldValue)}
                  input={<OutlinedInput label="Chip"></OutlinedInput>}
                  renderValue={(selected) => (
                    <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        return <Chip key={value} label={value} />;
                      })}
                    </Box>
                  )}>
                  {baseRoles.map((el) => (
                    <MenuItem key={el} value={el}>
                      {el}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Autocomplete
                  disableClearable
                  disablePortal
                  value={{ id: values?.officeId, label: officeData?.find((el) => el.id === values.officeId)?.title }}
                  onChange={(e, value) => setFieldValue('officeId', value?.id || '')}
                  onOpen={handleBlur}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  id="office_autocomplete_select"
                  options={officeData?.map((el) => ({ id: el.id, label: el.title })) ?? []}
                  fullWidth
                  defaultValue={{
                    id: values?.officeId,
                    label: officeData?.find((el) => el.id === values.officeId)?.title,
                  }}
                  renderInput={(params) => {
                    // @ts-ignore
                    return <TextField {...params} label={t('offices') as React.ReactNode} />;
                  }}
                />
              </FormControl>
              {isUpdating && (
                <FormControl sx={{ ml: 1 }}>
                  <FormControlLabel
                    control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} defaultChecked />}
                    label={t('activeUser')}
                  />
                </FormControl>
              )}
              {!isUpdating && (
                <Box component="div">
                  <Typography variant="caption">{t('passwordGenerated')}</Typography>
                </Box>
              )}
              <Box component="div" display={'flex'} justifyContent={'flex-end'}>
                <Button
                  sx={{ mt: 0 }}
                  type="submit"
                  endIcon={<RefreshOutlined />}
                  variant="contained"
                  disabled={userAddLoading || editUserLoading}>
                  {t('submit')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEditUserForm;
