import React, { useMemo } from "react";
import { ICustomIconPropertiesModel } from "./models/custom-icon-properties.model";
const BoardsIcon: React.FC<ICustomIconPropertiesModel> = ({
  fontSize,
  color,
}): JSX.Element => {
  const size = useMemo(() => {
    switch (fontSize) {
      case "large":
        return 32;
      case "medium":
        return 24;
      case "small":
        return 22;
      default:
        return 32;
    }
  }, []);
  return (
    <img src='/images/boards/boards_logo.jpg' width={size}
    height={size} />
  );
};

export default BoardsIcon;
