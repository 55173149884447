import { IAgentDetailsProps } from '@/shared/models/orders/single-order-with-details.model';
import { Grid, Typography } from '@mui/material';
import * as React from 'react';

const AgentDetailsCard: React.FC<IAgentDetailsProps> = (props: IAgentDetailsProps): JSX.Element => {
  return (
    <Grid item container display={'flex'} height={'100%'} justifyContent={'space-around'}>
      <Grid item p={1} xs={6} md={12} color="#fff" textAlign={'right'} sx={{ height: { md: '40%' } }}>
        <Typography fontWeight={100} color={'#fff'} variant="h5">
          {props.agentTitle}
        </Typography>
        <Typography fontWeight={500} variant={'h2'}>
          {props.agentName + ' ' + props.agentLastName}
        </Typography>
        <Typography fontWeight={100} variant={'h4'}>
          {props.agentOfficeName}
        </Typography>
        <Typography fontWeight={100} variant={'h5'}>
          {props.agentEmail}
        </Typography>
        <Typography fontWeight={100} variant={'h5'}>
          {props.agentPhone}
        </Typography>
      </Grid>
      <Grid
        item
        xs={6}
        md={12}
        sx={{
          height: { md: '60%' },
          backgroundImage: `url(${props.agentImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top 10% right 0%',
        }}></Grid>
    </Grid>
  );
};

export default AgentDetailsCard;
