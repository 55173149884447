type EventMap = Record<string | symbol, (payload: any) => void>;
type Bus<E> = Record<keyof E, E[keyof E][]>;

interface EventBus<T extends EventMap> {
  on(key: keyof T,handler: T[keyof T]): Record<"unsubscribe", () => void>;
  off(key: keyof T, handler: T[keyof T]): void;
  once(key: keyof T, handler: T[keyof T]): void;
  emit(key: keyof T, ...payload: Parameters<T[keyof T]>): void;
}

interface EventBusConfig {
  onError: (...params: any[]) => void;
}

export function eventbus<E extends EventMap>(
  config?: EventBusConfig
): EventBus<E> {
  const bus: Partial<Bus<E>> = {};

  const on: EventBus<E>["on"] = (key, handler) => {
    if (bus[key] === undefined) {
      bus[key] = [];
    }
    bus[key]?.push(handler);

    return {
      unsubscribe: function () {
        off(key, handler);
      },
    };
  };

  const off: EventBus<E>["off"] = (key, handler) => {
    const index = bus[key]?.indexOf(handler) ?? -1;
    bus[key]?.splice(index >>> 0, 1);
  };

  const once: EventBus<E>["once"] = (key, handler) => {
    const handleOnce = (payload: Parameters<typeof handler>) => {
      handler(payload);
      off(key, handleOnce as typeof handler);
    };

    on(key, handleOnce as typeof handler);
  };

  const emit: EventBus<E>["emit"] = (key, payload) => {
    bus[key]?.forEach((fn) => {
      try {
        fn(payload);
      } catch (e) {
        config?.onError(e);
      }
    });
  };

  return { on, off, once, emit };
}
