import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { debounce } from '@/shared/hooks/debounce';
import { COLORS } from '@/shared/styles/colors';
import { AddCircle, Delete, Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDeleteProductItemMutation, useGetProductItemsByProductIdQuery } from '@/admin/services/http/products-items.api';
import { IProductBudgetItemModel } from '@/shared/models/products/product-item.model';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'type',
    name: 'Type',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'spreadProductPercentageDefault',
    name: 'Default product spread %',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const ProductsItemsDataTable = (): JSX.Element => {
  const { productId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');
  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetProductItemsByProductIdQuery({
    productId: productId!,
    queryParams: {
      page,
      pageSize,
      search,
    },
  });

  const [deleteProductItem] = useDeleteProductItemMutation();

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircle />,
        action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/products/${productId}/product-items/new`),
      },
    ];
  }, []);

  const mapProductItems = (data?: IProductBudgetItemModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          type: el.type.charAt(0).toUpperCase() + el.type.slice(1),
          spreadProductPercentageDefault: el.spreadProductPercentageDefault,
          createdAt: el.createdAt,
          actions: [
            {
              title: t('edit'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/product-items/${el.id}/edit`),
            },
            {
              title: t('delete'),
              icon: <Delete />,
              action: () => deleteProductItem({ id: el.id }),
              color: COLORS.delete,
            },
          ],
        }))
      : [];
  };

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  return (
    <div>
      <AdminTableWrapper
        header={t('productItems')}
        isLoading={isLoading || isFetching}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapProductItems(data?.items as IProductBudgetItemModel[]),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default ProductsItemsDataTable;
