import * as Yup from 'yup';
import i18next from 'i18next';

const t = i18next.t.bind(i18next);

export const basicCreativeSchema = Yup.object({
  headline: Yup.string().required('Headline Required').max(1024),
  caption: Yup.string().required('Caption Required').max(40),
  description: Yup.string().required('Description Required').max(255),
  link: Yup.string().required('Link Required').max(255),
});

export const basicBidtheatreCreativeSchema = Yup.object({
  link: Yup.string().required('Link Required'),
  description: Yup.string().required('Description Required'),
  caption: Yup.string().nullable().required('Caption Required').max(40, 'Caption should not be more than 40 characters'),
  viewDate: Yup.string().nullable().max(45, 'Visning should not be more than 45 characters'),
  propertyEstateType: Yup.string().nullable().max(20, 'Property estate type should not be more than 20 characters'),
});

export const singleCreativeSchema = basicCreativeSchema.concat(
  Yup.object({
    media: Yup.object().nullable().required(t('Media required')),
  })
);

export const dynamicCreativeSchema = basicCreativeSchema.concat(
  Yup.object({
    creativeMedias: Yup.array()
      .required(t('Images required'))
      .min(1, t('At least one images are required'))
      .max(5, t('Maximum 5 images')),
  })
);

export const carouselCreativeSchema = basicCreativeSchema.concat(
  Yup.object({
    creativeItems: Yup.array()
      .of(
        Yup.object().shape({
          headline: Yup.string().required(t('Headline required')),
          description: Yup.string().required(t('Description required')),
          link: Yup.string().required(t('Link required')),
          creativeMedia: Yup.object().required(),
        })
      )
      .required(t('Images required'))
      .min(2, t('Two images required'))
      .max(5, t('Maximum 5 images')),
  })
);

export const videoCreativeSchema = basicCreativeSchema.concat(
  Yup.object({
    media: Yup.object().required('Media required'),
  })
);
