function providesList<
  R extends { id: string }[],
  T extends string,
  Y extends string
>(resultsWithIds: R | undefined, tagType: T, idType: Y) {
  return resultsWithIds
    ? [
        { type: tagType, id: idType },
        ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
      ]
    : [{ type: tagType, id: idType }];
}

export default providesList;
