import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import MediaLibraryDrawer from '../components/media-library/MediaLibraryDrawer';
import FinishingCreationOfAd from '../shared/components/modals/FinishingCreationOfAd';
import ImageCropper from '../shared/components/modals/ImageCropper';
import LeaveAdCreativeStepsModal from '../shared/components/modals/LeaveAdCreativeStepsModal';
import SideBar from './SideBar';
import { IAuthResponse } from '@/auth/models/auth.response';
import { getCredentialsFromLS, getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useGetMeQuery } from '../services/http/users.api';
import useAppInitLanguage from '@/shared/hooks/app-init-hook';
import { setCompanyId } from '../store/reducers/property-info.slice';
import { EDITOR_LS_CONSTANTS } from '../shared/constants/editor-LS.constants';
import { useEffect } from 'react';

const EditorLayout: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const credentials: IAuthResponse | null = getCredentialsFromLS();

  const { data: userData, isSuccess: userDataSuccess } = useGetMeQuery(undefined, { skip: !credentials });

  useEffect(() => {
    if (userDataSuccess && getValueFromLs(EDITOR_LS_CONSTANTS.COMPANY_ID) === null) {
      dispatch(setCompanyId(userData?.companyExternalId!));
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.COMPANY_ID, userData?.companyExternalId!);
    }
  }, [userData]);

  useAppInitLanguage(userDataSuccess);

  const modalStates = useAppSelector((state) => state.editorModalsDrawersReducer);

  return (
    <Box component="div" sx={{ height: '100%', width: '100%' }}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          height: '100vh',
          top: 0,
          bottom: 0,
          zIndex: 10000,
        }}>
        <SideBar></SideBar>
        <Box sx={{ width: '100%', overflowY: 'scroll' }}>
          <Container disableGutters={true} maxWidth={false}>
            <Outlet />
          </Container>
        </Box>
      </Box>
      <MediaLibraryDrawer />

      {modalStates.leaveAdCreationSteps.isOpened && <LeaveAdCreativeStepsModal />}
      {modalStates.finishingCreationOfAd && <FinishingCreationOfAd />}
      {modalStates.imageCropper.isOpened && <ImageCropper />}
    </Box>
  );
};

export default EditorLayout;
