import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import { Box, CircularProgress, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import { ILinkToUploadMedia } from '@/shared/models/media-library/link-to-upload-media.model';
import axiosInstance from '../../../../shared/services/api/axios.api';
import { getValueFromLs } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '../../../shared/constants/editor-LS.constants';
import { IRefetchAction } from '../MediaUploader';
import useSubscribeToImageCroppedEvent from './hooks/useSubscribeToCropperEvent';
import { useAppSelector } from '@/store/hooks/redux';
import { UploadFileRounded } from '@mui/icons-material';

const ImagesUploader: React.FC<IRefetchAction> = (props: IRefetchAction): JSX.Element => {
  const { refetchAction } = props;
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const propertyId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)
    ? getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)
    : useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  useSubscribeToImageCroppedEvent(uploadFile);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleInputClick = () => {
    inputRef?.current?.click();
  };

  async function uploadFile(file: File): Promise<ILinkToUploadMedia | null> {
    const extension = file.name.split('.').pop()?.toLowerCase();
    if (!extension) return null;

    const { width, height } = await getImgDimensions(file);

    setUploadLoading(true);
    const link = (
      await axiosInstance.post<ILinkToUploadMedia>('medias/images', {
        propertyId,
        extension,
        width,
        height,
      })
    ).data;

    await axios.put(link.linkToUpload, file);
    setUploadLoading(false);
    refetchAction();
    return link;
  }

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null | undefined = event?.target?.files?.[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const getImgDimensions = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve, reject) => {
      try {
        const url = URL.createObjectURL(file);
        const img = new Image();

        img.onload = () => {
          resolve({
            height: Math.floor(img.height),
            width: Math.floor(img.width),
          });
        };

        img.src = url;
      } catch (e) {
        reject(e);
      }
    });
  };

  return (
    <div>
      <IconButton disabled={uploadLoading} onClick={handleInputClick}>
        {!uploadLoading ? (
          <UploadTwoToneIcon color="secondary" fontSize="medium" />
        ) : (
          <Box sx={{ position: 'relative' }}>
            <CircularProgress
              variant="indeterminate"
              size={30}
              sx={{
                color: '#000',
                position: 'absolute',
                top: -4,
                left: -5,
                zIndex: 1,
              }}
            />
            <UploadFileRounded color="secondary" />
          </Box>
        )}
      </IconButton>
      <input type="file" accept="image/jpeg, image/png" hidden ref={inputRef} onChange={handleFileSelected}></input>
    </div>
  );
};

export default ImagesUploader;
