import { IRoute } from "@/shared/models/route.model";
import AdminLanguagePage from "@/admin/pages/languages/LanguagePage";

export const ROUTE_LANGUAGES_DECLARATIONS = Object.freeze({
	AdminLanguagePage: "languages",
	AdminLanguageEditPage: "languages/:languageId/edit"

});

export const AdminLanguagesRoutes: IRoute[] = [
	{
		path: ROUTE_LANGUAGES_DECLARATIONS.AdminLanguagePage,
		component: AdminLanguagePage,
	},
	// {
	// 	path: ROUTE_LANGUAGES_DECLARATIONS.AdminLanguageEditPage,
	// 	component: AdminLanguageEditPage,
	// },
];
