import { FC } from 'react';
import { Line } from 'react-chartjs-2';
import { IBaseChartsProps } from './chart-props.moldel';

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
};

const LineChart: FC<IBaseChartsProps> = (props: IBaseChartsProps): JSX.Element => {
  return (
    <Line
      options={options}
      data={{
        labels: props.labels,
        datasets: props.dataSets.map((el) => ({
          label: el.label,
          data: el.data,
          borderColor: el.borderColor ?? '#9BA4B5',
          backgroundColor: el.backgroundColor ?? '#9BA4B5',
        })),
      }}
    />
  );
};

export default LineChart;
