import { useAppSelector } from '@/store/hooks/redux';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
const SormeglerenBidtheatreSinglePreview: React.FC<IExtendedPreviewPropsModel> = ({
  caption,
  propertyPriceQuote,
  areaInUsage,
  propertyDebt,
  description,
  viewDate,
}): JSX.Element => {
  const media = useAppSelector(
    (state) => state.creativeStepSliceReducer?.creativeFormsState?.bidtheatre?.single?.media
  );
  return (
    <div>
      <Grid container sx={{ border: '1px solid #231464', maxWidth: '500px', height: '600px' }}>
        <Grid item xs={12} sx={{ minHeight: '325px', pb: 2, backgroundColor: '#FFF' }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}>
            <img
              className="sormegleren-preview-box"
              src={media?.preSignedUrl}
              alt="property"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box
            sx={{
              height: '13px',
              width: '100%',
              backgroundImage: 'linear-gradient(to right, #604696,#df4114,#f7a600)',
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ zIndex: 10 }} p={2}>
          <Typography sx={{ textOverflow: 'ellipsis', color: '#231464' }} variant="h4" fontFamily="GothamNarrowMedium">
            <span>{caption}</span>
          </Typography>
          <Typography sx={{ color: '#231464' }} variant="h5" fontFamily="GothamNarrowBook">
            {description && description.length > 120 ? description.slice(0, 120) + '...' : description}
          </Typography>
          <Typography sx={{ color: '#231464' }} variant="h5" fontFamily="GothamNarrowBook">
            <span>{areaInUsage && <div>{areaInUsage}</div>}</span>
          </Typography>
          <Typography sx={{ color: '#231464' }} className="animated-words" variant="h5" fontFamily="GothamNarrowBook">
            <span>{propertyPriceQuote && <div>{propertyPriceQuote}</div>}</span>
            <span>{viewDate && <div>{viewDate}</div>}</span>
          </Typography>
          <Typography sx={{ color: '#231464' }} variant="h5" fontFamily="GothamNarrowBook">
            {propertyDebt && <div> {propertyDebt} </div>}
          </Typography>
          <Box sx={{ float: 'right', position: 'relative', top: '20px', left: '10px' }}>
            <img
              style={{ width: '100px' }}
              src="/images/sormegleren/sormegleren_logo_a_rgb.png"
              alt="sormegleren-logo"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};
export default SormeglerenBidtheatreSinglePreview;
