import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { ICompanyBrandingFilePlatformModel } from '../../../../shared/models/companies/branding/company-branding-file-platform.model';
import Grid from '@mui/material/Grid';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Card, CardActions, IconButton, Tooltip, Typography } from '@mui/material';
import CompanyBrandingPlatformFileModal from './CompanyBrandingPlatformFileModal';
import { DeleteOutlineTwoTone } from '@mui/icons-material';
import { useDeleteBrandingFilePlatformMutation } from '../../../services/http/company-branding.api';

interface ICompanyBrandingPlatformFilesProps {
  brandingId: string;
  brandingPlatformFiles: ICompanyBrandingFilePlatformModel[];
}

const CompanyBrandingPlatformFiles: FunctionComponent<ICompanyBrandingPlatformFilesProps> = (
  props: ICompanyBrandingPlatformFilesProps
): JSX.Element => {
  const { brandingId, brandingPlatformFiles } = props;
  const [deletePlatformFile] = useDeleteBrandingFilePlatformMutation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null | undefined = event?.target?.files?.[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleInputClick = () => {
    inputRef?.current?.click();
  };

  const getToolTipTitle = useCallback((file: ICompanyBrandingFilePlatformModel) => {
    return `Platform: ${file.platform}. Width: ${file.width}. Height: ${file.height}`;
  }, []);

  const handleDeletePlatformFile = useCallback(
    async (id: string) => {
      await deletePlatformFile({ id }).unwrap();
    },
    [deletePlatformFile]
  );

  return (
    <div>
      <Grid container display="flex" justifyContent="center">
        <Grid item xs={12} display="flex" justifyContent="center">
          <IconButton onClick={handleInputClick}>
            <AttachFileIcon fontSize="large" color="secondary"></AttachFileIcon>
          </IconButton>
        </Grid>
      </Grid>
      <Grid container>
        {brandingPlatformFiles.length ? (
          <div>
            {brandingPlatformFiles.map((file, key) => (
              <Tooltip title={getToolTipTitle(file)} placement="top" key={key}>
                <Grid item xs={4}>
                  <Card>
                    <CardActions>
                      <IconButton onClick={() => handleDeletePlatformFile(file.id)}>
                        <DeleteOutlineTwoTone></DeleteOutlineTwoTone>
                      </IconButton>
                    </CardActions>
                    <img style={{ width: '100%' }} src={file.preSignedUrl} alt="img"></img>
                  </Card>
                </Grid>
              </Tooltip>
            ))}
          </div>
        ) : (
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography mt={1} variant="h5" color="red">
              No platforms logos to use
            </Typography>
          </Grid>
        )}
        <input type="file" accept="image/jpeg, image/png" hidden ref={inputRef} onChange={handleFileSelected}></input>
      </Grid>
      {selectedFile && (
        <CompanyBrandingPlatformFileModal
          brandingId={brandingId}
          deselectFile={() => setSelectedFile(null)}
          file={selectedFile!}
        />
      )}
    </div>
  );
};

export default CompanyBrandingPlatformFiles;
