import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { debounce } from '@/shared/hooks/debounce';
import { ICompanyModel } from '@/shared/models/companies/company.model';
import { useGetOfficesAdminQuery } from '../../services/http/offices.api';
import { ROUTE_OFFICES_DECLARATIONS } from '../../routes/offices-routes';
import { useTranslation } from 'react-i18next';
import { AddCircle, Edit, Settings } from '@mui/icons-material';
import { IOfficeModel } from '@/shared/models/offices/office.model';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'title',
    name: 'Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'externalId',
    name: 'Office external id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const OfficesDataTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading, isFetching } = useGetOfficesAdminQuery({
    page,
    pageSize,
    search,
  });

  const { t } = useTranslation();

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircle />,
        action: () =>
          navigate(`${ROUTES_DECLARATIONS.AdminPanel}/${ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesNewPage}`),
      },
    ];
  }, []);

  const mapOffices = useCallback((data?: IOfficeModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          title: el.title,
          externalId: el.externalId,
          createdAt: el.createdAt,
          actions: [
            {
              title: t('edit'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/offices/${el.id}/edit`),
            },
            {
              title: t('configs'),
              icon: <Settings />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/offices/${el.id}/platform-configs`),
            },
          ],
        }))
      : [];
  }, []);

  return (
    <div>
      <AdminTableWrapper
        header={t('offices')}
        isLoading={isLoading}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapOffices(data?.items as IOfficeModel[]),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default OfficesDataTable;
