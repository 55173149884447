import { StylesType } from '../../../../shared/styles/styles';

type AdminFormWrapperStyles = 'mainWrapper' | 'paper';

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdminFormWrapperStyles: StylesType<AdminFormWrapperStyles> = {
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    width: { md: '50%', xs: '100%' },
    height: '100%',
    padding: 5,
  },
};
