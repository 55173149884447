import { ICompanyBrandingTitleFormValues } from '@/admin/pages/companies/components/CompanyBrandingTitleForm';
import { api } from '../../../shared/services/api/api';
import { ICompanyBrandingModel } from './../../../shared/models/companies/branding/company-branding.model';

const baseURL = 'admin/company-brandings';

export const companiesBrandingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyBranding: build.query<ICompanyBrandingModel, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/companies/${id}/company-brandings`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'CompanyBranding', id: arg.id }],
    }),
    uploadBrandingFile: build.mutation<void, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `${baseURL}/${id}/file`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CompanyBranding'],
    }),
    uploadBrandingFilePlatform: build.mutation<void, { id: string; data: FormData }>({
      query: ({ id, data }) => ({
        url: `${baseURL}/${id}/platform-file`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['CompanyBranding'],
    }),
    deleteBrandingFilePlatform: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/admin/company-brandings/branding-platform-file/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyBranding'],
    }),
    updateCompanyBrandingTitle: build.mutation<void, { id: string; values: ICompanyBrandingTitleFormValues }>({
      query: ({ id, values }) => ({
        url: `${baseURL}/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
  }),
});

export const {
  useGetCompanyBrandingQuery,
  useUploadBrandingFileMutation,
  useUploadBrandingFilePlatformMutation,
  useDeleteBrandingFilePlatformMutation,
  useUpdateCompanyBrandingTitleMutation,
} = companiesBrandingsApi;
