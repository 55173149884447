import { Box, Grid, Typography } from '@mui/material';
import React, { FunctionComponent, useRef } from 'react';
import EmptyImageCard from '../../../../shared/components/platform-previews/previews/EmptyImageCard';
import { ICompanyBrandingFileModel } from '@/shared/models/companies/branding/company-branding-file.model';
import { useUploadBrandingFileMutation } from '@/admin/services/http/company-branding.api';
import { useTranslation } from 'react-i18next';

interface ICompanyBrandingFileUploaderProps {
  brandingId: string;
  brandingFile: ICompanyBrandingFileModel | null;
}

const CompanyBrandingFileUploader: FunctionComponent<ICompanyBrandingFileUploaderProps> = (
  props: ICompanyBrandingFileUploaderProps
): JSX.Element => {
  const { brandingFile, brandingId } = props;
  const [uploadFile] = useUploadBrandingFileMutation();

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null | undefined = event.target?.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await uploadFile({ id: brandingId, data: formData }).unwrap();
    }
  };

  const handleInputClick = () => {
    inputRef?.current?.click();
  };

  return (
    <div>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={6} alignItems="center" display="flex" flexDirection="column" justifyContent="center">
          <Typography mb={2} color="secondary">
            {t('companyLogo')}
          </Typography>
          {brandingFile && brandingFile.preSignedUrl ? (
            <Box onClick={handleInputClick}>
              <img style={{ width: '100%' }} src={brandingFile.preSignedUrl} alt="logo"></img>
            </Box>
          ) : (
            <EmptyImageCard onClick={handleInputClick} />
          )}
        </Grid>
        <input
          type="file"
          accept="image/jpeg, impage/jpg, image/png"
          hidden
          ref={inputRef}
          onChange={handleFileSelected}></input>
      </Grid>
    </div>
  );
};

export default CompanyBrandingFileUploader;
