import { IAddEditAutoCreationConfigFormValues } from '../../pages/companies-auto-creation-configs/components/AddEditAutoCreationConfigForm';
import { IAutoCreationConfigModel } from '../../../shared/models/companies/autocreation/auto-creation-config.model';
import { IAutoCreationConfigListModel } from '../../../shared/models/companies/autocreation/auto-creation-config-list.model';
import { api } from '../../../shared/services/api/api';

const baseURL = 'admin/auto-creation-settings';

export const companiesAutoCreationConfigsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAutoCreationConfigsByCompanyId: build.query<IAutoCreationConfigListModel, { id: string }>({
      query: ({ id }) => ({
        url: `admin/companies/${id}/auto-creation-settings`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'CompanyAutoCreationSettings', id: arg.id }],
    }),
    getSingleAutoCreationSettingsById: build.query<IAutoCreationConfigModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseURL}/${id}`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'CompanyAutoCreationSettings', id: arg.id }],
    }),
    createConfig: build.mutation<void, { values: IAddEditAutoCreationConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}`,
        method: 'POST',
        body: { ...values, companyId },
      }),
      invalidatesTags: ['CompanyAutoCreationSettings'],
    }),
    updateConfig: build.mutation<void, { values: IAddEditAutoCreationConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['CompanyAutoCreationSettings'],
    }),
  }),
});

export const {
  useGetAutoCreationConfigsByCompanyIdQuery,
  useGetSingleAutoCreationSettingsByIdQuery,
  useCreateConfigMutation,
  useUpdateConfigMutation,
} = companiesAutoCreationConfigsApi;
