import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import {
  useAddBudgetItemMutation,
  useEditBudgetItemMutation,
  useGetBudgetItemByIdQuery,
} from '@/admin/services/http/budgets-items.api';
import CommonButton from '../../../../shared/components/CommonButton';
import { capitalize } from 'lodash';
import CommonTextField from '@/shared/components/CommonTextField';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Sync } from '@mui/icons-material';

export interface ICreateEditBudgetItemProps {
  isUpdating: boolean;
}

export interface IBudgetItemCreateEditFormValues {
  type?: string;
  spreadBudgetPercentageDefault: number;
}

const validationSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
});

const types = ['meta', 'delta', 'snapchat', 'bidtheatre', 'boards'];

const CreateEditItemBudgetForm: React.FC<ICreateEditBudgetItemProps> = ({ isUpdating }): JSX.Element => {
  const { budgetId, budgetItemId } = useParams();
  const [editBudgetItem, { isLoading: editBudgetItemLoading }] = useEditBudgetItemMutation();
  const [addBudgetItem, { isLoading: addBudgetItemLoading }] = useAddBudgetItemMutation();
  const { t } = useTranslation();

  const {
    data: budgetItemData,
    isLoading: budgetItemDataLoading,
    isFetching: budgetItemDataFetching,
  } = useGetBudgetItemByIdQuery({ id: budgetItemId ?? '' }, { skip: !isUpdating });

  const navigate = useNavigate();
  const initialValues: IBudgetItemCreateEditFormValues = {
    type: budgetItemData?.type || '',
    spreadBudgetPercentageDefault: budgetItemData?.spreadBudgetPercentageDefault || 0,
  };

  const handleSubmit = async (values: IBudgetItemCreateEditFormValues) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        budgetItemData
          ? await editBudgetItem({ id: budgetItemId!, values }).unwrap()
          : await addBudgetItem({ budgetId: budgetId!, values }).unwrap();

        resolve('');
      } catch (err) {
        reject(err);
      }
    });

    toast.promise(promise, {
      success: () => {
        navigate(-1);
        return t('successfulProcessing');
      },
      error: (err) => (err?.data ? <span>{err.data}</span> : 'Error while updating'),
      loading: t('processing'),
    });
  };

  return (
    <div>
      {!budgetItemDataLoading && !budgetItemDataFetching ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IBudgetItemCreateEditFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <FormControl fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="type_input_label_id">{t('budgetType')}</InputLabel>
                <Select
                  name="type"
                  labelId="type_input_label_id"
                  id="type_input_id"
                  value={values.type}
                  onChange={handleChange}
                  input={<OutlinedInput label="Type"></OutlinedInput>}
                  renderValue={(value: string) => <Box component="div">{capitalize(value)}</Box>}>
                  {types?.map((el) => (
                    <MenuItem key={el} value={el}>
                      {capitalize(el)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <CommonTextField
                sx={{ mt: 3 }}
                type="number"
                name="spreadBudgetPercentageDefault"
                label={t('defaultBudgetSpreading')}
                value={values.spreadBudgetPercentageDefault}
                onChange={handleChange}
                required
                error={Boolean(touched.spreadBudgetPercentageDefault && errors.spreadBudgetPercentageDefault)}
                helperText={touched.spreadBudgetPercentageDefault && errors.spreadBudgetPercentageDefault}
              />
              <CommonButton
                sx={{ mt: 2 }}
                type="submit"
                variant="contained"
                startIcon={<Sync />}
                disabled={addBudgetItemLoading || editBudgetItemLoading}>
                {budgetItemId ? <Typography>{t('update')}</Typography> : <Typography>{t('submit')}</Typography>}
              </CommonButton>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEditItemBudgetForm;
