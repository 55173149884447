import { Box } from '@mui/material';
import { A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';

import 'swiper/css/navigation';
import 'swiper/swiper.min.css';

const MetaDynamicPreview: React.FC<{ selectedImage: string }> = ({ selectedImage }): JSX.Element => {
  const medias = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState.meta.dynamic.creativeMedias
  );

  return (
    <Box component="div" sx={{ maxWidth: '632px', height: '100%' }}>
      {medias.length > 0 ? (
        <Swiper modules={[A11y]} slidesPerView={1}>
          {medias.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={selectedImage}
                style={{
                  minWidth: '550px',
                  maxHeight: '250px',
                  objectFit: 'cover',
                }}
                alt="dynamic-image"></img>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default MetaDynamicPreview;
