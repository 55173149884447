import { FunctionComponent } from 'react';
import { ICompanyOGDisplayConfigModel } from '../../../../shared/models/companies/platform-configs/company-ogdisplay-config.model';
import {
  useCreateOgDisplayConfigMutation,
  useUpdateOgDisplayConfigMutation,
} from '../../../services/http/companies-platform-configs.api';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { Box, Grid, Typography } from '@mui/material';
import CommonButton from '../../../../shared/components/CommonButton';
import CommonTextField from '../../../../shared/components/CommonTextField';
import GoogleIcon from '@mui/icons-material/Google';

export interface IOGDisplayConfigProps {
  initialValues?: ICompanyOGDisplayConfigModel | null;
  companyId: string;
}

export interface IOGDisplayConfigFormValues {
  viewId: string;
  expressionKey: string;
  keyBody: string;
}

const OGDisplayConfigForm: FunctionComponent<IOGDisplayConfigProps> = (props: IOGDisplayConfigProps): JSX.Element => {
  const { initialValues, companyId } = props;
  const [createOgDisplayConfig, { isLoading: ogDisplayCreationLoading }] = useCreateOgDisplayConfigMutation();
  const [updateOgDisplayConfig, { isLoading: ogDisplayUpdateLoading }] = useUpdateOgDisplayConfigMutation();

  const { getFieldProps, touched, errors, handleSubmit } = useFormik({
    initialValues: {
      viewId: initialValues?.viewId || '',
      expressionKey: initialValues?.expressionKey || '',
      keyBody: initialValues?.keyBody || '',
    },
    validationSchema: yup.object().shape({
      viewId: yup.string().required('View id is required'),
      expressionKey: yup.string().required('Expression key is required'),
      keyBody: yup.string().required('Key body is required'),
    }),
    onSubmit: async (values: IOGDisplayConfigFormValues) => {
      !initialValues?.id
        ? await createOgDisplayConfig({ values, companyId }).unwrap()
        : await updateOgDisplayConfig({ values, id: initialValues.id }).unwrap();

      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });

  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box sx={{ ml: 2, width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <GoogleIcon fontSize="large" color="secondary" />
            <Typography variant="h5" color="secondary" sx={{ ml: 1 }}>
              Display
            </Typography>
          </Box>
        </Box>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <CommonTextField
            {...getFieldProps('viewId')}
            label="View Id"
            type="text"
            required
            error={Boolean(touched.viewId && errors.viewId)}
            helperText={touched.viewId && (errors?.viewId as string)}
          />
          <CommonTextField
            sx={{ mt: '15px' }}
            {...getFieldProps('expressionKey')}
            label="Expression Key"
            type="text"
            required
            error={Boolean(touched.expressionKey && errors.expressionKey)}
            helperText={touched.expressionKey && (errors?.expressionKey as string)}
          />
          <CommonTextField
            sx={{ mt: '15px' }}
            {...getFieldProps('keyBody')}
            label="Key Body"
            multiline
            minRows={3}
            maxRows={5}
            type="text"
            required
            error={Boolean(touched.keyBody && errors.keyBody)}
            helperText={touched.keyBody && (errors?.keyBody as string)}
          />
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2,
              }}>
              <CommonButton type="submit" disabled={ogDisplayCreationLoading || ogDisplayUpdateLoading}>
                {initialValues ? 'Update' : 'Create'}
              </CommonButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default OGDisplayConfigForm;
