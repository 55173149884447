import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';

import * as enCamelCase from '@/shared/locales/enCamelCase.json';
import * as noCamelCase from '@/shared/locales/noCamelCase.json';
import * as swCamelCase from '@/shared/locales/swCamelCase.json';

i18next
  .use(HttpBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init<HttpBackendOptions>({
    backend: {
      loadPath: '/locales/{{lng}}CamelCase.json',
    },
    resources: {
      en: {
        translation: JSON.parse(JSON.stringify(enCamelCase)),
      },
      no: {
        translation: JSON.parse(JSON.stringify(noCamelCase)),
      },
      sw: {
        translation: JSON.parse(JSON.stringify(swCamelCase)),
      },
      sv: {
        translation: JSON.parse(JSON.stringify(swCamelCase)),
      },
    },
    supportedLngs: ['sv', 'sw', 'en', 'no'],
    load: 'languageOnly',
    debug: false,
    fallbackLng: 'no',
    interpolation: {
      escapeValue: false,
    },
  });
