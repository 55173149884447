import { FunctionComponent } from 'react';
import { ICompanySnapchatConfigModel } from '../../../../shared/models/companies/platform-configs/company-snapchat-config.model';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import CommonButton from '../../../../shared/components/CommonButton';
import CommonTextField from '../../../../shared/components/CommonTextField';
import SnapChatIcon from '../../../../shared/icons/SnapChatIcon';
import {
  useCreateSnapchatConfigMutation,
  useUpdateSnapchatConfigMutation,
} from '../../../services/http/companies-platform-configs.api';
import { RefreshOutlined } from '@mui/icons-material';

export interface ISnapChatConfigFormProps {
  initialValues?: ICompanySnapchatConfigModel | null;
  companyId: string;
}

export interface ISnapChatConfigFormValues {
  adAccountId: string;
  clientId: string;
  profileId: string;
  clientSecret: string;
  marketingAccessToken: string;
  marketingRefreshToken: string;
}

const SnapChatConfigForm: FunctionComponent<ISnapChatConfigFormProps> = (
  props: ISnapChatConfigFormProps
): JSX.Element => {
  const { initialValues, companyId } = props;
  const [createSnapchatConfig, { isLoading: snapchatCreationLoading }] = useCreateSnapchatConfigMutation();
  const [updateSnapchatConfig, { isLoading: snapchatUpdateLoading }] = useUpdateSnapchatConfigMutation();

  const { getFieldProps, touched, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      adAccountId: initialValues?.adAccountId || '',
      profileId: initialValues?.profileId || '',
      clientId: initialValues?.clientId || '',
      clientSecret: initialValues?.clientSecret || '',
      marketingAccessToken: initialValues?.marketingAccessToken || '',
      marketingRefreshToken: initialValues?.marketingRefreshToken || '',
    },
    validationSchema: yup.object().shape({
      adAccountId: yup.string().required('Ad account id is required'),
      profileId: yup.string(),
      clientId: yup.string().required('Client id is required'),
      clientSecret: yup.string().required('Client secret is required'),
      marketingAccessToken: yup.string(),
      marketingRefreshToken: yup.string(),
    }),
    onSubmit: async (values: ISnapChatConfigFormValues) => {
      !initialValues?.id
        ? await createSnapchatConfig({ values, companyId }).unwrap()
        : await updateSnapchatConfig({
            values,
            id: initialValues.id,
          }).unwrap();
      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });

  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Box sx={{ ml: 5 }}>
              <SnapChatIcon fontSize="large" color="#7b68ee"></SnapChatIcon>
            </Box>
            <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
              Snapchat
            </Typography>
          </Box>
        </Box>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <CommonTextField
            {...getFieldProps('adAccountId')}
            label="Account id"
            type="text"
            required
            error={Boolean(touched.adAccountId && errors.adAccountId)}
            helperText={touched.adAccountId && (errors?.adAccountId as string)}
          />
          <CommonTextField
            sx={{ mt: '15px' }}
            type="text"
            {...getFieldProps('clientId')}
            label="Client id"
            onChange={handleChange}
            required
            error={Boolean(touched.clientId && errors.clientId)}
            helperText={touched.clientId && (errors?.clientId as string)}
          />
          <CommonTextField
            sx={{ mt: '15px' }}
            type="text"
            {...getFieldProps('profileId')}
            label="Profile Id"
            onChange={handleChange}
            required
            error={Boolean(touched.profileId && errors.profileId)}
            helperText={touched.profileId && (errors?.profileId as string)}
          />
          <CommonTextField
            sx={{ mt: '15px' }}
            type="text"
            {...getFieldProps('clientSecret')}
            label="Client secret"
            required
            error={Boolean(touched.clientSecret && errors.clientSecret)}
            helperText={touched.clientSecret && (errors?.clientSecret as string)}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', gap: 2 }}>
            <CommonTextField
              type="text"
              {...getFieldProps('marketingAccessToken')}
              label="Marketing Access Token"
              required
              error={Boolean(touched.marketingAccessToken && errors.marketingAccessToken)}
              helperText={touched.marketingAccessToken && (errors?.marketingAccessToken as string)}
            />
            {/* <Tooltip title="Refresh token" placement="right" arrow={true}>
              <IconButton>
                <RefreshOutlined></RefreshOutlined>
              </IconButton>
            </Tooltip> */}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', gap: 2 }}>
            <CommonTextField
              {...getFieldProps('marketingRefreshToken')}
              label="Marketing Refresh Token"
              required
              error={Boolean(touched.marketingRefreshToken && errors.marketingRefreshToken)}
              helperText={touched.marketingRefreshToken && (errors?.marketingRefreshToken as string)}
            />
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2,
              }}>
              <CommonButton type="submit" disabled={snapchatCreationLoading || snapchatUpdateLoading}>
                {initialValues ? 'Update' : 'Create'}
              </CommonButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};
export default SnapChatConfigForm;
