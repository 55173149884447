import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Grid, Typography } from '@mui/material';

interface ISormeglerenTimeLeftProps {
  startTime: string;
  endTime: string;
}

const SormeglerenTimeLeft: React.FC<ISormeglerenTimeLeftProps> = (props: ISormeglerenTimeLeftProps): JSX.Element => {
  const { startTime, endTime } = props;

  const [timeWhenStart, setTimeWhenStart] = useState<string | null>();
  const [timeWhenEnd, setTimeWhenEnd] = useState<string | null>();
  const [duration, setDuration] = useState<string | null>();

  useEffect(() => {
    setTimeWhenStart(moment(startTime).format('MMMM Do YYYY'));
    setTimeWhenEnd(moment(endTime).format('MMMM Do YYYY'));
    setDuration(moment.duration(moment(endTime).diff(moment(startTime))).humanize());
  }, [startTime, endTime]);

  return (
    <Grid container columnGap={1} my={1} mb={{ xs: 3, sm: 0 }} sx={{ height: '4rem' }}>
      <Grid item xs>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          sx={{ backgroundColor: '#1e0e3c', width: '100%', height: '100%' }}>
          <Typography ml={'4px'} variant={'h6'} sx={{ color: '#fff', textAlign: 'left' }}>
            Kampanjen startet
          </Typography>
          <Typography ml={'4px'} variant={'h6'} sx={{ color: '#fff', textAlign: 'left' }}>
            {timeWhenStart}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ backgroundColor: '#1e0e3c', width: '100%', height: '100%' }}>
          <Typography variant={'h6'} sx={{ color: '#fff' }}>
            Kampanjeperiode {duration}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignItems={'flex-start'}
          sx={{ backgroundColor: '#e14707', width: '100%', height: '100%' }}>
          <Typography ml={'4px'} variant={'h6'} sx={{ color: '#fff', textAlign: 'left' }}>
            Kampanjen avsluttet
          </Typography>
          <Typography ml={'4px'} variant={'h6'} sx={{ color: '#fff', textAlign: 'left' }}>
            {timeWhenEnd}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SormeglerenTimeLeft;
