import { IRoute } from "../../shared/models/route.model";
import ApplicationLogsTable from "../pages/application-logs/ApplicationLogsTable";

export const ROUTE_APP_LOGS = Object.freeze({
  AdminApplicationLogsTablePage: "application-logs",
});

export const ApplicationLogsRoutes: IRoute[] = [
  {
    path: ROUTE_APP_LOGS.AdminApplicationLogsTablePage,
    component: ApplicationLogsTable,
  },
];
