import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { SymfonyRolesEnum } from '@/auth/services/accesstoken.handler';
import CommonButton from '../../../../shared/components/CommonButton';
import { useGetMeQuery } from '../../../services/http/users.api';
import UserOrderOverviewTable from '@/shared/components/order-overviews-tables/UserOrderOverviewTable';
import OfficeAdminOrderOverviewTable from '@/shared/components/order-overviews-tables/OfficeAdminOrderOverviewTable';
import CompanyAdminOrderOverviewTable from '@/shared/components/order-overviews-tables/CompanyAdminOrderOverviewTable';
import Loader from '@/shared/components/Loader';
import { useTranslation } from 'react-i18next';

const OrdersTableWrapper: React.FC = (): JSX.Element => {
  const [dataToShow, setDataToShow] = useState(1);

  const { data, isLoading, isFetching, refetch, isUninitialized } = useGetMeQuery();

  const { t } = useTranslation();

  useEffect(() => {
    refetch();
  }, [isUninitialized]);

  return (
    <div>
      {isLoading || isFetching ? (
        <Loader />
      ) : (
        <Box>
          <Box sx={{ display: 'flex', mb: '10px' }}>
            <CommonButton disabled={dataToShow === 1} onClick={() => setDataToShow(1)}>
              {t('yourData')}
            </CommonButton>
            {data?.roles?.includes(SymfonyRolesEnum.OFFICE_ADMIN) && (
              <CommonButton disabled={dataToShow === 2} sx={{ ml: '10px' }} onClick={() => setDataToShow(2)}>
                {t('officeData')}
              </CommonButton>
            )}
            {data?.roles?.includes(SymfonyRolesEnum.COMPANY_ADMIN) && (
              <CommonButton disabled={dataToShow === 3} sx={{ ml: '10px' }} onClick={() => setDataToShow(3)}>
                {t('companyData')}
              </CommonButton>
            )}
          </Box>
          <Box mt={3}>
            {dataToShow === 1 && <UserOrderOverviewTable />}
            {dataToShow === 2 && <OfficeAdminOrderOverviewTable />}
            {dataToShow === 3 && <CompanyAdminOrderOverviewTable />}
          </Box>
        </Box>
      )}
    </div>
  );
};

export default OrdersTableWrapper;
