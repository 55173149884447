import { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import MetaPreviewSummary from '@/shared/components/platform-previews/previews/meta/MetaPreviewSummary';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import { Box, Card, CardContent, Divider, Grid, Paper, Typography } from '@mui/material';
import { FC } from 'react';
import SectionHeader from '../SectionHeader';
import DatesBreakDown from '../DatesBreakdown';
import GenderBreakDown from './GenderBreakdown';
import { IBasicBreakdownModel } from '@/shared/models/advertisements/statistics/meta-stats.model';
import NoData from '@/statistics/templates/shared/NoData';
import { useTranslation } from 'react-i18next';

export interface IEieMetaSectionProps {
  advertisement: IFullAdvertisementModel;
}

const EieMetaSection: FC<IEieMetaSectionProps> = (props: IEieMetaSectionProps): JSX.Element => {
  const { advertisement } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <SectionHeader
          platform="Meta"
          totalClicks={advertisement.advertisementStats.metaStats?.totalClicks || 0}
          totalImpressions={advertisement.advertisementStats.metaStats?.totalImpressions || 0}
        />

        <CardContent>
          <Grid container>
            <Grid item xs={12} md={5} display={'flex'}>
              <MetaPreviewSummary selectedType={advertisement.creative.creativeType as CreativeType} />
            </Grid>
            {(advertisement.advertisementStats?.metaStats?.totalClicks as number) > 50 ? (
              <Grid item xs={12} md={6} mx={'auto'}>
                <Divider sx={{ mt: 2, display: { md: 'none' } }} />
                <DatesBreakDown
                  datesBreakdown={advertisement.advertisementStats.metaStats?.datesBreakdown as IBasicBreakdownModel[]}
                />
                <Divider sx={{ mt: 2 }} />
                <GenderBreakDown advertisement={advertisement} />
              </Grid>
            ) : (
              <Grid item xs={12} m={2} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography m={2} variant="h2">
                  {t('adWithNoStats')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EieMetaSection;
