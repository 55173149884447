import { IRoute } from '../shared/models/route.model';
import OnlyUnAuthorizedUserGuard from './guards/OnlyUnAthorizedUserGuard';
import AuthLayout from './layout/AuthLayout';
import SignIn from './sign-in/SignIn';
import ForgetPassword from './sign-in/forget-password/ForgetPassword';

export const authRoutes: IRoute[] = [
  {
    path: '/',
    component: AuthLayout,
    guard: OnlyUnAuthorizedUserGuard,
    children: [
      {
        path: 'sign-in',
        component: SignIn,
      },
      {
        path: 'forgot-pwd',
        component: ForgetPassword,
      },
    ],
  },
];
