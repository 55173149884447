import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@mui/material";

const NotFound: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Box
      component="div"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px",
        textAlign: "center",
        background: "transparent",
      }}
    >
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Page not found.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        The page you are looking for might have been removed.
      </Typography>
      <Button
        variant="contained"
        sx={{ width: "200px", height: "40px" }}
        onClick={() => navigate(-1)}
      >
        Return To MakeAds
      </Button>
    </Box>
  );
};

export default NotFound;
