import { useAppDispatch } from '@/store/hooks/redux';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { imageCroppedChannel } from '../event-bus/image-cropped-channel';
import { setImageCropper } from '@/editor/store/reducers/modals-drawers.slice';
import { setRecentlyCroppedImageId } from '@/editor/store/reducers/creative-step.slice';
import { ILinkToUploadMedia } from '@/shared/models/media-library/link-to-upload-media.model';

const useSubscribeToImageCroppedEvent = (uploadFile: (file: File) => Promise<ILinkToUploadMedia | null>) => {
  const fnRef = useRef(uploadFile);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    fnRef.current = uploadFile;
  }, [uploadFile]);

  useEffect(() => {
    const fn = async (file: File) => fnRef.current(file);
    const subscriptionToImageCroppedEvent = imageCroppedChannel.on('onImageCropped', async ({ image }) => {
      await fn(image).then((data): void => {
        if (!data) {
          return;
        }
        dispatch(setRecentlyCroppedImageId({ id: data.propertyFileId }));

        dispatch(
          setImageCropper({
            isOpened: false,
            image: null,
            platform: null,
            propertyInternalId: null,
            widthToCrop: 0,
            heightToCrop: 0,
          })
        );
      });
    });

    // clear subscriptions
    return () => {
      subscriptionToImageCroppedEvent.unsubscribe();
    };
  }, [fnRef, dispatch]);
};

export default useSubscribeToImageCroppedEvent;
