const CreativeTypesEnum = Object.freeze({
  SINGLE: 'single',
  DYNAMIC: 'dynamic',
  VIDEO: 'video',
  CAROUSEL: 'carousel',
});

export type CreativeType = 'single' | 'dynamic' | 'video' | 'carousel';

export default CreativeTypesEnum;
