import {IBasePaginatedModel} from '../../../shared/models/base.paginated.model';
import {IPaginatedQueryParams} from '../../../shared/models/paginated-query-params';
import {TypeIdsDefinitions, api} from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import {IApplicationLogsModel} from "../../../shared/models/application-logs.model";

const baseUrl = 'admin/application-logs';

export const applicationLogsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedApplicationLogs: build.query<IBasePaginatedModel<IApplicationLogsModel>, IPaginatedQueryParams>({
      query: ({page, pageSize, search}) => ({
        url: baseUrl,
        params: {page, pageSize, search},
      }),
      providesTags: (result) =>
        providesList(
          result?.items ?? [],
          'ApplicationLogs',
          TypeIdsDefinitions.partialListTypeId
        ),
    }),
  }),
});

export const {
  useGetPaginatedApplicationLogsQuery,
} = applicationLogsApi;
