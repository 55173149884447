import { api } from '@/shared/services/api/api';

const baseUrl = 'admin/languages';
const userUrl = 'languages';

export interface ILanguage {
  locale: string;
  messages: {[key: string]: string};
  name: string;
}
export interface ILanguageDefault {
  locale: string;
  name: string;
}
export interface IDataGrid {
  totalCount: number;
  totalPages: number;
  hasPrev: boolean;
  hasNext: boolean;
  items: ILanguageDefault[];
}

export const languagesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getLanguage: build.query<ILanguage, string>({
      query: (locale) => `${baseUrl}/${locale}`,
    }),
    getLanguagesForUser: build.query<ILanguageDefault[], void>({
      query: () => `${userUrl}/`,
    }),
    getLanguageMessageCopy: build.query<ILanguage, string>({
      query: (locale) => `${baseUrl}/${locale}?messages=1`,
    }),
    getAllLanguages: build.query<IDataGrid, void>({
      query: () => `${baseUrl}/datagrid?page=0`,
    }),
    getLanguageMessages: build.query<ILanguage, string>({
      query: (locale) => `${baseUrl}/${locale}/messages`,
    }),
    createLanguage: build.mutation<any, ILanguageDefault>({
      query: (data) => ({
        url: `${baseUrl}/`,
        method: 'POST',
        body: data,
      }),
    }),
    updateLanguage: build.mutation<any, ILanguage>({
      query: (data: ILanguage) => ({
        url: `${baseUrl}/${data.locale}`,
        method: 'PUT',
        body: data,
      }),
    }),
    deleteLanguage: build.mutation<any, ILanguageDefault>({
      query: (data) => ({
        url: `${baseUrl}/${data.locale}`,
        method: 'DELETE',
        body: data,
      }),
    }),
    setDefaultLanguage: build.mutation<void, string>({
      query: (locale) => ({
        url: `${baseUrl}/${locale}/set-default`,
        method: 'POST',
        body: locale,
      }),
    }),
  }),
});

export const {
  useGetLanguageQuery,
  useGetLanguagesForUserQuery,
  useGetLanguageMessageCopyQuery,
  useGetAllLanguagesQuery,
  useGetLanguageMessagesQuery,
  useCreateLanguageMutation,
  useUpdateLanguageMutation,
  useDeleteLanguageMutation,
  useSetDefaultLanguageMutation,
} = languagesApi;
