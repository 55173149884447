import { Card, Grid } from '@mui/material';
import { FormikProvider, FormikTouched, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { ICarouselPreviewProps } from '@/shared/components/platform-previews/previews/carousel-preview.props';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { setCreativeItemsOnCarousel, setcreativeSubmissionProgress } from '@/editor/store/reducers/creative-step.slice';
import { PlatformType } from '../../../constants/available-platforms';
import { IEditorOrderFormPropertiesBaseModel } from '../editor-order-form-properties-base.model';
import BasicCreativeForm from './BasicCreativeForm';
import { ICarouselCreativeItemListProps } from './CarouselCreativeItemsList';
import { IBasicPreviewPropsModel } from './models/basic-preview-props.model';
import { ICarouselCreativeFormValuesModel } from './models/carousel-creative-form-values';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import { SendOutlined } from '@mui/icons-material';
import useFormSubmission from '@/editor/shared/hooks/formSubmission';

export interface IBasicCarouselFormProps extends IEditorOrderFormPropertiesBaseModel<ICarouselCreativeFormValuesModel> {
  basePreview: React.FC<IBasicPreviewPropsModel>[];
  concretePreview: React.FC<ICarouselPreviewProps>[];
  fieldsRequired: boolean;
  platform: PlatformType;
  validationSchema: any;
  initialTouched?: FormikTouched<ICarouselCreativeFormValuesModel> | undefined;
  carouselItems?: React.FC<ICarouselCreativeItemListProps>;
}

const BasicCarouselForm: React.FC<IBasicCarouselFormProps> = ({
  initialValues,
  fieldsRequired,
  platform,
  basePreview,
  concretePreview,
  initialTouched,
  validationSchema,
  carouselItems,
  updating,
}): JSX.Element => {
  const formSubmissionProgress = useAppSelector((state) => state.creativeStepSliceReducer.creativeSubmissionProgress);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema: validationSchema,
    initialTouched,
    onSubmit: () => {},
  });

  const { handleSubmit } = useFormSubmission({ formik, formSubmissionProgress, updating, platform });

  const handleUpdate = () => {
    dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
  };

  useEffect(() => {
    handleSubmit();
  }, [formSubmissionProgress.active]);

  return (
    <div>
      <FormikProvider value={formik}>
        <BasicCreativeForm
          areFieldsRequired={fieldsRequired}
          platform={platform}
          carouselItems={carouselItems}
          initialValues={initialValues!}
          concretePreviews={concretePreview}
          basePreviews={basePreview}
        />
        {updating && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', pb: 2 }}>
                <CommonButton
                  size="small"
                  type="button"
                  onClick={() => handleUpdate()}
                  endIcon={<SendOutlined fontSize="small" />}>
                  {t('update')}
                </CommonButton>
              </Card>
            </Grid>
          </Grid>
        )}
      </FormikProvider>
    </div>
  );
};

export default BasicCarouselForm;
