import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/redux';
import { setLeaveAdCreationSteps } from '../../../store/reducers/modals-drawers.slice';
import CommonButton from '../../../../shared/components/CommonButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LeaveAdCreativeStepsModal: React.FC = (): JSX.Element => {
  const modalOpened = useAppSelector((state) => state.editorModalsDrawersReducer.leaveAdCreationSteps);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const handleClose = (agreed: boolean) => {
    dispatch(setLeaveAdCreationSteps({ isOpened: false, pathNavigateTo: null }));
    const routeToNav = modalOpened.pathNavigateTo;
    if (agreed && routeToNav) {
      navigate(routeToNav);
    }
  };

  return (
    <Dialog open={modalOpened.isOpened} onClose={() => handleClose(false)}>
      <DialogTitle>{t('leaveAdCreation')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{t('leaveAdCreationWarning')}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CommonButton onClick={() => handleClose(false)}>{t('stayOnCreation')}</CommonButton>
        <CommonButton disableRipple color="error" onClick={() => handleClose(true)}>
          {t('leaveCreation')}
        </CommonButton>
      </DialogActions>
    </Dialog>
  );
};

export default LeaveAdCreativeStepsModal;
