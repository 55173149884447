import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditProviderForm from './components/CreteEditProviderForm';

const CreateEditProviderPage = (): JSX.Element => {
  const { providerId } = useParams();

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          <CreateEditProviderForm isUpdating={!!providerId}></CreateEditProviderForm>
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default CreateEditProviderPage;
