import { UserCreateEditStyles } from '@/admin/pages/users/create-edit/styles';
import { useSendEmailOrderShareTokenMutation } from '@/editor/services/http/orders.api';
import CommonTextField from '@/shared/components/CommonTextField';
import { Box, Button } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import { FC } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

interface ISendUsersEmailsFormValues {
  usersEmails: string;
}

const validationSchema = yup.object().shape({
  usersEmails: yup.string().required('Users emails comma separated required'),
});

const SendEmailWithSharedTokenForm: FC = (): JSX.Element => {
  const { id } = useParams();

  const [sendEmail, { isLoading: sendingLoading, isError }] = useSendEmailOrderShareTokenMutation();

  const handleSubmit = async (values: ISendUsersEmailsFormValues) => {
    toast.promise(sendEmail({ id: id!, usersEmails: values.usersEmails }), {
      success: 'Emails have been sent successfully',
      loading: 'Updating',
      error: (err) => {
        console.log(err);
        return 'Error while sending emails';
      },
    });
    console.log(isError);
  };

  const initialValues: ISendUsersEmailsFormValues = {
    usersEmails: '',
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: ISendUsersEmailsFormValues) => {
          await handleSubmit(values);
        }}>
        {({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <CommonTextField
              sx={{ mt: 3 }}
              type="text"
              name="usersEmails"
              label="Users emails. Comma separated"
              value={values.usersEmails}
              onChange={handleChange}
              required
              error={Boolean(touched.usersEmails && errors.usersEmails)}
              helperText={touched.usersEmails && errors.usersEmails}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={UserCreateEditStyles.elementsMarginTop}
                type="submit"
                variant="contained"
                disabled={sendingLoading}>
                Notify users
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SendEmailWithSharedTokenForm;
