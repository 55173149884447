import { useNavigate, useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import { Box, Card, CardActions, CardContent, CardHeader, Container, Grid, Typography } from '@mui/material';
import Loader from '../../../shared/components/Loader';
import { useGetAutoCreationConfigsByCompanyIdQuery } from '../../services/http/companies-auto-creation-configs.api';
import CommonButton from '../../../shared/components/CommonButton';
import { IAutoCreationConfigModel } from '../../../shared/models/companies/autocreation/auto-creation-config.model';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { useCallback } from 'react';

const CompanyAutoCreationConfigsListPage: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data, isLoading } = useGetAutoCreationConfigsByCompanyIdQuery({
    id: id!,
  });

  const handleNavigateToAddEdit = (item: IAutoCreationConfigModel | null | undefined) => {
    item ? navigate(`${item.id}/edit`) : navigate(`new`);
  };

  const resolveIcon = useCallback((value: boolean) => {
    return value ? (
      <DoneTwoToneIcon fontSize="small" htmlColor="#98D8AA" />
    ) : (
      <CloseTwoToneIcon fontSize="small" htmlColor="#F45050" />
    );
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Container maxWidth="xl">
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <CommonButton onClick={() => handleNavigateToAddEdit(null)}>Add configuration</CommonButton>
            </Box>
            {data?.items?.length && data?.items?.length > 0 ? (
              <Grid container spacing={3} sx={{ mt: 2 }}>
                {data.items.map((config, key) => {
                  return (
                    <Grid key={key} item xs={12} md={6}>
                      <Card sx={{ p: 2 }} variant="outlined">
                        <CardActions sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <CommonButton onClick={() => handleNavigateToAddEdit(config)}>Edit</CommonButton>
                        </CardActions>
                        <CardHeader
                          title={
                            <Typography variant="h4" color="secondary" fontWeight="bold">
                              {'Type - ' + config.type.charAt(0).toUpperCase() + config.type.slice(1).replace('_', ' ')}
                            </Typography>
                          }></CardHeader>
                        <CardContent>
                          <Typography variant="h5">Title - {config.title}</Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Message - {config.message}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Description - {config.description}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Caption - {config.caption}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Link - {config.link ?? 'Empty'}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Delta active - {resolveIcon(config.isDeltaActive)}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Meta active - {resolveIcon(config.isMetaActive)}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Snapchat active - {resolveIcon(config.isSnapchatActive)}
                          </Typography>
                          <Typography variant="h5" sx={{ mt: 2 }}>
                            Bidtheatre active - {resolveIcon(config.isBidtheatreActive)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography color="secondary" variant="h4">
                    No Auto creation configs. Start from adding first one
                  </Typography>
                  <CommonButton sx={{ mt: 2 }} onClick={() => handleNavigateToAddEdit(null)}>
                    Add first config
                  </CommonButton>
                </Box>
              </AdminBaseFormWrapper>
            )}
          </Container>
        </div>
      )}
    </div>
  );
};

export default CompanyAutoCreationConfigsListPage;
