import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import { Grid } from '@mui/material';
import StatisticsHeader from './components/StatisticsHeader';
import EieTimeLineProgress from '../eie/components/EieTimeLineProgress';
import CampaignMetaBarCharts from '../eie/components/CampaignMetaBarCharts';
import EieMetaSection from '../eie/components/meta-section/EieMetaSection';
import EieDeltaSection from '../eie/components/delta-section/EieDeltaSection';
import EieBidtheatreSection from '../eie/components/bidtheatre-section/EieBidtheatreSection';
import EieSnapchatSection from '../eie/components/snapchat-section/EieSnapchatSection';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import EieBoardsSection from '../eie/components/boards-section/EieBoardsSection';

export interface IDefaultTemplateProps {
  order: ISingleOrderWithDetailsModel;
}

const DefaultTemplate: React.FC<IDefaultTemplateProps> = ({ order }): JSX.Element => {
  const findAd = (platform: PlatformType): IFullAdvertisementModel | null => {
    return order.advertisements.find((el) => el.platform === platform) ?? null;
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <StatisticsHeader order={order}></StatisticsHeader>
        </Grid>
        <Grid container spacing={1} item m={{ md: 4, xs: 0 }}>
          <EieTimeLineProgress order={order} />

          <Grid item xs={12}>
            <CampaignMetaBarCharts order={order} />
          </Grid>

          {findAd('meta') && (
            <Grid item xs={12}>
              <EieMetaSection advertisement={findAd('meta')!}></EieMetaSection>
              {/*<EieInstagramSection advertisement={findAd('meta')!} />*/}
            </Grid>
          )}
          {findAd('delta') && (
            <Grid item xs={12}>
              <EieDeltaSection advertisement={findAd('delta')!} extCompanyId={order.companyExternalId} />
            </Grid>
          )}
          {findAd('bidtheatre') && (
            <Grid item xs={12}>
              <EieBidtheatreSection advertisement={findAd('bidtheatre')!} extCompanyId={order.companyExternalId} />
            </Grid>
          )}
          {findAd('boards') && (
            <Grid item xs={12}>
              <EieBoardsSection advertisement={findAd('boards')!} extCompanyId={order.companyExternalId} />
            </Grid>
          )}
          {findAd('snapchat') && <EieSnapchatSection advertisement={findAd('snapchat')!} />}
        </Grid>
      </Grid>
    </div>
  );
};

export default DefaultTemplate;
