import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { useDeleteProviderMutation, useGetProvidersPaginatedQuery } from '../../services/http/retail-data-provider.api';
import { ROUTE_RETAIL_PROVIDERS_DECLARATIONS } from '../../routes/retail-data-providers-routes';
import { IRetailDataProvider } from '@/shared/models/retail-data-provider.model';
import { debounce } from '@/shared/hooks/debounce';
import { COLORS } from '@/shared/styles/colors';
import { useTranslation } from 'react-i18next';
import { Delete, DeleteOutline, Edit, EditOutlined } from '@mui/icons-material';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'title',
    name: 'Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const RetailProvidersDataTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading, isFetching } = useGetProvidersPaginatedQuery({
    page,
    pageSize,
    search,
  });

  const { t } = useTranslation();

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  const [deleteProvider, { isLoading: deleteProviderLoading }] = useDeleteProviderMutation();

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircleIcon />,
        action: () =>
          navigate(
            `${ROUTES_DECLARATIONS.AdminPanel}/${ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersNewPage}`
          ),
      },
    ];
  }, []);

  const mapProviders = (data?: IRetailDataProvider[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          title: el.title,
          createdAt: el.createdAt,
          actions: [
            {
              title: t('edit'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/providers/${el.id}/edit`),
            },
            {
              title: t('delete'),
              icon: <Delete />,
              action: () => deleteProvider({ id: el.id }),
              color: COLORS.delete,
            },
          ],
        }))
      : [];
  };

  return (
    <div>
      <AdminTableWrapper
        header={t('retailDataProviders')}
        isLoading={isLoading || isFetching}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapProviders(data?.items as IRetailDataProvider[]),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default RetailProvidersDataTable;
