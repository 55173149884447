import { IRoute } from '@/shared/models/route.model';
import Integrations from '../components/Integrations';
import ConnectionsLayout from '@/connections/layout/ConnectionsLayout';

export const integrationsRoutes: IRoute[] = [
  {
    path: '/integrations',
    component: ConnectionsLayout,
    children: [
      {
        path: ':provider',
        component: Integrations,
      },
    ],
  },
];

// '?installationId=installationId&estateId=estateId&orderId=orderId&userId=userId&userName=userName&userEmail=userEmail&matrikkel=matrikkel&shareno=shareno&assignmentno=assignmentno&deptId=deptId',
// https://api.makeads.app/api/v2/
