import React from 'react';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditCompanyForm from './components/CreateEditCompanyForm';
import { Grid } from '@mui/material';
import CompanyBranding from './components/CompanyBranding';

const CreateEditCompanyPage = (): JSX.Element => {
  const { id } = useParams();

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <AdminBaseFormWrapper>
            <CreateEditCompanyForm isUpdating={!!id}></CreateEditCompanyForm>
          </AdminBaseFormWrapper>
        </Grid>
        {!!id && (
          <Grid item xs={12} sx={{ mt: 5 }}>
            <AdminBaseFormWrapper>
              <CompanyBranding />
            </AdminBaseFormWrapper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CreateEditCompanyPage;
