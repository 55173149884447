import React, { useEffect, useState } from 'react';
import { IDataGrid, ILanguageDefault, useUpdateLanguageMutation } from '@/admin/services/http/languages.api';
import LanguageEditorPresentational from './LanguageEditorPresentational';
import { SelectChangeEvent } from '@mui/material';
import LanguageTableContainer from '@/admin/pages/languages/components/LanguageTableContainer';
import { useTranslation } from 'react-i18next';
// import sw from '@/shared/locales/swCamelCase.json';
// import no from '@/shared/locales/noCamelCase.json';
// import en from '@/shared/locales/enCamelCase.json';

interface ILanguageEditorContainerProps {
  languageData: IDataGrid | undefined;
}

const LanguageEditorContainer: React.FC<ILanguageEditorContainerProps> = ({ languageData }): JSX.Element => {
  const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
  const [selectedTranslatedLanguage, setTranslatedLanguage] = useState<string>('no');
  const [availableLanguages, setAvailableLanguages] = useState<ILanguageDefault[]>([]);

  // this is for manual update of the languages

  // const [updateLanguage] = useUpdateLanguageMutation();
  // const [createLanguage] = useCreateLanguageMutation();
  // const [setDefaultLang] = useSetDefaultLanguageMutation();

  // useEffect(() => {
  //   updateLanguage({ locale: 'sw', name: 'Swedish', messages: sw as any });
  //   updateLanguage({ locale: 'no', name: 'Norwegian', messages: no as any });
  //   updateLanguage({ locale: 'en', name: 'English', messages: en as any });
  // }, []);

  const { t } = useTranslation();

  const handleTranslations = (word: string): string => {
    return t(word);
  };

  const handleChangeSourceLanguage = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value);
  };

  const handleChangeTranslatedLanguage = (event: SelectChangeEvent) => {
    setTranslatedLanguage(event.target.value);
  };

  const handleLanguageSwitch = () => {
    const temp = selectedLanguage;
    setSelectedLanguage(selectedTranslatedLanguage);
    setTranslatedLanguage(temp);
  };

  useEffect(() => {
    if (languageData) {
      setAvailableLanguages(languageData.items);
      setSelectedLanguage(languageData.items[0].locale);
      setTranslatedLanguage(languageData.items[1].locale);
    }
  }, [languageData]);

  return (
    <div>
      <LanguageEditorPresentational
        availableLanguages={availableLanguages}
        selectedLanguage={selectedLanguage}
        selectedTranslatedLanguage={selectedTranslatedLanguage}
        handleChangeSourceLanguage={handleChangeSourceLanguage}
        handleChangeTranslatedLanguage={handleChangeTranslatedLanguage}
        handleLanguageSwitch={handleLanguageSwitch}
        handleTranslations={handleTranslations}
      />
      <LanguageTableContainer
        selectedLanguage={selectedLanguage}
        selectedTranslatedLanguage={selectedTranslatedLanguage}
        availableLanguages={availableLanguages}
        handleTranslations={handleTranslations}
      />
    </div>
  );
};

export default LanguageEditorContainer;
