import { Box, Tab, Tabs } from '@mui/material';
import React, { FC } from 'react';
import MediaLibraryVideos from './MediaLibraryVideos';
import MoovlyCreatorTab from './video-creator/MoovlyCreatorTab';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`video-library-tab-${index}`}
      aria-labelledby={`video-library-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `video-library-tab-${index}`,
    'aria-controls': `video-library-tab-${index}`,
  };
}

const MediaLibraryVideosWrapper: FC = (): JSX.Element => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <MediaLibraryVideos />
      </Box>
    </Box>
  );
};

export default MediaLibraryVideosWrapper;
