import { FC, useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, IconButton } from '@mui/material';
import { useGetMediaLibraryImagesQuery } from '../../../../services/http/media-library.api';
import { getValueFromLs } from '../../../../../shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '../../../../shared/constants/editor-LS.constants';
import { IMediaLibraryImageModel } from '../../../../../shared/models/media-library/media-library-image.model';
import Loader from '../../../../../shared/components/Loader';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import MediaUploader from '../../MediaUploader';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '@/store/hooks/redux';

interface IMoovlyImageSelectorDialogProps {
  handleImgSelected: (image: IMediaLibraryImageModel) => void;
  open: boolean;
  closeFn: () => void;
}

const MoovlyImageSelectorDialog: FC<IMoovlyImageSelectorDialogProps> = (
  props: IMoovlyImageSelectorDialogProps
): JSX.Element => {
  const { handleImgSelected, open, closeFn } = props;
  const [externalImages, setExternalImages] = useState<IMediaLibraryImageModel[]>([]);

  const propertyInternalId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  const { data, isLoading, refetch } = useGetMediaLibraryImagesQuery(
    { propertyId: propertyInternalId! },
    { skip: !propertyInternalId }
  );

  // useEffect(() => {
  //   if (data !== undefined) {
  //     setExternalImages(data.filter((item) => item.originalUrl === 'no_original_url' && item.sourceId === null));
  //     console.log(externalImages);
  //   }
  // }, [data, refetch]);

  return (
    <Dialog open={open} maxWidth={'md'} onClose={closeFn}>
      <DialogTitle>
        <MediaUploader concreteUploaderType="image" refetchAction={refetch}></MediaUploader>
        <IconButton
          aria-label="close"
          onClick={closeFn}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ p: 1 }}>
          {data && (
            <ImageList variant="masonry" cols={4} gap={8}>
              {data!.map((image: IMediaLibraryImageModel, key: number) => (
                <ImageListItem key={key} sx={{ cursor: 'pointer' }} onClick={() => handleImgSelected(image)}>
                  <img src={image.preSignedUrl} width="100%" style={{ borderRadius: '5px' }} alt={image.fileName} />
                </ImageListItem>
              ))}
            </ImageList>
          )}
        </Box>
      )}
    </Dialog>
  );
};

export default MoovlyImageSelectorDialog;
