import { IOGDisplayConfigFormValues } from '../../pages/companies-platform-configs/components/OGDisplayConfigForm';
import { IFinnConfigFormValues } from '../../pages/companies-platform-configs/components/FinnConfigForm';
import { ISnapChatConfigFormValues } from '../../pages/companies-platform-configs/components/SnapChatConfigForm';
import { IDeltaConfigFormValues } from '../../pages/companies-platform-configs/components/DeltaMediaConfigForm';
import { ICompanyPlatformConfigurationsModel } from '../../../shared/models/companies/platform-configs/company-platform-configurations.model';
import { api } from '../../../shared/services/api/api';
import { IMetaConfigFormValues } from '../../pages/companies-platform-configs/components/MetaConfigForm';
import { IBidTheaterConfigFormValues } from '../../pages/companies-platform-configs/components/BidTheatreConfigForm';

const baseURL = 'admin/company-platform-configs';

export const companiesPlatformConfigsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPlatformConfigsByCompanyId: build.query<ICompanyPlatformConfigurationsModel, { id: string }>({
      query: ({ id }) => ({
        url: `admin/companies/${id}/platform-configurations`,
      }),
      providesTags: (_result, _error, arg) => [{ type: 'CompanyPlatformsConfigs', id: arg.id }],
    }),
    createMetaConfig: build.mutation<void, { values: IMetaConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}/meta`,
        method: 'POST',
        body: { ...values, companyId },
      }),
      invalidatesTags: ['CompanyPlatformsConfigs'],
    }),
    updateMetaConfig: build.mutation<void, { values: IMetaConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/meta/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
    createDeltaConfig: build.mutation<void, { values: IDeltaConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}/delta`,
        method: 'POST',
        body: { ...values, companyId },
      }),
    }),
    updateDeltaConfig: build.mutation<void, { values: IDeltaConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/delta/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
    createSnapchatConfig: build.mutation<void, { values: ISnapChatConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}/snapchat`,
        method: 'POST',
        body: { ...values, companyId },
      }),
    }),
    updateSnapchatConfig: build.mutation<void, { values: ISnapChatConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/snapchat/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
    createFinnConfig: build.mutation<void, { values: IFinnConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}/finn`,
        method: 'POST',
        body: { ...values, companyId },
      }),
    }),
    updateFinnConfig: build.mutation<void, { values: IFinnConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/finn/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
    createOgDisplayConfig: build.mutation<void, { values: IOGDisplayConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}/ogdisplay`,
        method: 'POST',
        body: { ...values, companyId },
      }),
    }),
    updateOgDisplayConfig: build.mutation<void, { values: IOGDisplayConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/ogdisplay/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
    createBidTheatreConfig: build.mutation<void, { values: IBidTheaterConfigFormValues; companyId: string }>({
      query: ({ values, companyId }) => ({
        url: `${baseURL}/bidtheatre`,
        method: 'POST',
        body: { ...values, companyId },
      }),
    }),
    updateBidTheatreConfig: build.mutation<void, { values: IBidTheaterConfigFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseURL}/bidtheatre/${id}`,
        method: 'PUT',
        body: values,
      }),
    }),
  }),
});

export const {
  useGetPlatformConfigsByCompanyIdQuery,
  useCreateMetaConfigMutation,
  useCreateDeltaConfigMutation,
  useCreateSnapchatConfigMutation,
  useUpdateSnapchatConfigMutation,
  useUpdateDeltaConfigMutation,
  useUpdateMetaConfigMutation,
  useCreateFinnConfigMutation,
  useUpdateFinnConfigMutation,
  useCreateOgDisplayConfigMutation,
  useUpdateOgDisplayConfigMutation,
  useCreateBidTheatreConfigMutation,
  useUpdateBidTheatreConfigMutation
} = companiesPlatformConfigsApi;
