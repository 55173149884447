import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { IMediaLibraryVideoModel } from '../../../../shared/models/media-library/media-library-video.model';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks/redux';
import { useGetUserVideosQuery } from '../../../services/http/media-library.api';
import { AvailablePlatformsEnum } from '../../../shared/constants/available-platforms';
import { setMediaOnVideo } from '../../../store/reducers/creative-step.slice';
import List from '@mui/material/List';
import VideoListItem from './VideoListItem';
import CommonTextField from '../../../../shared/components/CommonTextField';
import { debounce } from '../../../../shared/hooks/debounce';
import { Search } from '@mui/icons-material';
import { getValueFromLs } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import VideosUploader from './VideosUploader';

export interface IAnchorVideoModel {
  element: null | HTMLElement;
  item: null | IMediaLibraryVideoModel;
}

export interface IVideoLibraryProps {
  videos?: IMediaLibraryVideoModel[];
}

const MediaLibraryVideos: FC<IVideoLibraryProps> = ({ videos }): JSX.Element => {
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState<string>('');
  const [videoData, setVideoData] = useState<IMediaLibraryVideoModel[]>(videos ?? []);
  const propertyInternalId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  const propertyFromLS = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);

  const { data, isLoading, isFetching, refetch: mediaLibraryVideosRefetch } = useGetUserVideosQuery();

  useEffect(() => {
    let vidData: IMediaLibraryVideoModel[] | undefined = search
      ? data?.filter((el) => el.fileName.toLowerCase().includes(search.trim().toLowerCase()))
      : data;
    setVideoData(vidData || []);
  }, [data, search]);

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>, item: IMediaLibraryVideoModel) => {
    dispatch(setMediaOnVideo({ platform: AvailablePlatformsEnum.META, media: item }));
  };

  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
          <VideosUploader refetchAction={mediaLibraryVideosRefetch} />

          <CommonTextField
            fullWidth={false}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
              handleSearchChange(e.target.value)
            }
          />
        </Grid>

        <Grid item xs={12}>
          {videoData && videoData?.length > 0 ? (
            <List>
              {videoData.map((el: IMediaLibraryVideoModel, key) => (
                <VideoListItem el={el} key={key} itemIndex={key} handleClickListItem={handleClickListItem} />
              ))}
            </List>
          ) : (
            <Box sx={{ mt: 5, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h4" color="secondary">
                No videos
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default MediaLibraryVideos;
