import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditProductForm from './components/CreateEditProductForm';

const CreateEditProductPage = (): JSX.Element => {
  const { id } = useParams();

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          <CreateEditProductForm isUpdating={!!id}></CreateEditProductForm>
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default CreateEditProductPage;
