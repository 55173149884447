import { Grid, Paper } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

const ConnectionsLayout: React.FC = (): JSX.Element => {
  return (
    <Grid container sx={{ height: '100vh', alignContent: 'center', justifyContent: 'center' }}>
      <Paper
        sx={{
          minHeight: '500px',
          width: { xs: '100%', md: '45%' },
          alignContent: 'center',
          justifyContent: 'center',
        }}>
        <Grid
          container
          item
          xs={12}
          mb={3}
          sx={{
            alignContent: 'center',
            display: { md: 'flex' },
            justifyContent: 'center',
            height: '30%',
          }}>
          <img src="/Makeads_logo.png" alt="logo"></img>
        </Grid>
        <Grid item xs={12} height={'70%'}>
          <Outlet />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ConnectionsLayout;
