import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAuthResponse } from "../../models/auth.response";

interface IAuthState {
  accessToken?: string | null;
  email?: string | null;
}

const initialState: IAuthState = {
  accessToken: null,
  email: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(state: IAuthState, action: PayloadAction<IAuthResponse>) {
      const { accessToken, email } = action.payload;
      state.accessToken = accessToken;
      state.email = email;
    },
    deleteCredentials(state: IAuthState, _: PayloadAction<void>) {
      state.accessToken = undefined;
      state.email = undefined;
    },
  },
});

export const { setCredentials, deleteCredentials } = authSlice.actions;

export default authSlice.reducer;
