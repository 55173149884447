import { IBidTheaterCategoryModel } from '../../../shared/models/companies/company.model';
import { IBidTheaterMedialistModel, IBidTheaterAudienceModel, IBidTheaterFilterModel } from '../../../shared/models/offices/office.model'
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
const baseUrl = '/bidtheatre';
export const bidTheatreApi = api.injectEndpoints({
  endpoints: (build) => ({
   
    getAllCategories: build.query<IBidTheaterCategoryModel[], void>({
      query: () => ({
        url: `${baseUrl}/categories`,
      }),
      providesTags: (result) => providesList(result ?? [], 'Categories', TypeIdsDefinitions.listTypeId),
    }), 
    getAllMedialist: build.query<IBidTheaterMedialistModel[], { id: string }>({
      query: ({id}) => ({
        url:`${baseUrl}/${id}/medialists`
      }),
      providesTags: (result) => providesList(result ?? [], 'Medialists' , TypeIdsDefinitions.listTypeId),
    }),
    getAllAudinces: build.query<IBidTheaterAudienceModel[], { id: string }>({
      query: ({id}) => ({
        url:`${baseUrl}/${id}/audiences`
      }),
      providesTags: (result) => providesList(result ?? [], 'Audiences' , TypeIdsDefinitions.listTypeId),
    }),
    getAllFilters: build.query<IBidTheaterFilterModel[], void>({
      query: () => ({
        url:`${baseUrl}/filters`
      }),
      providesTags: (result) => providesList(result ?? [], 'Filters' , TypeIdsDefinitions.listTypeId),
    }),
  })
})
export const {
    useGetAllCategoriesQuery,
    useGetAllMedialistQuery,
    useGetAllAudincesQuery,
    useGetAllFiltersQuery
} = bidTheatreApi;