import React from "react";
import { Paper } from "@mui/material";
import { Box, SxProps } from "@mui/system";
import { AdminFormWrapperStyles } from "./styles";

interface IAdminBaseFormWrapperProps {
  children?: JSX.Element | JSX.Element[];
  customMainWrapperStyles?: SxProps;
  customPaperWrapperStyles?: SxProps;
}

const AdminBaseFormWrapper: React.FC<IAdminBaseFormWrapperProps> = ({
  children,
  customMainWrapperStyles,
  customPaperWrapperStyles,
}): JSX.Element => {
  const mergeSxProps = (sxProps: SxProps, customSxProps?: SxProps) => {
    if (customSxProps) {
      return { ...sxProps, ...customSxProps } as SxProps;
    }

    return sxProps;
  }

  return (
    <Box component="div" sx={mergeSxProps(AdminFormWrapperStyles.mainWrapper, customMainWrapperStyles)}>
      <Paper sx={mergeSxProps(AdminFormWrapperStyles.paper, customPaperWrapperStyles)}>
        {children}
      </Paper>
    </Box>
  );
};

export default AdminBaseFormWrapper;
