import { Box } from '@mui/material';
import React from 'react';
import { A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ICarouselPreviewProps } from '../carousel-preview.props';
import EmptyImageCard from '../EmptyImageCard';

import 'swiper/css/navigation';
import 'swiper/swiper.min.css';

const CarouselSnapChatPreview: React.FC<ICarouselPreviewProps> = ({ items }): JSX.Element => {
  return (
    <Box sx={{ width: '100%' }}>
      {items?.length > 0 ? (
        <Swiper
          modules={[Navigation, A11y, Autoplay]}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          grabCursor={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}>
          {items.map((item, index) => (
            <SwiperSlide key={index}>
              {item.creativeMedia?.preSignedUrl ? (
                <img
                  style={{ width: '100%', height: '100%', minHeight: '450px', objectFit: 'cover' }}
                  src={item.creativeMedia?.preSignedUrl}
                  alt="preview"></img>
              ) : (
                <EmptyImageCard />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default CarouselSnapChatPreview;
