import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { IOrderOverviewModel } from '../../../shared/models/orders/order-overview.model';
import { IOrderModel } from '../../../shared/models/orders/order.model';
import { ISingleOrderWithDetailsModel } from '../../../shared/models/orders/single-order-with-details.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { api } from '../../../shared/services/api/api';

const baseUrl = 'orders';

interface IOrderCreationValues {
  propertyId: string;
  title: string;
  budgetId: string | null;
  productId:string | null;
  budgetValue: number;
}

export const customersOrdersApi = api.injectEndpoints({
  endpoints: (build) => ({
    createOrder: build.mutation<IOrderModel, IOrderCreationValues>({
      query: (values: IOrderCreationValues) => ({
        url: baseUrl,
        method: 'POST',
        body: values,
      }),
    }),
    getOrderWithDetailsById: build.query<ISingleOrderWithDetailsModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}/details`,
      }),
      providesTags: (result, error, arg) => [{ type: 'CustomersOrders', id: arg.id }],
    }),
    getOrderWithDetailsByIdSharedStatistics: build.query<ISingleOrderWithDetailsModel, { id: string; token: string }>({
      query: ({ id, token }) => ({
        url: `${baseUrl}/${id}/shared-statistics/details`,
        params: { token },
      }),
      providesTags: (result, error, arg) => [{ type: 'CustomersOrders', id: arg.id }],
    }),
    getSelfUserPaginatedOrders: build.query<IBasePaginatedModel<IOrderOverviewModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search, filters, sorting }) => ({
        url: `${baseUrl}`,
        params: {
          page,
          pageSize,
          search,
          filters,
          sorting,
        },
      }),
    }),
    getOfficeAdminPaginatedOrders: build.query<IBasePaginatedModel<IOrderOverviewModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search, filters, sorting }) => ({
        url: `${baseUrl}:officeAdmin`,
        params: {
          page,
          pageSize,
          search,
          filters,
          sorting,
        },
      }),
    }),
    getApplicationAdminPaginatedOrders: build.query<IBasePaginatedModel<IOrderOverviewModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search, filters, sorting }) => ({
        url: `admin/${baseUrl}`,
        params: {
          page,
          pageSize,
          search,
          filters,
          sorting,
        },
      }),
    }),
    getCompanyAdminPaginatedOrders: build.query<IBasePaginatedModel<IOrderOverviewModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search, filters, sorting }) => ({
        url: `${baseUrl}:companyAdmin`,
        params: {
          page,
          pageSize,
          search,
          filters,
          sorting,
        },
      }),
    }),
    activateOrStopOrder: build.mutation<void, { id: string; action: 'activate' | 'pause' }>({
      query: ({ id, action }) => ({
        url: `${baseUrl}/${id}/actions/${action}`,
        method: 'PUT',
      }),
      invalidatesTags: ['CustomersOrders'],
    }),
    sendEmailOrderShareToken: build.mutation<void, { id: string; usersEmails: string }>({
      query: ({ id, usersEmails }) => ({
        url: `${baseUrl}/${id}/actions:sendStatsCreds`,
        method: 'POST',
        body: { usersEmails },
      }),
    }),
  }),
});

export const {
  useCreateOrderMutation,
  useGetOrderWithDetailsByIdQuery,
  useGetOrderWithDetailsByIdSharedStatisticsQuery,
  useGetApplicationAdminPaginatedOrdersQuery,
  useGetSelfUserPaginatedOrdersQuery,
  useGetOfficeAdminPaginatedOrdersQuery,
  useGetCompanyAdminPaginatedOrdersQuery,
  useActivateOrStopOrderMutation,
  useSendEmailOrderShareTokenMutation,
} = customersOrdersApi;
