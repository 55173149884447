import { useEffect, useState } from 'react';
import { AvailablePlatformsEnum, PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum, { CreativeType } from '../../editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import {
  ICreativeFormsStatePerPlatform,
  refreshCreativeFormState,
  setCreativeFormValueForPlatform,
  setCreativeItemsOnCarousel,
  setMediaOnDynamic,
  setMediaOnSingle,
  setMediaOnVideo,
  setSelectedCreativeType,
} from '@/editor/store/reducers/creative-step.slice';
import { setActivePlatforms } from '@/editor/store/reducers/selected-platforms.slice';
import { useAppDispatch } from '@/store/hooks/redux';
import { IFullAdvertisementModel } from '../models/advertisements/full-advertisement-model';
import { ICarouselCreativeModel } from '../models/creatives/carousel-creative.model';
import { IDynamicCreativeModel } from '../models/creatives/dynamic-creative.model';
import { ISingleCreativeModel } from '../models/creatives/single-creative.model';
import { IVideoCreativeModel } from '../models/creatives/video-creative.model';
import { IMediaLibraryImageModel } from '../models/media-library/media-library-image.model';
import { IMediaLibraryVideoModel } from '../models/media-library/media-library-video.model';
import { ISingleOrderWithDetailsModel } from '../models/orders/single-order-with-details.model';
import { deleteKeyValuePairFromLS, setKeyValuePairToLS } from '../services/localstorage';

const usePrepareCreativeOrderDetailsAdvertisements = (orderData?: ISingleOrderWithDetailsModel | null) => {
  const dispatch = useAppDispatch();
  const [platformsOfOrder, setPlatformsOfOrder] = useState<PlatformType[]>([]);
  const [activePlatform, setActivePlatform] = useState<PlatformType>();
  const [ready, setReady] = useState<boolean>(false);
  const [firstRender, setFirstRender] = useState<boolean>(true);

  useEffect(() => {
    if (!orderData) return;
    const platforms = orderData.advertisements.map((ad) => ad.platform);

    if (firstRender) {
      dispatch(
        setSelectedCreativeType({
          creativeType:
            (orderData.advertisements.find((el) => el.platform === AvailablePlatformsEnum.META)?.creative
              .creativeType as CreativeType) ?? CreativeTypesEnum.SINGLE,
        })
      );
      setPlatformsOfOrder(platforms);
      setActivePlatform(orderData.advertisements[0].platform);
      dispatch(setActivePlatforms(platforms));
    }

    orderData.advertisements.forEach((ad) => {
      const {
        platform,
        creative: { creativeType },
      } = ad;

      // set base forms state
      dispatch(
        setCreativeFormValueForPlatform({
          platform,
          creativeType: creativeType as keyof ICreativeFormsStatePerPlatform,
          values: ad.creative,
        })
      );

      // set images or videos
      switch (creativeType) {
        case CreativeTypesEnum.SINGLE:
          prepareSingleFormMedia(dispatch, ad, platform);
          break;
        case CreativeTypesEnum.VIDEO:
          prepareVideoFormMedia(dispatch, ad, platform);
          break;
        case CreativeTypesEnum.CAROUSEL:
          prepareCarouselFormMediasAndItems(dispatch, ad, platform);
          break;
        case CreativeTypesEnum.DYNAMIC:
          prepareDynamicFormMedias(ad, dispatch);
          break;
      }
    });

    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID, orderData.propertyId);
    setReady(true);
    setFirstRender(false);

    return () => {
      dispatch(refreshCreativeFormState());
      deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);
      setReady(false);
      setFirstRender(true);
    };
  }, [orderData, dispatch]);

  return {
    platformsOfOrder,
    activePlatform,
    setActivePlatform,
    ready,
  };
};

const prepareCarouselFormMediasAndItems = (
  dispatch: Function,
  ad: IFullAdvertisementModel,
  adPlatform: PlatformType
) => {
  const carouselCreativeItems = (ad.creative as ICarouselCreativeModel).creativeItems.map((el) => ({
    ...el,
    creativeMedia: el.creativeMedia.propertyFile,
  }));
  dispatch(
    setCreativeItemsOnCarousel({
      platform: adPlatform,
      creativeItems: carouselCreativeItems,
    })
  );
};

const prepareSingleFormMedia = (dispatch: Function, ad: IFullAdvertisementModel, adPlatform: PlatformType) => {
  const creative = ad.creative as ISingleCreativeModel;
  dispatch(
    setMediaOnSingle({
      media: creative.media.propertyFile as IMediaLibraryImageModel,
      platform: adPlatform,
    })
  );
};

const prepareVideoFormMedia = (dispatch: Function, ad: IFullAdvertisementModel, adPlatform: PlatformType) => {
  const creative = ad.creative as IVideoCreativeModel;
  dispatch(
    setMediaOnVideo({
      media: creative.media.propertyFile as IMediaLibraryVideoModel,
      platform: adPlatform,
    })
  );
};

const prepareDynamicFormMedias = (ad: IFullAdvertisementModel, dispatch: Function) => {
  const medias: IMediaLibraryImageModel[] = (ad.creative as IDynamicCreativeModel).creativeMedias.map(
    (el) => el.propertyFile as IMediaLibraryImageModel
  );

  dispatch(
    setMediaOnDynamic({
      platform: ad.creative.platform as PlatformType,
      creativeMedias: medias,
    })
  );
};

export default usePrepareCreativeOrderDetailsAdvertisements;
