import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditOfficeForm from './components/CreateEditOfficeForm';

const CreateEditOfficePage = (): JSX.Element => {
  const { id } = useParams();

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          <CreateEditOfficeForm isUpdating={!!id}></CreateEditOfficeForm>
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default CreateEditOfficePage;
