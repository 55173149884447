import React from 'react';
import { Button } from '@mui/material';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { useUpdatePasswordMutation } from '@/admin/services/http/users.api';
import { useParams } from 'react-router-dom';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { RefreshOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';

export interface IPasswordFormValues {
  password: string;
  confirmPassword: string;
}

const validationSchema = yup.object().shape({
  password: yup.string().required('Password required').min(6, 'Minimum password length 6'),
  confirmPassword: yup
    .string()
    .required('Confirm password required')
    .min(6, 'Minimum password length 6')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.password === value;
    }),
});

const EditUserPasswordForm: React.FC<{ isUpdating: boolean }> = ({ isUpdating }): JSX.Element => {
  const { userId } = useParams();

  const { t } = useTranslation();

  const [updatePassword, { isLoading: updatingLoading }] = useUpdatePasswordMutation();

  const initialValues: IPasswordFormValues = {
    password: '',
    confirmPassword: '',
  };

  const handleSubmit = async (values: IPasswordFormValues) => {
    const password = values.password;
    const confirmPassword = values.confirmPassword;

    if (formik.touched && formik.isValid) {
      toast.promise(updatePassword({ id: userId!, password }), {
        success: t('passwordUpdatedSuccessfully'),
        loading: t('updating'),
        error: t('errorWhileUpdatingPassword'),
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues!,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      {isUpdating && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IPasswordFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, setFieldValue, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                id={'password'}
                type="password"
                name="password"
                label={t('password')}
                value={values.password}
                onChange={handleChange}
                required
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <CommonTextField
                id={'confirmPassword'}
                sx={{ mt: 2 }}
                type="password"
                name="confirmPassword"
                label={t('confirmPassword')}
                value={values.confirmPassword}
                onChange={handleChange}
                required
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                helperText={touched.confirmPassword && errors.confirmPassword}
              />
              <Box component="div" display={'flex'} justifyContent={'flex-end'}>
                <Button
                  sx={{ mt: 2 }}
                  type="submit"
                  endIcon={<RefreshOutlined />}
                  variant="contained"
                  disabled={updatingLoading}>
                  {t('submit')}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditUserPasswordForm;
