import { useUploadImageMutation } from '@/editor/services/http/media-library.api';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import axiosInstance from '@/shared/services/api/axios.api';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppSelector } from '@/store/hooks/redux';
import { Check, Close, CloudUpload } from '@mui/icons-material';
import { Box, Button, CircularProgress, Dialog, DialogTitle, Grid, Typography } from '@mui/material';
import moment from 'moment';

export interface SimpleDialogProps {
  open: boolean;
  imageUrl: string;
  imageIsLoading: boolean;
  onClose: () => void;
}

const ConfirmImageDialog: React.FC<SimpleDialogProps> = ({ open, imageUrl, imageIsLoading, onClose }): JSX.Element => {
  const [uploadImage, { isLoading, isError, isSuccess }] = useUploadImageMutation();

  const propertyId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)
    ? getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)
    : useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    const img = new Image();
    img.src = imageUrl;

    fetch(imageUrl)
      .then((r) => r.blob())
      .then((res) => {
        uploadImage({
          extension: 'jpg',
          width: img.width,
          height: img.height,
          propertyId: propertyId as string,
          file: res as File,
        });
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle variant="h3" fontWeight={300}>
        Confirm image
      </DialogTitle>
      <Grid
        container
        p={2}
        sx={{
          minWidth: '560px',
          minHeight: '380px',
        }}>
        {imageIsLoading ? (
          <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <img src={imageUrl} width={'100%'} />
            </Grid>
            {isLoading ? (
              <Grid item xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <CircularProgress />
              </Grid>
            ) : (
              <Grid item xs={12} mt={2} display={'flex'} justifyContent={'space-between'} alignItems={'flex-end'}>
                <Button onClick={handleClose} variant="contained" color={'error'} startIcon={<Close />}>
                  Cancel
                </Button>
                <Button onClick={handleConfirm} variant="contained" color={'secondary'} startIcon={<CloudUpload />}>
                  Confirm
                </Button>
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Dialog>
  );
};

export default ConfirmImageDialog;
