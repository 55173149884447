import React from "react";
import ErrorPage from "./ErrorPage";

class ErrorBoundary extends React.Component<{children: any}> {
  state = {hasError: false};

  constructor(props: any) {
    super(props);
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch() {
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage/>
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
