import { useGenerateShadowInstMutation } from '@/admin/services/http/generate-shadow-inst.api';
import CommonButton from '@/shared/components/CommonButton';
import CommonTextField from '@/shared/components/CommonTextField';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom'
import * as yup from 'yup';


type PossibleTypeEntity = "company" | "office";

interface IGenerateShadowInstagramProps {
  type: PossibleTypeEntity;
  closeFn: () => void;
}

export interface IGenerateShadowInstagramFormValues {
  entityType: string;
  fbPageId: string;
  entityId: string;
}

function GenerateShadowInstagram(props: IGenerateShadowInstagramProps): JSX.Element {
  const { id } = useParams();
  const [instId, setInstId] = useState<string | null>(null);

  const [genearateShadowInst, { isLoading }] = useGenerateShadowInstMutation();

  const { handleSubmit, handleChange, touched, errors, getFieldProps } = useFormik({
    initialValues: {
      entityType: props.type,
      fbPageId: "",
    },
    validationSchema: yup.object().shape({
      entityType: yup.string().oneOf(['office', 'company']).required("Type is required"),
      fbPageId: yup.string().required("Facebook page id is required")
    }),
    onSubmit: async (values: Pick<IGenerateShadowInstagramFormValues, "entityType" | "fbPageId">) => {
      const valsToSend: IGenerateShadowInstagramFormValues = { ...values, entityId: id! };

      const res = await toast.promise(genearateShadowInst(valsToSend).unwrap(), {
        loading: "Generating...",
        success: "Generated",
        error: "Something happened",
      });

      setInstId(res?.shadowInstagramId)
    }
  });

  return (
    <Dialog open={true} onClose={() => props.closeFn()}>
      <DialogTitle>
          Generate shadow intagram id
      </DialogTitle>
      <DialogContent>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <CommonTextField
            disabled
            sx={{ mt: 2 }}
            {...getFieldProps('entityType')}
            label="Entity type (company or office)"
            onChange={handleChange}
            required
            error={Boolean(touched.entityType && errors.entityType)}
            helperText={touched.entityType && (errors?.entityType as string)}
          />
          <CommonTextField
            sx={{ mt: 2 }}
            {...getFieldProps('fbPageId')}
            label="Facebook page id"
            onChange={handleChange}
            required
            error={Boolean(touched.fbPageId && errors.fbPageId)}
            helperText={touched.fbPageId && (errors?.fbPageId as string)}
          />
          <CommonButton sx={{ mt: 1 }} type="submit" disabled={isLoading}>Submit</CommonButton>
        </form>
        {instId && <Typography variant="h5" color="secondary" sx={{mt: 2}}>Instagram id - {instId}</Typography>}
      </DialogContent>
    </Dialog>
  )
}

export default GenerateShadowInstagram
