import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useGetPlatformConfigsByOfficesIdQuery } from '../../services/http/offices-platform-configs.api';
import Loader from '../../../shared/components/Loader';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import { Grid } from '@mui/material';
import OfficeMetaConfigForm from './components/MetaConfigForm';
import OfficeBidtheatreConfigForm from './components/BidtheatreConfigForm';
import OfficeBoardsConfigForm from './components/BoardsConfigForm';

const OfficePlatformConfigsListPage: FunctionComponent = (): JSX.Element => {
  const { id } = useParams();
  const { data, isLoading } = useGetPlatformConfigsByOfficesIdQuery({
    id: id!,
  });

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <AdminBaseFormWrapper>
            <Grid container>
              <Grid item xs={12}>
                <OfficeMetaConfigForm initialValues={data?.metaConfig} officeId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <OfficeBidtheatreConfigForm initialValues={data?.bidtheatreConfig} officeId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
          <AdminBaseFormWrapper customMainWrapperStyles={{ mt: 3 }}>
            <Grid container>
              <Grid item xs={12}>
                <OfficeBoardsConfigForm initialValues={data?.doohConfig} officeId={id!} />
              </Grid>
            </Grid>
          </AdminBaseFormWrapper>
        </div>
      )}
    </div>
  );
};

export default OfficePlatformConfigsListPage;
