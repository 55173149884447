import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetIntegrationsDataQuery } from '../services/http/integration.http.service';
import Loader from '@/shared/components/Loader';
import { useConnectMutation } from '@/connections/services/http/connect-user.http.service';
import { clearLS, getCredentialsFromLS, setCredentialsToLS, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { setCredentials } from '@/auth/store/reducers/auth.slice';
import { setPropertyExtId } from '@/editor/store/reducers/property-info.slice';
import { useAppDispatch } from '@/store/hooks/redux';
import { useTranslation } from 'react-i18next';

const Integrations: React.FC = (): JSX.Element => {
  const location = useLocation();
  const { provider } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [connectMutate, { isSuccess: connectIsSuccess, isError: connectIsError, isLoading: connectIsLoading }] =
    useConnectMutation();
  const { data, error, isLoading } = useGetIntegrationsDataQuery(
    {
      provider: provider as string,
      data: location.search,
    },
    { skip: Boolean(getCredentialsFromLS()) }
  );

  useEffect(() => {
    clearLS();

    if (data) {
      connectMutate(data)
        .unwrap()
        .then((res) => {
          setKeyValuePairToLS('propertyId', data.propertyId!);
          setCredentialsToLS(res);
          dispatch(setCredentials(res));
          dispatch(setPropertyExtId(data.propertyId!));
          navigate('/editor/advertise/add');
        });
    }
  }, [data]);

  return (
    <div>
      {isLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <Loader absolute={0} />
          <Typography pt={5} variant={'h4'}>
            {t('integrationMessage')}
          </Typography>
        </Box>
      )}
      {error && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography pt={5} variant={'h4'}>
            {t('integrationError')}
          </Typography>
        </Box>
      )}
      {connectIsError && (
        <Box sx={{ textAlign: 'center' }}>
          <Typography pt={5} variant={'h4'}>
            {t('integrationError')}
          </Typography>
        </Box>
      )}
      {connectIsLoading && (
        <Box sx={{ textAlign: 'center' }}>
          <Loader absolute={0} />
          <Typography pt={5} variant={'h4'}>
            {t('connectionMessage')}
          </Typography>
        </Box>
      )}
    </div>
  );
};

export default Integrations;
