import React, { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { IAdSetModel } from '@/shared/models/adsets/adset.model';
import { ICampaignModel } from '@/shared/models/campaign.model';
import { IBaseCreativeModel } from '@/shared/models/creatives/base-creative.model';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { useCreateAdvertisementsBatchMutation } from '@/editor/services/http/advertisements.api';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { setFinishingCreationOfAd } from '@/editor/store/reducers/modals-drawers.slice';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';
import Summary from './components/Summary';
import { useTranslation } from 'react-i18next';
import { PlatformType } from '@/editor/shared/constants/available-platforms';

export interface IAdvertisementCompleteValues {
  platform: string;
  campaignId: string;
  adSetId: string;
  creativeId: string;
  orderId: string;
}

const AdvertisementStep: React.FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  const [createAdsBatch, { isLoading: createAdsLoading }] = useCreateAdvertisementsBatchMutation();
  const platformsFromStoreIds = useAppSelector((state) => state.editorActivePlatformsReducer.activePlatforms);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const activePlatforms = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);
  const platformList: PlatformType[] = platformsFromStoreIds.length === 0 ? activePlatforms : platformsFromStoreIds;

  useEffect(() => {
    window.onbeforeunload = function () {
      return 'Are you sure? You will lose all the data for preview but would be possible to continue ad creation';
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [dispatch]);

  const handleAdvertisementFormSubmitted = async () => {
    const createdCampaigns = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.CREATED_CAMPAIGNS)!);
    const createdAdSets = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.CREATED_AD_SETS)!);
    const createdCreatives = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.CREATED_CREATIVES)!);
    const createdOrderId = getValueFromLs(EDITOR_LS_CONSTANTS.ORDER_INTERNAL_ID)!;

    const mappedValues: IAdvertisementCompleteValues[] = platformList.map((el) => ({
      platform: el,
      campaignId: createdCampaigns.find((campaign: Partial<ICampaignModel>) => campaign.platform === el).id,
      adSetId: createdAdSets.find((adSet: Partial<IAdSetModel>) => adSet.platform === el).id,
      creativeId: createdCreatives.find((creative: Partial<IBaseCreativeModel>) => creative.platform === el).id,
      orderId: createdOrderId,
    }));

    try {
      await toast.promise(createAdsBatch(mappedValues).unwrap(), {
        loading: t('processing'),
        success: <b>{t('successfullyCreated')}</b>,
        error: <b>{t('somethingWrong')}</b>,
      });
      dispatch(setFinishingCreationOfAd(true));
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      <Summary
        platformList={platformList}
        createAdsLoading={createAdsLoading}
        handleBackButtonClicked={handleBackBtnClicked}
        onSubmit={handleAdvertisementFormSubmitted}
      />
    </div>
  );
};

export default AdvertisementStep;
