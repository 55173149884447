import { FunctionComponent } from 'react';
import { ICompanyFinnConfigModel } from '../../../../shared/models/companies/platform-configs/company-finn-config.model';
import * as yup from 'yup';
import {
  useCreateFinnConfigMutation,
  useUpdateFinnConfigMutation,
} from '../../../services/http/companies-platform-configs.api';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { Box, Grid, Typography } from '@mui/material';
import { NewspaperOutlined } from '@mui/icons-material';
import CommonTextField from '../../../../shared/components/CommonTextField';
import CommonButton from '../../../../shared/components/CommonButton';
import DomainDisabledIcon from '@mui/icons-material/DomainDisabled';

export interface IFinnConfigFormProps {
  initialValues?: ICompanyFinnConfigModel | null;
  companyId: string;
}

export interface IFinnConfigFormValues {
  finnApiKey: string;
}

const FinnConfigForm: FunctionComponent<IFinnConfigFormProps> = (props: IFinnConfigFormProps): JSX.Element => {
  const { initialValues, companyId } = props;
  const [createFinnConfig, { isLoading: finnCreationLoading }] = useCreateFinnConfigMutation();
  const [updateFinnConfig, { isLoading: finnUpdateLoading }] = useUpdateFinnConfigMutation();

  const { getFieldProps, touched, errors, handleSubmit } = useFormik({
    initialValues: {
      finnApiKey: initialValues?.finnApiKey || '',
    },
    validationSchema: yup.object().shape({
      finnApiKey: yup.string().required('Finn api key is required'),
    }),
    onSubmit: async (values: IFinnConfigFormValues) => {
      !initialValues?.id
        ? await createFinnConfig({ values, companyId }).unwrap()
        : await updateFinnConfig({ values, id: initialValues.id }).unwrap();

      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });

  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <DomainDisabledIcon fontSize="large" color="secondary" />
          <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
            Finn
          </Typography>
        </Box>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <CommonTextField
            {...getFieldProps('finnApiKey')}
            label="Finn Api Key"
            type="text"
            required
            error={Boolean(touched.finnApiKey && errors.finnApiKey)}
            helperText={touched.finnApiKey && (errors?.finnApiKey as string)}
          />
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2,
              }}>
              <CommonButton type="submit" disabled={finnCreationLoading || finnUpdateLoading}>
                {initialValues ? 'Update' : 'Create'}
              </CommonButton>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default FinnConfigForm;
