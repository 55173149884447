import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAdSettingsFormValues } from '@/editor/shared/components/forms/ad-settings/AdSettingsForm';

export interface IAdSetStepSlice {
  adSet: {
    title: string;
    startTime: string;
    endTime: string;
    budgetId: string | null;
    productId?: string | null;
    budgetValue?: number;
  };
  places: ICircleStoreModel[];
  valuesUpdated: boolean;
  valuesFetchedFromLocalStorage: boolean;
}

export interface ICircleStoreModel {
  address: string;
  radius: number;
  center: google.maps.LatLngLiteral;
}

const initialState: IAdSetStepSlice = {
  adSet: {
    title: '',
    startTime: '',
    endTime: '',
    budgetId: '',
    productId:'',
    budgetValue:0,
  },
  places: [],
  valuesUpdated: false,
  valuesFetchedFromLocalStorage: false,
};

const adSetStepSlice = createSlice({
  name: 'adSet-step',
  initialState: initialState,
  reducers: {
    setAdSetStepValues(
      state: IAdSetStepSlice,
      action: PayloadAction<{
        adSet: Pick<IAdSettingsFormValues, 'title' | 'startTime' | 'endTime' | 'budgetId' | 'productId' | 'budgetValue'>;
        circles: ICircleStoreModel[];
        valuesUpdated: boolean;
        valuesFetchedFromLocalStorage: boolean;
      }>
    ) {
      state.adSet = action.payload.adSet;
      state.places = action.payload.circles;
      state.valuesUpdated = action.payload.valuesUpdated;
      state.valuesFetchedFromLocalStorage = action.payload.valuesFetchedFromLocalStorage;
    },
    addPlace(state: IAdSetStepSlice, action: PayloadAction<ICircleStoreModel>) {
      state.places.push(action.payload);
    },
    removePlace(state: IAdSetStepSlice, action: PayloadAction<number>) {
      state.places.splice(action.payload, 1);
    },
    setPlaceArray(state: IAdSetStepSlice, action: PayloadAction<ICircleStoreModel[]>) {
      state.places = action.payload;
    },
    setAdsetValuesUpdated(state: IAdSetStepSlice, action: PayloadAction<boolean>) {
      state.valuesUpdated = action.payload;
    },
    setValuesFetchedFromStoreFlag(state: IAdSetStepSlice, action: PayloadAction<boolean>) {
      state.valuesFetchedFromLocalStorage = action.payload;
    },
    removeAllPlaces(state: IAdSetStepSlice) {
      state.places = [];
    },
  },
});

export const {
  setAdSetStepValues,
  addPlace,
  removePlace,
  setPlaceArray,
  setAdsetValuesUpdated,
  setValuesFetchedFromStoreFlag,
  removeAllPlaces
} = adSetStepSlice.actions;

export default adSetStepSlice.reducer;
