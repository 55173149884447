import { Grid, Paper } from '@mui/material';
import React from 'react';
import { setKeyValuePairToLS } from '../../../../../shared/services/localstorage';
import { useRegisterPropertyMutation } from '../../../../services/http/properties.api';
import { EDITOR_LS_CONSTANTS } from '../../../../shared/constants/editor-LS.constants';
import RegisterEditPropertyForm, { IRegisterCreateEditFormValues } from './RegisterEditPropertyForm';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';

const RegisterPropertyStep: React.FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  const [registerProperty, { isLoading: registeringPropertyInLoad }] = useRegisterPropertyMutation();

  const handleSubmitForm = async (values: IRegisterCreateEditFormValues) => {
    const res = await registerProperty(values).unwrap();
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID, res.id);
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.ORDER_TITLE, res.address);
    handleChangeActiveStep(1);
  };

  return (
    <div>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
        <Paper sx={{ p: 4 }}>
          <RegisterEditPropertyForm loading={registeringPropertyInLoad} handleSubmit={handleSubmitForm} />
        </Paper>
      </Grid>
    </div>
  );
};

export default RegisterPropertyStep;
