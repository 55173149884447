import { useCallback } from 'react';
import availablePlatforms from '../../../../../shared/constants/available-platforms';

export interface ICardHeaderPlatformIconProps {
  platformId: string;
}

export const CardHeaderPlatformIcon: React.FC<ICardHeaderPlatformIconProps> = ({ platformId }): JSX.Element => {
  const resolvePlatformIcon = useCallback(() => {
    const Icon = availablePlatforms.find((el) => el.id === platformId)?.icon;

    return <Icon fontSize={'large'}></Icon>;
  }, [platformId]);

  return <div>{resolvePlatformIcon()}</div>;
};
