import { IProductItemCreateEditFormValues } from '@/admin/pages/product-items/components/CreateEditProductItemForm';
import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IProductBudgetItemModel } from '@/shared/models/products/product-item.model';

const baseUrl = 'admin/product-items';

interface IProductProductItemsPaginatedQueryParams {
  productId: string;
  queryParams: IPaginatedQueryParams;
}

interface IAddProductItemAction {
  productId: string;
  values: IProductItemCreateEditFormValues;
}

export const productsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductItemsByProductId: build.query<
      IBasePaginatedModel<IProductBudgetItemModel>,
      IProductProductItemsPaginatedQueryParams
    >({
      query: ({ productId, queryParams }) => ({
        url:
          queryParams.search?.length === 0
            ? `admin/products/${productId}/product-items?page=${queryParams.page}&pageSize=${queryParams.pageSize}`
            : `admin/products/${productId}/product-items?page=${queryParams.page}&pageSize=${queryParams.pageSize}&search=${queryParams.search}`,
      }),
      providesTags: (result) =>
        providesList(result?.items ?? [], 'AdminProductItems', TypeIdsDefinitions.partialListTypeId),
    }),
    addProductItem: build.mutation<IProductBudgetItemModel, IAddProductItemAction>({
      query: ({ productId, values }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: {
          type: values.type,
          productId: productId,
          spreadProductPercentageDefault: values.spreadProductPercentageDefault,
        },
      }),
      invalidatesTags: ['AdminProductItems'],
    }),
    getProductItemById: build.query<IProductBudgetItemModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'AdminProductItems', id: arg.id }],
    }),
    editProductItem: build.mutation<IProductBudgetItemModel, { values: IProductItemCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['AdminProductItems'],
    }),
    deleteProductItem: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdminProductItems'],
    }),
  }),
});

export const {
  useAddProductItemMutation,
  useDeleteProductItemMutation,
  useEditProductItemMutation,
  useGetProductItemByIdQuery,
  useGetProductItemsByProductIdQuery,
} = productsApi;