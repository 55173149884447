import { useAppSelector } from './../../../../../store/hooks/redux';
import { useEffect, useState } from 'react';
import axiosInstance from '../../../../../shared/services/api/axios.api';

const useImageCropper = () => {
  const [cropperLoading, setCropperLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState<string | null>(null);
  // possibility to make cropper dynamic based on platform and requirements
  // for now mocked and dumy
  const [aspectRatio, setAspectRatio] = useState('9 / 16');
  const [croppingInProgress, setCroppingInProgress] = useState<boolean>(false);

  const modalState = useAppSelector((state) => state.editorModalsDrawersReducer.imageCropper);

  useEffect(() => {
    async function loadImage() {
      if (modalState.image?.preSignedUrl) {
        setCropperLoading(true);
        const res = (
          await axiosInstance.get(`medias/${modalState.image?.id}/proxy`, {
            responseType: 'blob',
          })
        ).data;
        const fileReader = new FileReader();
        fileReader.onload = () => {
          setImageBlob(fileReader.result as string);
          setCropperLoading(false);
        };

        fileReader.readAsDataURL(res);
      }
    }

    loadImage();

    return () => {
      setImageBlob(null);
      setCropperLoading(false);
    };
  }, [modalState.image?.id, modalState.image?.preSignedUrl]);

  return {
    cropperLoading,
    setCropperLoading,
    imageBlob,
    setImageBlob,
    modalState,
    croppingInProgress,
    setCroppingInProgress,
  };
};

export default useImageCropper;
