import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Form, Formik } from 'formik';
import { SaveAs } from '@mui/icons-material';
import CommonButton from '@/shared/components/CommonButton';

interface ILanguageTablePresentationalProps {
  selectedLanguageMessages: { [key: string]: string };
  selectedTranslatedMessages: { [key: string]: string };
  initialFormValues: any;
  isLoading: boolean;
  isFetching: boolean;
  isSubmitting: boolean;
  validationSchema: any;
  onSubmit: (values: any, actions: any) => void;
  handleTranslations: (word: string) => string;
}

const LanguageTablePresentational: React.FC<ILanguageTablePresentationalProps> = ({
  selectedLanguageMessages,
  selectedTranslatedMessages,
  initialFormValues,
  onSubmit,
  validationSchema,
  isSubmitting,
  isLoading,
  isFetching,
  handleTranslations,
}): JSX.Element => {
  return (
    <Box>
      {isLoading && isFetching ? (
        <Typography>{handleTranslations('loadingLanguageCopies')}</Typography>
      ) : (
        <Formik
          initialValues={initialFormValues}
          validateOnChange={false}
          enableReinitialize={true}
          onSubmit={onSubmit}
          validationSchema={validationSchema}>
          {({ handleChange, handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <TableContainer sx={{ maxHeight: 800 }} component={Paper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ backgroundColor: '#000', color: '#fff' }}>
                        {handleTranslations('name')}
                      </TableCell>
                      <TableCell sx={{ backgroundColor: '#000', color: '#fff' }}>
                        {handleTranslations('translations')}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedTranslatedMessages &&
                      Object.keys(selectedTranslatedMessages).map((key, value) => (
                        <TableRow key={key}>
                          <TableCell component="th" scope="row">
                            {selectedLanguageMessages?.[key]}
                          </TableCell>
                          <TableCell>
                            {selectedTranslatedMessages?.[key] !== undefined ? (
                              <TextField
                                name={key}
                                id={key}
                                label=""
                                fullWidth
                                onChange={handleChange}
                                value={values[key] ?? ''}
                                variant="outlined"
                              />
                            ) : (
                              <div>{handleTranslations('loading')}</div>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  position: 'sticky',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  backgroundColor: '#fff',
                  padding: '10px',
                  boxShadow: '0px -4px 8px rgba(0, 0, 0, 0.1)',
                  alignContent: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}>
                <CommonButton startIcon={<SaveAs />} sx={{}} type="submit">
                  {handleTranslations('saveChanges')}
                </CommonButton>
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export default LanguageTablePresentational;
