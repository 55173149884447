import React from 'react';
import { IDefaultTemplateProps } from '../DefaultTemplate';
import { Grid, Paper, Typography } from '@mui/material';
import { useSelectHeaderImage } from '@/statistics/hooks/select-header-image';
import AgentDetailsCard from './AgentDetailsCard';

const StatisticsHeader: React.FC<IDefaultTemplateProps> = ({ order }) => {
  const headerImg = useSelectHeaderImage(order);
  return (
    <Grid item container sx={{ minHeight: '500px', boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 1) ' }}>
      <Grid
        item
        xs={12}
        display={'flex'}
        sx={{
          backgroundImage: `url(${headerImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}>
        <Grid container item direction={{ md: 'row-reverse' }} display={'flex'}>
          <Grid
            alignSelf={'flex-start'}
            item
            xs={12}
            md={3}
            sx={{
              height: { md: '500px' },
              backgroundColor: 'rgba(0,0,0,0.9)',
            }}>
            <AgentDetailsCard {...order.userDetails}></AgentDetailsCard>
          </Grid>
          <Grid
            item
            alignSelf={'flex-end'}
            alignContent={'center'}
            justifyContent={'center'}
            xs={12}
            md={9}
            sx={{
              maxHeight: '150px',
              backgroundColor: 'rgba(0,0,0,0.7)',
              minHeight: '50px',
            }}>
            <Typography textAlign={'center'} variant="h2" color={'#fff'}>
              {order.propertyAddress}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StatisticsHeader;
