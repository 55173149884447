import moment from "moment";
import * as yup from "yup";

export const buildYupTimeDateProperty = (
  propertyName: string,
  min: boolean,
  max: boolean,
  daysToAdd?: number,
  daysToSubtract?: number
) => {
  const schemaProperty = yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }

      return moment(originalValue).format("DD-MM-YYYY");
    })
    .typeError("Please enter a valid date!")
    .required(`${propertyName} is required!`);

  if (min && daysToSubtract) {
    schemaProperty.min(
      moment().subtract(daysToSubtract, "days"),
      `${propertyName} must be in the future!`
    );
  }

  if (max && daysToAdd) {
    schemaProperty.max(
      moment().add(daysToAdd, "days"),
      `${propertyName} must be in the past!`
    );
  }

  return schemaProperty;
};
