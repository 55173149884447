import { StylesType } from '@/shared/styles/styles';

type UserCreateEditStyles =
  | 'elementsMarginTop'
  | 'avatarBoxWrapper'
  | 'avatarBoxSubWrapper'
  | 'imagePlace'
  | 'avatarBoxIcon'
  | 'avatarWindow';

export const UserCreateEditStyles: StylesType<UserCreateEditStyles> = {
  elementsMarginTop: {
    marginTop: '15px',
  },
  avatarBoxWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  avatarBoxIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '97%',
    width: '100%',
    opacity: 0.7,
    borderRadius: 0,
    ':hover': {
      opacity: 1,
      color: '#fff',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  avatarBoxSubWrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: '400px',
    cursor: 'pointer',
  },
  imagePlace: {
    width: '100%',
    height: '100%',
    maxWidth: '240px',
    maxHeight: '280px',
    objectFit: 'cover',
  },
  avatarWindow: {
    height: '100%',
    width: '100%',
  },
};
