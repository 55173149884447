import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { debounce } from '@/shared/hooks/debounce';
import { useDeleteCompanyMutation, useGetCompaniesAdminQuery } from '../../services/http/companies.api';
import { ICompanyModel } from '@/shared/models/companies/company.model';
import { ROUTE_COMPANIES_DECLARATIONS } from '../../routes/companies-routes';
import { COLORS } from '@/shared/styles/colors';
import { useTranslation } from 'react-i18next';
import { AutoFixHigh, Delete, DisplaySettings, Edit, PriceChange } from '@mui/icons-material';
import AddCircle from '@mui/icons-material/AddCircle';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'title',
    name: 'Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const actions = [
  {
    action: 'edit',
    btnTitle: 'Edit',
    icon: <Edit />,
  },
  {
    action: 'budgets',
    icon: <PriceChange />,
    btnTitle: 'Budgets',
  },

  {
    action: 'platform-configs',
    icon: <DisplaySettings />,
    btnTitle: 'Platforms',
  },
  {
    action: 'auto-creation',
    icon: <AutoFixHigh />,
    btnTitle: 'Autocreation',
  },
];

const CompaniesDataTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading, isFetching } = useGetCompaniesAdminQuery({
    page,
    pageSize,
    search,
  });

  const [deleteCompany] = useDeleteCompanyMutation();

  const { t } = useTranslation();

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  actions.forEach((act) => {
    if (act.btnTitle) {
      act.btnTitle = t(act.btnTitle);
    }
  });

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircle />,
        action: () =>
          navigate(`${ROUTES_DECLARATIONS.AdminPanel}/${ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesNewPage}`),
      },
    ];
  }, []);

  const mapCompanies = useCallback(
    (data?: ICompanyModel[]) => {
      const basePath = `${ROUTES_DECLARATIONS.AdminPanel}/companies`;
      return data
        ? data.map((el) => ({
            id: el.id,
            title: el.title,
            createdAt: el.createdAt,
            actions: [
              ...actions.map((action) => ({
                title: action.btnTitle,
                icon: action.icon,
                action: () => navigate(`${basePath}/${el.id}/${action.action}`),
              })),
              {
                title: t('delete'),
                icon: <Delete />,
                action: () => deleteCompany({ id: el.id }),
                color: COLORS.delete,
              },
            ],
          }))
        : [];
    },
    [data?.items.length]
  );

  return (
    <div>
      <AdminTableWrapper
        header={t('companies')}
        isLoading={isLoading || isFetching}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapCompanies(data?.items as ICompanyModel[]),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default CompaniesDataTable;
