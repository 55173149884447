import { getCredentialsFromLS, getValueFromLs } from '@/shared/services/localstorage';
import jwt from 'jwt-decode';
import { IAuthResponse } from '../models/auth.response';
import { impersonationBackToken } from './../../shared/services/localstorage/index';

export const RolesEnum = Object.freeze({
  ADMIN: 'Admin',
  USER: 'User',
});

export const SymfonyRolesEnum = Object.freeze({
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  OFFICE_ADMIN: 'ROLE_OFFICE_ADMIN',
  COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
});

interface ISymfonyDecodedToken {
  roles: string[];
}

export const checkTokenPresented = (accessToken: string | undefined | null): boolean => {
  return !!accessToken;
};

export const setupAccessTokenFromLS = (): IAuthResponse | null => {
  const credentials = getCredentialsFromLS();
  if (!credentials) return null;
  const { accessToken, email } = credentials;

  return { accessToken, email };
};

export const checkValidityAndRoleFromAccessToken = (accessToken: string, role: string): boolean => {
  const decodedJwt: object = jwt(accessToken);
  for (const [_, entry] of Object.entries(decodedJwt)) {
    if (entry === role) {
      return true;
    }
  }

  return false;
};

export const checkValidityAndRoleFromSymfonyAccessToken = (accessToken: string, role: string): boolean => {
  const decodedJwt: ISymfonyDecodedToken = jwt(accessToken);
  return decodedJwt?.['roles'].includes(role);
};

export const checkRoleFromAccessTokenWithCb = (accessToken: string, role: string, callBack: () => void): boolean => {
  const isNeededRole = checkValidityAndRoleFromSymfonyAccessToken(accessToken, role);

  if (!isNeededRole && !getValueFromLs(impersonationBackToken)) {
    callBack();
    return false;
  }

  return true;
};
