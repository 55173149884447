import React from 'react';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import CommonButton from '../../../../shared/components/CommonButton';
import { capitalize } from 'lodash';
import CommonTextField from '@/shared/components/CommonTextField';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Sync } from '@mui/icons-material';
import { useAddProductItemMutation, useEditProductItemMutation, useGetProductItemByIdQuery } from '@/admin/services/http/products-items.api';

export interface ICreateEditProductItemProps {
  isUpdating: boolean;
}

export interface IProductItemCreateEditFormValues {
  type?: string;
  spreadProductPercentageDefault: number;
}

const validationSchema = yup.object().shape({
  type: yup.string().required('Type is required'),
});

const types = ['meta', 'delta', 'snapchat', 'bidtheatre', 'boards'];

const CreateEditProductItemForm: React.FC<ICreateEditProductItemProps> = ({ isUpdating }): JSX.Element => {
  const { productId, productItemId } = useParams();
  const [editProductItem, { isLoading: editProductItemLoading }] = useEditProductItemMutation();
  const [addProductItem, { isLoading: addProductItemLoading }] = useAddProductItemMutation();
  const { t } = useTranslation();
  const {
    data: productItemData,
    isLoading: productItemDataLoading,
    isFetching: productItemDataFetching,
  } = useGetProductItemByIdQuery({ id: productItemId ?? '' }, { skip: !isUpdating });

  const navigate = useNavigate();
  const initialValues: IProductItemCreateEditFormValues = {
    type: productItemData?.type || '',
    spreadProductPercentageDefault: productItemData?.spreadProductPercentageDefault || 0,
  };

  const handleSubmit = async (values: IProductItemCreateEditFormValues) => {
    const promise = new Promise(async (resolve, reject) => {
      try {
        productItemData
          ? await editProductItem({ id: productItemId!, values }).unwrap()
          : await addProductItem({ productId: productId!, values }).unwrap();

        resolve('');
      } catch (err) {
        reject(err);
      }
    });

    toast.promise(promise, {
      success: () => {
        navigate(-1);
        return t('successfulProcessing');
      },
      error: (err) => (err?.data ? <span>{err.data}</span> : 'Error while updating'),
      loading: t('processing'),
    });
  };

  return (
    <div>
      {!productItemDataLoading && !productItemDataFetching ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IProductItemCreateEditFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <FormControl fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="type_input_label_id">{t('productType')}</InputLabel>
                <Select
                  name="type"
                  labelId="type_input_label_id"
                  id="type_input_id"
                  value={values.type}
                  onChange={handleChange}
                  input={<OutlinedInput label="Type"></OutlinedInput>}
                  renderValue={(value: string) => <Box component="div">{capitalize(value)}</Box>}>
                  {types?.map((el) => (
                    <MenuItem key={el} value={el}>
                      {capitalize(el)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <CommonTextField
                sx={{ mt: 3 }}
                type="number"
                name="spreadProductPercentageDefault"
                label={t('defaultProductSpreading')}
                value={values.spreadProductPercentageDefault}
                onChange={handleChange}
                required
                error={Boolean(touched.spreadProductPercentageDefault && errors.spreadProductPercentageDefault)}
                helperText={touched.spreadProductPercentageDefault && errors.spreadProductPercentageDefault}
              />
              <CommonButton
                sx={{ mt: 2 }}
                type="submit"
                variant="contained"
                startIcon={<Sync />}
                disabled={addProductItemLoading || editProductItemLoading}>
                {productItemId ? <Typography>{t('update')}</Typography> : <Typography>{t('submit')}</Typography>}
              </CommonButton>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEditProductItemForm;
