import React, { FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import CommonTextField from '@/shared/components/CommonTextField';
import * as yup from 'yup';
import CommonButton from '@/shared/components/CommonButton';
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import { IOfficeBidtheatreConfigModel } from '@/shared/models/offices/office-bidtheatre-config.model';
import {
  useCreateOfficeBidtheatreConfigMutation,
  useUpdateOfficeBidtheatreConfigMutation,
} from '../../../services/http/offices-platform-configs.api';
import GenerateShadowInstagram from '@/admin/shared/components/modals/GenerateShadowInstagram';
import bidTheatre from '/images/bidtheatre/bidtheatre.png';
import {
  useGetAllMedialistQuery,
  useGetAllAudincesQuery,
  useGetAllFiltersQuery,
} from '@/admin/services/http/bidTheatre.api';
import Loader from '@/shared/components/Loader';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export interface IOfficeBidtheatreConfigFormProps {
  initialValues?: IOfficeBidtheatreConfigModel | null;
  officeId: string;
}
interface IBidtheatreUiTemplateFormValues {
  mediaList: { mediaListId: string | number; mediaListName: string }[];
  maxCPM: string | number;
  targetShare: string | number;
  audience: { audienceId: string | number; audienceName: string }[];
  filter: { filterId: string | number; filterName: string }[];
}
export interface IOfficeBidtheatreConfigFormValues {
  rangeSelection: string | number;
  bidtheatreOfficeBidStrategyConfigs: IBidtheatreUiTemplateFormValues[];
}
interface MediaListItem {
  mediaListId: string | number;
  mediaListName: string | number;
}
const OfficeBidtheatreConfigForm: FunctionComponent<IOfficeBidtheatreConfigFormProps> = (
  props: IOfficeBidtheatreConfigFormProps
): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { initialValues, officeId } = props;
  const {
    data: medialist,
    isLoading: mediaDataLoading,
    isFetching: mediaDataFetching,
  } = useGetAllMedialistQuery({ id: id! }, { skip: !id });
  const {
    data: audiencelist,
    isLoading: audienceDataLoading,
    isFetching: audienceDataFetching,
  } = useGetAllAudincesQuery({ id: id! }, { skip: !id });
  const { data: filterlist, isLoading: filterDataLoading, isFetching: filterDataFetching } = useGetAllFiltersQuery();
  const [instDialogOpened, setInstDialogOpened] = useState<boolean>(false);
  const [createBidtheatreConfig, { isLoading: bidthatreCreationLoading }] = useCreateOfficeBidtheatreConfigMutation();
  const [updateBidtheatreConfig, { isLoading: bidtheatreUpdateLoading }] = useUpdateOfficeBidtheatreConfigMutation();
  const { getFieldProps, getFieldMeta, touched, errors, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      rangeSelection: initialValues?.rangeSelection || 10,
      bidtheatreOfficeBidStrategyConfigs: initialValues?.bidtheatreOfficeBidStrategyConfigs?.map((el) => ({
        mediaList: el.mediaList.map((media: any) => media.mediaListId),
        maxCPM: el.maxCPM,
        audience: el.audience,
        targetShare: el.targetShare,
        filter: el.filter,
      })) || [{ mediaList: [], maxCPM: '', audience: [], targetShare: '', filter: [] }],
    },
    validationSchema: yup.object().shape({
      rangeSelection: yup.string().required('Range is required'),
      bidtheatreOfficeBidStrategyConfigs: yup
        .array()
        .of(
          yup.object().shape({
            mediaList: yup.array().min(1, 'Please select at least one media list'),
            maxCPM: yup.string().required('CPM is required'),
          })
        )
        .min(1)
        .required(),
    }),
    onSubmit: async (values: IOfficeBidtheatreConfigFormValues) => {
      const updatedValues = {
        ...values,
        bidtheatreOfficeBidStrategyConfigs: values.bidtheatreOfficeBidStrategyConfigs.map((config) => ({
          ...config,
          mediaList: config.mediaList.map((mediaId: any) => {
            const media = medialist?.find((media: { mediaListId: number | string }) => media.mediaListId === mediaId);
            return { mediaListId: mediaId, mediaListName: media?.mediaListName || '' };
          }),
        })),
      };
      !initialValues?.id
        ? await createBidtheatreConfig({ values: updatedValues, officeId }).unwrap()
        : await updateBidtheatreConfig({ values: updatedValues, officeId }).unwrap();
      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });
  const addValues = async () => {
    await setFieldValue('bidtheatreOfficeBidStrategyConfigs', [
      ...getFieldMeta('bidtheatreOfficeBidStrategyConfigs').value,
      {
        mediaList: [],
        maxCPM: '',
        audience: [],
        targetShare: '',
        filter: [],
      },
    ]);
  };
  const removeValues = async (index: number) => {
    await setFieldValue(
      'bidtheatreOfficeBidStrategyConfigs',
      getFieldMeta('bidtheatreOfficeBidStrategyConfigs').value.filter(
        (_: IBidtheatreUiTemplateFormValues, idx: number) => {
          return index !== idx;
        }
      )
    );
  };
  const isLoadingFinished = (): boolean =>
    !mediaDataLoading &&
    !mediaDataFetching &&
    !audienceDataLoading &&
    !audienceDataFetching &&
    !filterDataLoading &&
    !filterDataFetching;
  const handleUpdateConfig = async (index: number, updatedConfig: any) => {
    const currentConfigs = getFieldMeta('bidtheatreOfficeBidStrategyConfigs').value;
    currentConfigs[index] = updatedConfig;
    await setFieldValue('bidtheatreOfficeBidStrategyConfigs', currentConfigs);
  };
  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 3,
          }}>
          <img src={bidTheatre} width={50} />
          <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
            {t('bidtheatre')}
          </Typography>
        </Box>
        {isLoadingFinished() ? (
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <CommonTextField
              sx={{ mt: '15px' }}
              type="number"
              {...getFieldProps('rangeSelection')}
              label="Range In KM"
              required
              error={Boolean(touched.rangeSelection && errors.rangeSelection)}
              helperText={touched.rangeSelection && (errors?.rangeSelection as string)}
            />
            <Grid container spacing={3}>
              {getFieldMeta('bidtheatreOfficeBidStrategyConfigs').value.map(
                (el: IBidtheatreUiTemplateFormValues, key: number) => {
                  return (
                    <Grid item xs={12} md={12} key={key}>
                      <div>
                        <Card variant="outlined" sx={{ mt: 3, pr: 2, pl: 2, pb: 2 }}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                              }}>
                              <CardHeader
                                action={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                      justifyContent: 'flex-end',
                                    }}>
                                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }} color="secondary">
                                      BidStrategy
                                    </Typography>
                                  </Box>
                                }></CardHeader>
                              <IconButton onClick={() => removeValues(key)}>
                                <CancelIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <FormControl fullWidth sx={{ marginTop: '15px' }}>
                            <Autocomplete
                              multiple
                              id={`medialist_input_id_${key}`}
                              options={medialist?.map((option) => option.mediaListName) || []}
                              value={values.bidtheatreOfficeBidStrategyConfigs[key].mediaList.map((mediaId: any) => {
                                const selectedMedia = medialist?.find(
                                  (media: MediaListItem) => media.mediaListId === mediaId
                                );
                                return selectedMedia?.mediaListName || '';
                              })}
                              onChange={(event, newValue) => {
                                const selectedMediaIds = newValue.map((mediaName: string) => {
                                  const selectedMedia = medialist?.find(
                                    (media: MediaListItem) => media.mediaListName === mediaName
                                  );
                                  return selectedMedia?.mediaListId || '';
                                });
                                handleUpdateConfig(key, {
                                  ...values.bidtheatreOfficeBidStrategyConfigs[key],
                                  mediaList: selectedMediaIds,
                                });
                              }}
                              renderInput={(params) => <TextField {...params} label="Media List" />}
                            />
                            <FormHelperText
                              error={
                                !!(
                                  touched?.bidtheatreOfficeBidStrategyConfigs?.[key]?.mediaList &&
                                  (errors.bidtheatreOfficeBidStrategyConfigs as any[])?.[key]?.mediaList
                                )
                              }>
                              {touched?.bidtheatreOfficeBidStrategyConfigs?.[key]?.mediaList &&
                                (errors.bidtheatreOfficeBidStrategyConfigs as any[])?.[key]?.mediaList}
                            </FormHelperText>
                          </FormControl>
                          <FormControl fullWidth sx={{ marginTop: '15px' }}>
                            <Autocomplete
                              id={`audience_input_id_${key}`}
                              options={audiencelist || []}
                              getOptionLabel={(option) => option.audienceName}
                              value={values?.bidtheatreOfficeBidStrategyConfigs[key]?.audience[0] || null}
                              onChange={(event, newValue) => {
                                const updatedConfig = {
                                  ...values.bidtheatreOfficeBidStrategyConfigs[key],
                                  audience: newValue
                                    ? [{ audienceId: newValue.audienceId, audienceName: newValue.audienceName }]
                                    : [],
                                };
                                handleUpdateConfig(key, updatedConfig);
                              }}
                              renderInput={(params) => <TextField {...params} label="Audience" variant="outlined" />}
                            />
                          </FormControl>
                          <FormControl fullWidth sx={{ marginTop: '15px' }}>
                            <Autocomplete
                              id={`filter_input_id_${key}`}
                              options={filterlist || []}
                              getOptionLabel={(option) => option.filterName}
                              value={values?.bidtheatreOfficeBidStrategyConfigs[key]?.filter[0] || null}
                              onChange={(event, newValue) => {
                                const updatedConfig = {
                                  ...values.bidtheatreOfficeBidStrategyConfigs[key],
                                  filter: newValue
                                    ? [{ filterId: newValue.filterId, filterName: newValue.filterName }]
                                    : [],
                                };
                                handleUpdateConfig(key, updatedConfig);
                              }}
                              renderInput={(params) => <TextField {...params} label="Filter" variant="outlined" />}
                            />
                          </FormControl>
                          <CommonTextField
                            sx={{ mt: '15px' }}
                            type="number"
                            {...getFieldProps(`bidtheatreOfficeBidStrategyConfigs[${key}].maxCPM`)}
                            label="Max CPM"
                            required
                            error={Boolean(
                              touched?.bidtheatreOfficeBidStrategyConfigs?.[key]?.maxCPM &&
                                (errors?.bidtheatreOfficeBidStrategyConfigs as any[])?.[key]?.maxCPM
                            )}
                            helperText={
                              touched?.bidtheatreOfficeBidStrategyConfigs?.[key]?.maxCPM &&
                              (errors?.bidtheatreOfficeBidStrategyConfigs as any[])?.[key]?.maxCPM
                            }
                          />
                          <CommonTextField
                            sx={{ mt: '15px' }}
                            type="number"
                            {...getFieldProps(`bidtheatreOfficeBidStrategyConfigs[${key}].targetShare`)}
                            label="targetShare"
                            required
                          />
                        </Card>
                      </div>
                    </Grid>
                  );
                }
              )}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 4,
                }}>
                <CommonButton onClick={() => addValues()} type="button">
                  Add BidStrategy
                </CommonButton>
                <CommonButton type="submit" disabled={bidthatreCreationLoading || bidtheatreUpdateLoading}>
                  {initialValues ? t('update') : t('create')}
                </CommonButton>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Loader />
        )}
        {instDialogOpened && <GenerateShadowInstagram type="office" closeFn={() => setInstDialogOpened(false)} />}
      </Box>
    </div>
  );
};
export default OfficeBidtheatreConfigForm;
