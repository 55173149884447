import CommonButton from '@/shared/components/CommonButton';
import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import moment from 'moment';
import { FC } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useActivateOrStopOrderMutation } from '@/editor/services/http/orders.api';
import { toast } from 'react-hot-toast';

interface IOrderStatusChangeModal {
  open: boolean;
  order: ISingleOrderWithDetailsModel;
  closeFn: () => void;
}

const OrderStatusChangeModal: FC<IOrderStatusChangeModal> = (props: IOrderStatusChangeModal): JSX.Element => {
  const { open, order, closeFn } = props;

  const [activateStopAction, { isLoading }] = useActivateOrStopOrderMutation();

  const pauseOrder = () => {
    toast.promise(activateStopAction({ id: order.id, action: 'pause' }), {
      loading: 'Processing...',
      error: (err) => (err?.data ? <span>{err.data}</span> : 'Error while pausing'),
      success: 'Successfully paused',
    });

    closeFn();
  };

  const activateOrder = () => {
    toast.promise(activateStopAction({ id: order.id, action: 'activate' }), {
      loading: 'Processing...',
      error: (err) => (err?.data ? <span>{err.data}</span> : 'Error while activating'),
      success: 'Successfully activated',
    });
    closeFn();
  };

  return (
    <Dialog open={open} onClose={closeFn}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={closeFn}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ mt: 2 }}>
        {order.status === 'active' && (
          <Typography variant="h5" color="secondary">
            All advertisements of order will be stopped
          </Typography>
        )}
        {order.status === 'paused' && (
          <Typography variant="h5" color="secondary">
            All possible advertisements where end time less then now will be activated
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <CommonButton disabled={isLoading} onClick={order.status === 'active' ? pauseOrder : activateOrder}>
          {order.status === 'active' ? 'Pause' : 'Activate'}
        </CommonButton>
      </DialogActions>
    </Dialog>
  );
};

export default OrderStatusChangeModal;
