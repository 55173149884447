import { IMetaStatsModel } from '@/shared/models/advertisements/statistics/meta-stats.model';
import { NumberValuesFromObject } from '@/shared/types/types';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';

interface IMetaSummaryStatsProps {
  metaStats: IMetaStatsModel;
}

const MetaSummaryStats: FC<IMetaSummaryStatsProps> = (props: IMetaSummaryStatsProps): JSX.Element => {
  const { metaStats } = props;

  const values: NumberValuesFromObject<IMetaStatsModel, 'totalClicks' | 'totalImpressions'> = {
    totalClicks: metaStats.totalClicks,
    totalImpressions: metaStats.totalImpressions,
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="meta stats table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Clicks</TableCell>
            <TableCell align="left">Impressions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {Object.values(values).map((row, index) => (
              <TableCell key={index} align="left">
                {row}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MetaSummaryStats;
