import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import { useGetAllCompaniesQuery } from '@/admin/services/http/companies.api';
import { useAddBudgetMutation, useEditBudgetMutation, useGetBudgetByIdQuery } from '@/admin/services/http/budgets.api';
import CommonTextField from '../../../../shared/components/CommonTextField';
import CommonButton from '../../../../shared/components/CommonButton';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Sync } from '@mui/icons-material';

export interface ICreateEditCompaniesProps {
  isUpdating: boolean;
}

export interface IBudgetCreateEditFormValues {
  title: string;
  companyId?: string;
  value: number;
  tag?: string;
  valueToInvoice: number;
  indexToShow: number;
  estateTypes: string[];
  type: string;
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
  value: yup.number().required('Value is required').moreThan(0, 'Value should be more than 0'),
  companyId: yup.string().required('Company id is required'),
  type: yup.string().required('Type is required'),
});

const estateTypes = [
  'boligtomt',
  'leilighet',
  'enebolig',
  'tomannsbolig',
  'rekkehus',
  'fritidseiendom',
  'næringsbygg',
  'kontorlokale',
  'annet',
  'forretning',
  'næringstomt',
  'tomt',
];

const types = ['regular', 'automatic', 'after_sold'];

const CreateEditBudgetForm: React.FC<ICreateEditCompaniesProps> = ({ isUpdating }): JSX.Element => {
  const { budgetId } = useParams();
  const [editBudget, { isLoading: editBudgetLoading }] = useEditBudgetMutation();
  const [addBudget, { isLoading: addBudgetLoading }] = useAddBudgetMutation();
  const { data: companies } = useGetAllCompaniesQuery();
  const { t } = useTranslation();

  const {
    data: budgetData,
    isLoading: budgetDataLoading,
    isFetching: budgetDataFetching,
  } = useGetBudgetByIdQuery({ id: budgetId ?? '' }, { skip: !isUpdating });

  const navigate = useNavigate();
  const initialValues: IBudgetCreateEditFormValues = {
    title: budgetData?.title || '',
    companyId: budgetData?.companyId || '',
    type: budgetData?.type || '',
    tag: budgetData?.tag || '',
    value: budgetData?.value || 1,
    valueToInvoice: budgetData?.valueToInvoice || 1,
    indexToShow: budgetData?.indexToShow || 1,
    estateTypes: budgetData?.estateTypes || [],
  };

  const handleSubmit = async (values: IBudgetCreateEditFormValues) => {
    try {
      budgetData ? await editBudget({ id: budgetId!, values }).unwrap() : await addBudget(values);
      navigate(-1);
    } catch (e) {
      return;
    }
  };

  const handleEstateTypeChange = (e: SelectChangeEvent<string[]>, setFieldValue: Function) => {
    const { value } = e.target;
    const mappedValue = value as string[];
    setFieldValue('estateTypes', mappedValue);
  };

  const isSelected = (values: IBudgetCreateEditFormValues, el: string) => {
    return values.estateTypes.includes(el);
  };

  return (
    <div>
      {!budgetDataLoading && !budgetDataFetching ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IBudgetCreateEditFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, touched, values, setFieldValue }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                type="text"
                name="title"
                label={t('title')}
                value={values.title}
                onChange={handleChange}
                required
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <CommonTextField
                type="text"
                sx={{ mt: 2 }}
                name="tag"
                label={t('Tag')}
                value={values.tag}
                onChange={handleChange}
              />
              <CommonTextField
                sx={{ mt: 2 }}
                type="number"
                name="value"
                label={t('value')}
                value={values.value}
                onChange={handleChange}
                required
                error={Boolean(touched.value && errors.value)}
                helperText={touched.value && errors.value}
              />
              <CommonTextField
                sx={{ mt: 2 }}
                type="number"
                name="valueToInvoice"
                label={t('valueToInvoice')}
                value={values.valueToInvoice}
                onChange={handleChange}
                required
                error={Boolean(touched.valueToInvoice && errors.valueToInvoice)}
                helperText={touched.valueToInvoice && errors.valueToInvoice}
              />
              <CommonTextField
                sx={{ mt: 2 }}
                type="number"
                name="indexToShow"
                label={t('indexToShow')}
                value={values.indexToShow}
                onChange={handleChange}
                required
                error={Boolean(touched.valueToInvoice && errors.indexToShow)}
                helperText={touched.indexToShow && errors.indexToShow}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="company_input_label_id">{t('company')}</InputLabel>
                <Select
                  name="companyId"
                  labelId="company_input_label_id"
                  id="company_input_id"
                  value={values.companyId}
                  onChange={handleChange}
                  input={<OutlinedInput label="Company"></OutlinedInput>}
                  renderValue={(value: string) => (
                    <Box component="div">{companies?.find((el) => el.id === value)?.title}</Box>
                  )}>
                  {companies?.map(({ title, id }) => (
                    <MenuItem key={id} value={id}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="type_input_label_id">{t('budgetType')}</InputLabel>
                <Select
                  name="type"
                  labelId="type_input_label_id"
                  id="type_input_id"
                  value={values.type}
                  onChange={handleChange}
                  input={<OutlinedInput label="Type"></OutlinedInput>}
                  renderValue={(value: string) => <Box component="div">{capitalize(value)}</Box>}>
                  {types?.map((el) => (
                    <MenuItem key={el} value={el}>
                      {capitalize(el)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="estate_types_selector">{t('estateTypes')}</InputLabel>
                <Select
                  multiple
                  labelId="estate_types_selector"
                  id="estate_types_selector_id"
                  value={values.estateTypes}
                  onChange={(e) => handleEstateTypeChange(e, setFieldValue)}
                  input={<OutlinedInput label="Chip"></OutlinedInput>}
                  renderValue={(selected) => (
                    <Box component="div" sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        return <Chip key={value} label={capitalize(value)} />;
                      })}
                    </Box>
                  )}>
                  {estateTypes.map((el) => (
                    <MenuItem key={el} value={el} selected={isSelected(values, el)}>
                      {capitalize(el)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 2,
                  }}>
                  <CommonButton
                    type="submit"
                    startIcon={<Sync />}
                    variant="contained"
                    disabled={addBudgetLoading || editBudgetLoading}>
                    {budgetId ? t('update') : t('submit')}
                  </CommonButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEditBudgetForm;
