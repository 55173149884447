import { ISingleOrderWithDetailsModel, OrderStatusType } from '@/shared/models/orders/single-order-with-details.model';
import { Button, Chip, ListItem, ListItemText, Tooltip } from '@mui/material';
import { capitalize } from 'lodash';
import { FC, useEffect, useState } from 'react';
import OrderStatusChangeModal from './OrderStatusChangeModal';
import moment from 'moment';

type OrderStatusChipColor = { [k in OrderStatusType]: string };

interface IOrderStatusProps {
  order: ISingleOrderWithDetailsModel;
}

const colors: OrderStatusChipColor = {
  in_progress: '#9575DE',
  completed: '#FFF2CC',
  active: '#DDFFBB',
  error: '#FF6969',
  paused: '#EAE0DA',
};

interface IActiveChip {
  text: string | null;
  withAction: boolean;
}

const OrderStatus: FC<IOrderStatusProps> = (props: IOrderStatusProps): JSX.Element => {
  const { order } = props;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [activeChip, setActiveChip] = useState<IActiveChip | null>(null);

  useEffect(() => {
    if (
      order.status === 'active' ||
      (order.status === 'paused' && moment(order.advertisements[0].adSet.endTime).diff(moment()) > 0)
    ) {
      let text: string | null;

      switch (order.status) {
        case 'active':
          text = 'Status. Click to pause order';
          break;
        case 'paused':
          text = 'Status. Click to activate order';
          break;
        default:
          text = null;
          break;
      }

      setActiveChip({ text, withAction: true });
      return () => setActiveChip(null);
    }

    setActiveChip({ text: null, withAction: false });
    return () => setActiveChip(null);
  }, [order]);

  return (
    <div>
      <ListItem>
        <ListItemText id="order_status_badge" primary={activeChip?.text ?? 'Status'} />
        {activeChip?.withAction ? (
          <Chip
            onClick={() => setModalOpen(true)}
            sx={{ backgroundColor: colors[order.status] }}
            label={capitalize(order.status)}
          />
        ) : (
          <Chip sx={{ backgroundColor: colors[order.status] }} label={capitalize(order.status)} />
        )}
      </ListItem>
      <OrderStatusChangeModal open={modalOpen} closeFn={() => setModalOpen(false)} order={order} />
    </div>
  );
};

export default OrderStatus;
