import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import CreateEditProductItemForm from './components/CreateEditProductItemForm';

const CreateEditProductItemPage = (): JSX.Element => {
  const { productItemId } = useParams();

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          <CreateEditProductItemForm isUpdating={!!productItemId}></CreateEditProductItemForm>
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default CreateEditProductItemPage;
