import { IPropertyApiResponseModel } from '../../../shared/models/property.api.response.model';
import { api } from '../../../shared/services/api/api';

const baseUrl = 'retail-providers';

export const userRetailDataProviderApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRetailPropertyData: build.query<IPropertyApiResponseModel, { propertyId: string; companyId: string }>({
      query: ({ propertyId, companyId }) => ({
        url: `${baseUrl}/data/${companyId}/${propertyId}`,
      }),
    }),
  }),
});

export const { useGetRetailPropertyDataQuery, useLazyGetRetailPropertyDataQuery } = userRetailDataProviderApi;
