import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import BarChart from '@/statistics/components/BarChart';
import { IBaseChartsProps, IDataSetModel } from '@/statistics/components/chart-props.moldel';
import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import Woman2RoundedIcon from '@mui/icons-material/Woman2Rounded';
import Man2RoundedIcon from '@mui/icons-material/Man2Rounded';
import { useTranslation } from 'react-i18next';

export interface IGenderBreakDownProps {
  advertisement: IFullAdvertisementModel;
}

const GenderBreakDown: FC<IGenderBreakDownProps> = (props: IGenderBreakDownProps): JSX.Element => {
  const { advertisement } = props;

  const { t } = useTranslation();

  const prepareDataSets = (): IBaseChartsProps | null => {
    const {
      advertisementStats: { metaStats },
    } = advertisement;

    if (!metaStats) return null;

    const labelsSet = new Set<string>();
    const dataSets: IDataSetModel[] = [];

    for (const gendersData of metaStats.genderBreakdown) {
      for (const data of gendersData?.data) {
        labelsSet.add(data.key);
      }

      if (gendersData.key !== 'unknown') {
        dataSets.push({
          label: gendersData.key === 'male' ? t('male') : t('female'),
          data: gendersData.data.map((el) => el.impressions),
          backgroundColor: gendersData.key === 'male' ? '#162831' : '#ffa737',
        });
      }
    }

    return {
      labels: Array.from(labelsSet),
      backgroundColors: ['rgba(255, 99, 132, 0.2)', 'rgba(255, 159, 64, 0.2)'],
      dataSets,
    };
  };

  const calculateSumByGender = (gender: 'female' | 'male') => {
    return advertisement.advertisementStats.metaStats?.genderBreakdown
      .filter((el) => el.key === gender)?.[0]
      ?.data.reduce((acc, currVal) => {
        acc += currVal.impressions;
        return acc;
      }, 0);
  };

  return (
    <div>
      {advertisement.advertisementStats?.metaStats?.datesBreakdown.length ? (
        <Grid container item mt={2}>
          <Grid
            item
            xs={12}
            my={{ xs: 2, md: 8 }}
            md={2}
            display={'flex'}
            justifyContent={'space-around'}
            flexDirection={{ md: 'column' }}>
            <Box display={'flex'} alignItems={'center'}>
              <Woman2RoundedIcon sx={{ fontSize: 30, color: '#ffa737' }} />
              <Typography variant="h5">{calculateSumByGender('female')}</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <Man2RoundedIcon sx={{ fontSize: 30 }} />
              <Typography variant="h5">{calculateSumByGender('male')}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <BarChart
              labels={prepareDataSets()?.labels ?? ['female', 'male']}
              dataSets={
                prepareDataSets()?.dataSets ?? [
                  {
                    label: 'No data',
                    data: [0, 0, 10],
                  },
                ]
              }
            />
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GenderBreakDown;
