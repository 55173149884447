import { useTranslation } from 'react-i18next';
import { useGetAppInitQuery } from '@/admin/services/http/initialization.api';
import { useEffect } from 'react';

const useAppInitLanguage = (skip: boolean = false) => {
  const { i18n } = useTranslation();
  const {
    data: initialData,
    isSuccess: initialDataSuccess,
    refetch: refetchInitialData,
  } = useGetAppInitQuery(undefined, { skip: !skip });

  useEffect(() => {
    if (initialDataSuccess) {
      refetchInitialData();
      addAndChangeLanguage(initialData?.language.locale as string, initialData?.language.messages);
    }
  }, [initialDataSuccess, initialData, skip]);

  const addAndChangeLanguage = (locale: string, messages: { [key: string]: string } | undefined): void => {
    i18n.addResourceBundle(locale, 'translation', messages, true, true);

    i18n.changeLanguage(locale, (err) => {
      if (err) return console.log(err);
    });
  };
};

export default useAppInitLanguage;
