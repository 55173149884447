import { DeleteOutline } from '@mui/icons-material';
import CollectionsIcon from '@mui/icons-material/Collections';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import CommonButton from '../../../../../shared/components/CommonButton';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import EmptyImageCard from '../../../../../shared/components/platform-previews/previews/EmptyImageCard';
import { useAppDispatch } from '../../../../../store/hooks/redux';
import { removeCarouselItem, updateFieldOfCarouselItem } from '../../../../store/reducers/creative-step.slice';
import { PlatformType } from '../../../constants/available-platforms';
import {
  ICarouselCreativeFormValuesModel,
  ICarouselCreativeItemFormValuesModel,
} from './models/carousel-creative-form-values';

export interface ICarouselCreativeItemProps {
  carouselItem: ICarouselCreativeItemFormValuesModel;
  carouselItemIndex: number;
  platform: PlatformType;
  openMediaLibrary: (index: number) => void;
}

const CarouselCreativeItem: React.FC<ICarouselCreativeItemProps> = (props: ICarouselCreativeItemProps): JSX.Element => {
  const { platform, carouselItem, carouselItemIndex, openMediaLibrary } = props;

  const formikCtx = useFormikContext();
  const dispatch = useAppDispatch();

  const onDeleteItemClick = (index: number) => {
    const currentValues = (formikCtx.values! as ICarouselCreativeFormValuesModel).creativeItems;

    dispatch(removeCarouselItem({ platform, index }));
    formikCtx.setFieldValue(
      'creativeItems',
      currentValues.filter((el: ICarouselCreativeItemFormValuesModel, i: number) => i !== index)
    );
  };

  const updateCarouselItemField = useCallback((platform: PlatformType, index: number, field: string, value: string) => {
    dispatch(updateFieldOfCarouselItem({ platform, index, field, value }));
  }, []);

  return (
    <Grid item xs={12} key={carouselItemIndex} sx={{ mt: carouselItemIndex === 0 ? 3 : 0 }}>
      <Accordion TransitionProps={{ unmountOnExit: false }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-between',
              alignItems: 'center',
            }}>
            <Typography color="secondary" variant="h5">
              Skriv inn overskrift, beskrivelse, URL
            </Typography>
            <IconButton
              sx={{ ml: 'auto' }}
              onClick={(e) => {
                e.stopPropagation();
                openMediaLibrary(carouselItemIndex);
              }}>
              <CollectionsIcon />
            </IconButton>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDeleteItemClick(carouselItemIndex);
              }}>
              <DeleteOutline />
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {carouselItem.creativeMedia?.preSignedUrl ? (
              <Grid item xs={12} sx={{ cursor: 'pointer' }} onClick={() => openMediaLibrary(carouselItemIndex)}>
                <Tooltip title="Click image to open media library" placement="right">
                  <img
                    style={{
                      width: '100%',
                      maxHeight: '300px',
                      objectFit: 'contain',
                    }}
                    src={carouselItem.creativeMedia?.preSignedUrl || ''}
                    alt={`item ${carouselItemIndex}`}></img>
                </Tooltip>
              </Grid>
            ) : (
              <Grid item xs={12} sx={{ cursor: 'pointer' }} onClick={() => openMediaLibrary(carouselItemIndex)}>
                <EmptyImageCard />
              </Grid>
            )}
          </Grid>
          <CommonTextField
            required
            sx={{ mt: '15px' }}
            type="text"
            name={`creativeItems[${carouselItemIndex}].headline`}
            label={`Headline ${carouselItem?.headline?.length ?? 0}/34`}
            value={carouselItem?.headline}
            onChange={(e: any) => {
              formikCtx.handleChange(e);
              updateCarouselItemField(platform, carouselItemIndex, 'headline', e.target.value);
            }}
          />
          <CommonTextField
            required
            sx={{ mt: '15px' }}
            type="text"
            name={`creativeItems[${carouselItemIndex}].description`}
            label={`Description ${carouselItem.description?.length ?? 0}/90`}
            value={carouselItem?.description}
            onChange={(e: any) => {
              formikCtx.handleChange(e);
              updateCarouselItemField(platform, carouselItemIndex, 'description', e.target.value);
            }}
          />
          <CommonTextField
            required
            sx={{ mt: '15px' }}
            type="text"
            name={`creativeItems[${carouselItemIndex}].link`}
            label={`Link ${carouselItem.link?.length ?? 0}/90`}
            value={carouselItem.link}
            onChange={(e: any) => {
              formikCtx.handleChange(e);
              updateCarouselItemField(platform, carouselItemIndex, 'link', e.target.value);
            }}
          />
        </AccordionDetails>
        <AccordionActions>
          <CommonButton size="small" color="error" onClick={() => onDeleteItemClick(carouselItemIndex)}>
            Delete
          </CommonButton>
        </AccordionActions>
      </Accordion>
    </Grid>
  );
};

export default CarouselCreativeItem;
