import { api } from '@/shared/services/api/api';
import { IConnectUserRequest } from '@/connections/services/http/connect-user.http.service';

interface IGetIntegrationData {
  provider: string;
  data: string;
}

export const integrationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getIntegrationsData: builder.query<IConnectUserRequest, IGetIntegrationData>({
      query: ({ provider, data }) => `integrations/${provider}${data}`,
    }),
  }),
});

export const { useGetIntegrationsDataQuery } = integrationsApi;
