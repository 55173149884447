import { Box } from '@mui/system';
import { Audio } from 'react-loader-spinner';
import React from 'react';
import { styled } from '@mui/material/styles';

const PositionedLoaderBox = styled(Box)<{ absolute: 0 | 1 }>(({ absolute }) => {
  const defaultValues = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  if (absolute && absolute === 1) {
    return {
      ...defaultValues,
      ...{
        position: 'absolute',
        top: '50%',
        left: '50%',
      },
    };
  }

  return defaultValues;
});

export interface ILoaderProps {
  absolute?: 0 | 1;
}

const Loader: React.FC<ILoaderProps> = (props: ILoaderProps): JSX.Element => {
  return (
    <PositionedLoaderBox absolute={props.absolute ?? 1}>
      <Audio height="50" width="50" color="#7b68ee" visible={true} ariaLabel="circles-with-bar-loading" />
    </PositionedLoaderBox>
  );
};

export default Loader;
