import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { debounce } from '@/shared/hooks/debounce';
import { COLORS } from '@/shared/styles/colors';
import { useDeleteBudgetMutation, useGetBudgetsByCompanyIdAdminQuery } from '@/admin/services/http/budgets.api';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { ROUTE_BUDGETS_DECLARATIONS } from '@/admin/routes/budgets-routes';
import { useTranslation } from 'react-i18next';
import { AddCircle, Delete, Edit, ListAlt } from '@mui/icons-material';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'title',
    name: 'Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'value',
    name: 'Value',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'type',
    name: 'Type',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const BudgetsDataTable = (): JSX.Element => {
  const { companyId } = useParams();
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { t } = useTranslation();

  const { data, isLoading, isFetching } = useGetBudgetsByCompanyIdAdminQuery({
    companyId: companyId!,
    queryParams: {
      page,
      pageSize,
      search,
    },
  });

  const [deleteBudget, { isLoading: deleteBudgetLoading }] = useDeleteBudgetMutation();

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircle />,
        action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/${ROUTE_BUDGETS_DECLARATIONS.AdminBudgetsNewPage}`),
      },
    ];
  }, []);

  const mapBudgets = (data?: IBudgetModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          title: el.title,
          value: el.value,
          type: el.type.charAt(0).toUpperCase() + el.type.slice(1),
          createdAt: el.createdAt,
          actions: [
            {
              title: t('items'),
              icon: <ListAlt />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/budgets/${el.id}/budget-items`),
            },
            {
              title: t('edit'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/budgets/${el.id}/edit`),
            },
            {
              title: t('delete'),
              icon: <Delete />,
              action: () => deleteBudget({ id: el.id }),
              color: COLORS.delete,
            },
          ],
        }))
      : [];
  };

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  return (
    <div>
      <AdminTableWrapper
        header={t('budgets')}
        isLoading={isLoading || isFetching}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapBudgets(data?.items as IBudgetModel[]),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default BudgetsDataTable;
