import { IRoute } from '../../shared/models/route.model';
import RetailDataUserConnection from '../components/RetailDataUserConnection';
import ConnectionsLayout from '../layout/ConnectionsLayout';

export const connectionsRoutes: IRoute[] = [
  {
    path: '/connections',
    component: ConnectionsLayout,
    children: [
      {
        path: 'companies/:companyId/properties/:propertyId',
        component: RetailDataUserConnection,
      },
    ],
  },
];
