import React from 'react';
import { Button } from '@mui/material';

const CommonButton: React.FC<any> = (props): JSX.Element => {
  return (
    <Button
      {...props}
      color={props.color ? props.color : 'secondary'}
      variant={props.variant ? props.variant : 'contained'}
    />
  );
};

export default CommonButton;
