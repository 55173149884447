import React, { useEffect, useState } from 'react';
import { ILanguage, ILanguageDefault, languagesApi as LanguageApi } from '@/admin/services/http/languages.api';
import LanguageTablePresentational from './LanguageTablePresentational';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';

interface ILanguageTableContainerProps {
  selectedLanguage: string;
  selectedTranslatedLanguage: string;
  availableLanguages: ILanguageDefault[];
  handleTranslations: (word: string) => string;
}

const LanguageTableContainer: React.FC<ILanguageTableContainerProps> = ({
  selectedLanguage,
  selectedTranslatedLanguage,
  availableLanguages,
}): JSX.Element => {
  const [selectedLanguageMessages, setSelectedLanguageMessages] = useState<{ [key: string]: string }>();
  const [selectedTranslatedMessages, setSelectedTranslatedMessages] = useState<{ [key: string]: string }>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [updateLanguage, { isLoading: isLanguageUpdating }] = LanguageApi.useUpdateLanguageMutation();

  const [formData, setFormData] = useState<{ [key: string]: string }>();
  const [validationSchema, setValidationSchema] = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    initForm(selectedLanguageMessages, selectedTranslatedMessages);
  }, [selectedLanguageMessages, selectedTranslatedMessages]);

  const initForm = (
    selectedMessages: { [key: string]: string } | undefined,
    translatedMessages: { [key: string]: string } | undefined
  ) => {
    let _formData: { [key: string]: string } = {};
    let _validationSchema: { [key: string]: any } = {};

    for (let key in selectedMessages) {
      if (translatedMessages && translatedMessages[key] !== undefined) {
        _formData[key] = translatedMessages[key];
        _validationSchema[key] = Yup.string();
      }
    }

    setFormData(_formData);
    setValidationSchema(Yup.object().shape({ ..._validationSchema }));
  };

  const handleSubmitForm = (values: FormData, actions: FormikHelpers<any>) => {
    const languageProp = availableLanguages.find(
      (lang: ILanguageDefault) => lang.locale === selectedTranslatedLanguage
    );
    toast.promise(handleUpdateLanguage({ ...languageProp, messages: values }), {
      loading: t('processing'),
      success: <b>{t('successfulUpdate')}</b>,
      error: (err) => {
        if (err?.data?.length > 10) {
          return t('errorWhileUpdating');
        }

        return err?.data;
      },
    });

    actions.setSubmitting(false);
  };

  const handleTranslations = (word: string): string => {
    return t(word);
  };

  const {
    refetch: refetchLanguageMessageCopy,
    data: languageMessageCopyData,
    isLoading: isLoadingLanguageMessageCopy,
    isFetching: isFetchingLanguageMessageCopy,
  } = LanguageApi.useGetLanguageMessageCopyQuery(selectedLanguage);

  const {
    refetch: refetchTranslatedLanguageMessageCopy,
    data: translatedLanguageMessageCopyData,
    isLoading: isLoadingTranslatedLanguageMessageCopy,
    isFetching: isFetchingTranslatedLanguageMessageCopy,
  } = LanguageApi.useGetLanguageMessageCopyQuery(selectedTranslatedLanguage);

  const handleUpdateLanguage = async (values: ILanguage | any) => {
    const res = await updateLanguage(values);
  };

  useEffect(() => {
    // Refetch the data when selectedLanguage changes
    if (selectedLanguage !== '') {
      refetchLanguageMessageCopy();
    }
  }, [selectedLanguage, refetchLanguageMessageCopy]);

  useEffect(() => {
    // Refetch the data when selectedTranslatedLanguage changes
    if (selectedTranslatedLanguage !== '') {
      refetchTranslatedLanguageMessageCopy();
    }
  }, [selectedTranslatedLanguage, refetchTranslatedLanguageMessageCopy]);

  useEffect(() => {
    // Update the messages when data is fetched
    if (!isLoadingLanguageMessageCopy && languageMessageCopyData) {
      setSelectedLanguageMessages(languageMessageCopyData.messages ?? []);
    }
    if (!isLoadingTranslatedLanguageMessageCopy && translatedLanguageMessageCopyData) {
      setSelectedTranslatedMessages(translatedLanguageMessageCopyData.messages ?? []);
    }
    setIsLoading(isLoadingLanguageMessageCopy || isLoadingTranslatedLanguageMessageCopy);
    setIsFetching(isFetchingLanguageMessageCopy || isFetchingTranslatedLanguageMessageCopy);
  }, [languageMessageCopyData, translatedLanguageMessageCopyData]);

  return (
    <LanguageTablePresentational
      isSubmitting={isLanguageUpdating}
      selectedLanguageMessages={selectedLanguageMessages as { [key: string]: string }}
      initialFormValues={formData}
      onSubmit={handleSubmitForm}
      validationSchema={validationSchema}
      selectedTranslatedMessages={selectedTranslatedMessages as { [key: string]: string }}
      isLoading={isLoading}
      isFetching={isFetching}
      handleTranslations={handleTranslations}
    />
  );
};

export default LanguageTableContainer;
