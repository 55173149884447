import React from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import BidtheatrePreviewResolver from './BidTheatrePreviewResolver';
import { Box } from '@mui/material';
const BidTheatrePreviewSummary: React.FC<{
  values: IBasicCreativeFormValues;
  externalCompanyId: string;
}> = ({ values, externalCompanyId }): JSX.Element => {
  return (
    <Box maxWidth="600px">
      <BidtheatrePreviewResolver
        propertyType={values.propertyEstateType}
        areaInUsage={values.areaInUsage}
        propertyDebt={values.stateDebt}
        headline={values.headline!}
        description={values.description!}
        caption={values.caption!}
        ownershipType={values.ownershipType}
        propertyPriceQuote={values.priceQuote}
        viewDate={values.viewDate}
        companyExternalId={externalCompanyId}
        nativeAd={values.nativeAd}
        employeeEmail={values.employeeEmail}
        employeeAvatar={values.employeeAvatar}
        employeeName={values.employeeName}
        employeePhone={values.employeePhone}
      />
    </Box>
  );
};
export default BidTheatrePreviewSummary;
