import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { PlatformType } from '../../shared/constants/available-platforms';

export interface IPlatformChoice {
  id: PlatformType;
  title: string;
  icon: any;
}

export interface ISliderValuesPerPlatform {
  platform: PlatformType;
  value: number;
  isActive: boolean;
}

interface IActivePlatformsState {
  activePlatforms: PlatformType[];
  activePlatformsBudgetSpreading: Record<PlatformType, number>;
  selectedPlatform: PlatformType;
  slidersState: ISliderValuesPerPlatform[];
}

const initialState: IActivePlatformsState = {
  activePlatforms: [],
  activePlatformsBudgetSpreading: { meta: 0, snapchat: 0, delta: 0, bidtheatre: 0, boards: 0},
  selectedPlatform: 'meta',
  slidersState: [],
};

const activePlatformsSlice = createSlice({
  name: 'editor-active-platforms',
  initialState,
  reducers: {
    setActivePlatforms(state: IActivePlatformsState, action: PayloadAction<PlatformType[]>) {
      state.activePlatforms = action.payload;
    },
    setSpreadingBudgetForPlatform(
      state: IActivePlatformsState,
      action: PayloadAction<{ platform: PlatformType; value: number }>
    ) {
      const { platform, value } = action.payload;
      state.activePlatformsBudgetSpreading[platform] = +value;
    },
    setSelectedPlatform(state: IActivePlatformsState, action: PayloadAction<PlatformType>) {
      state.selectedPlatform = action.payload;
    },
    setSlidersInitialState(state: IActivePlatformsState, action: PayloadAction<ISliderValuesPerPlatform[]>) {
      state.slidersState = action.payload;
    },
    setSingleSliderState(state: IActivePlatformsState, action: PayloadAction<ISliderValuesPerPlatform>) {
      const { platform, value, isActive } = action.payload;
      const platformIndex = state.slidersState.findIndex((el) => el.platform === platform);
      state.slidersState[platformIndex] = { platform, value, isActive };
    },
    setSingleSliderValue(
      state: IActivePlatformsState,
      action: PayloadAction<{ platform: PlatformType; value: number }>
    ) {
      const { platform, value } = action.payload;
      const platformIndex = state.slidersState.findIndex((el) => el.platform === platform);
      state.slidersState[platformIndex].value = value;
    },
    setSingleSliderActiveState(
      state: IActivePlatformsState,
      action: PayloadAction<{ platform: PlatformType; isActive: boolean }>
    ) {
      const { platform, isActive } = action.payload;
      const platformIndex = state.slidersState.findIndex((el) => el.platform === platform);
      state.slidersState[platformIndex].isActive = isActive;
    },
  },
});

export const {
  setActivePlatforms,
  setSpreadingBudgetForPlatform,
  setSelectedPlatform,
  setSlidersInitialState,
  setSingleSliderActiveState,
  setSingleSliderState,
  setSingleSliderValue,
} = activePlatformsSlice.actions;

export default activePlatformsSlice.reducer;
