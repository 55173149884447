import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import CommonTextField from '../CommonTextField';
import { useTranslation } from 'react-i18next';
import { RefreshOutlined } from '@mui/icons-material';
import { Box } from '@mui/system';
import { ILanguageDefault } from '@/admin/services/http/languages.api';

type SubmitFn = (values: IUserProfileAddEditFormValues) => void;

interface IEditUserDetailsFormProps {
  initialValues: IUserProfileAddEditFormValues;
  submitFn: SubmitFn;
  actionLoading?: boolean;
  availableLanguages: ILanguageDefault[];
}

export interface IUserProfileAddEditFormValues {
  firstName: string;
  lastName: string;
  title?: string;
  phone: string;
  locale?: string;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  title: yup.string(),
  phone: yup.string().required('Phone is required'),
  locale: yup.string(),
});

const EditUserDetails: React.FC<IEditUserDetailsFormProps> = ({
  initialValues,
  submitFn,
  actionLoading,
  availableLanguages,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values: IUserProfileAddEditFormValues) => {
          submitFn(values);
        }}>
        {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <CommonTextField
              name="firstName"
              label={t('firstName')}
              value={values.firstName}
              onChange={handleChange}
              required
              autoFocus
              sx={{ mt: 2 }}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <CommonTextField
              name="lastName"
              label={t('lastName')}
              value={values.lastName}
              onChange={handleChange}
              required
              autoFocus
              sx={{ mt: 2 }}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
            <CommonTextField
              name="title"
              label={t('agentTitle')}
              value={values.title}
              onChange={handleChange}
              autoFocus
              sx={{ mt: 2 }}
              error={Boolean(touched.title && errors.title)}
              helperText={touched.title && errors.title}
            />
            <CommonTextField
              name="phone"
              label={t('phoneNumber')}
              value={values.phone}
              onChange={handleChange}
              required
              autoFocus
              sx={{ mt: 2 }}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />

            <FormControl sx={{ mt: 2 }} fullWidth>
              <InputLabel id="locale-label">{t('languages')}</InputLabel>
              <Select
                labelId="locale-label"
                id="locale"
                name="locale"
                value={values.locale ? values.locale : availableLanguages[0].locale}
                label="{t('languages')}"
                onChange={handleChange}>
                {availableLanguages?.map((el) => {
                  return (
                    <MenuItem key={el.locale} value={el.locale}>
                      {el.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Box component="div" display={'flex'} justifyContent={'flex-end'}>
              <Button
                sx={{ mt: 2 }}
                type="submit"
                endIcon={<RefreshOutlined />}
                variant="contained"
                disabled={actionLoading}>
                {t('submit')}
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default EditUserDetails;
