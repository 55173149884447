import { FunctionComponent, ReactNode, useState } from 'react';
import { IBidTheatreConfigModel } from '../../../../shared/models/companies/platform-configs/bid-theatre-config.model';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Grid, Typography, Select, MenuItem, FormControl, InputLabel, Chip, OutlinedInput } from '@mui/material';
import CommonButton from '../../../../shared/components/CommonButton';
import {
  useCreateBidTheatreConfigMutation,
  useUpdateBidTheatreConfigMutation,
} from '../../../services/http/companies-platform-configs.api';
import toast from 'react-hot-toast';
import { capitalize } from 'lodash';
import { FormHelperText } from '@mui/material';
import { useGetAllCategoriesQuery } from '@/admin/services/http/bidTheatre.api';
import Loader from '@/shared/components/Loader';
import { useTranslation } from 'react-i18next';

const types = ['0% Margin / At cost', '10% margin', '15% margin', '20%margin'];

export interface BidTheatreConfigFormProps {
  initialValues?: IBidTheatreConfigModel | null;
  companyId: string;
}
export interface IBidTheaterConfigFormValues {
  companyId: string;
  bidtheatrePublisherConfigs: { publisherId: string; publisherName: string }[];
  bidtheatreCategoriesConfigs: { categoryId: string; categoryName: string }[];
  spendModel: string;
  client: { clientId: string; clientName: string }[];
}

type publisher = {
  publisherId: string;
  publisherName: string;
};
type categories = {
  categoryId: any;
  categoryName: string;
};
type client = {
  clientId: any;
  clientName: string;
};

const publishers = [
  {
    publisherId: 112994,
    publisherName: 'Eie brandsafe sitelist',
  },
  {
    publisherId: 112995,
    publisherName: 'Sørmegleren brandsafe sitelist',
  },
  {
    publisherId: 7320,
    publisherName: 'BidTheatre - SE Whitelist',
  },
];
const clients = [
  {
    clientId: 12647,
    clientName: 'EIE',
  },
  {
    clientId: 12641,
    clientName: 'Sørmegleren',
  },
  {
    clientId: 12730,
    clientName: 'Svenska Mäklargruppen',
  },
  {
    clientId: 12733,
    clientName: 'Frem Eiendomsmegling',
  },
];
const BidTheatreConfigForm: FunctionComponent<BidTheatreConfigFormProps> = (
  props: BidTheatreConfigFormProps
): JSX.Element => {
  const { t } = useTranslation();
  const { initialValues, companyId } = props;
  const {
    data: categoryList,
    isLoading: categoryDataLoading,
    isFetching: categoryDataFetching,
  } = useGetAllCategoriesQuery();

  const [createBidTheatreConfig, { isLoading: bidTheatreCreationLoading }] = useCreateBidTheatreConfigMutation();
  const [updateBidTheatreConfig, { isLoading: bidTheatreUpdateLoading }] = useUpdateBidTheatreConfigMutation();
  const initialPublishers: publisher[] | string[] = initialValues?.bidtheatrePublisherConfigs || [];
  const initialCategories: categories[] | string[] = initialValues?.bidtheatreCategoriesConfigs || [];

  const [publisher, setSelectedPublishers] = useState<publisher[]>(
    initialPublishers.map((item) =>
      typeof item === 'string' ? ({ publisherId: item, publisherName: item } as publisher) : (item as publisher)
    )
  );

  const [category, setSelectedCategory] = useState<categories[]>(
    initialCategories.map((item) => (typeof item === 'string' ? { categoryId: item, categoryName: item } : item))
  );

  const transformedInitialClient = initialValues?.client;
  const transformedClientName = transformedInitialClient
    ? (typeof transformedInitialClient === 'number'
        ? clients.find((c) => c.clientId == transformedInitialClient)?.clientName
        : clients.find((c) => c.clientId === transformedInitialClient?.client)?.clientName) || ''
    : '';

  const [client, setSelectedClient] = useState(
    transformedInitialClient ? [{ clientId: transformedInitialClient, clientName: transformedClientName }] : []
  );

  const { handleSubmit, handleChange, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      bidtheatrePublisherConfigs: (initialValues?.bidtheatrePublisherConfigs || []) as unknown as {
        publisherId: string;
        publisherName: string;
      }[],
      bidtheatreCategoriesConfigs: (initialValues?.bidtheatreCategoriesConfigs || []) as unknown as {
        categoryId: string;
        categoryName: string;
      }[],
      spendModel: initialValues?.spendModel || '',
      client: initialValues?.client || '',
      companyId: '',
    },

    validationSchema: yup.object().shape({
      bidtheatrePublisherConfigs: yup.array().nullable().min(1, 'Please select publisher'),
      bidtheatreCategoriesConfigs: yup.array().min(1, 'Please select at least one category'),
      spendModel: yup.string().required('Please select spend model'),
      client: yup.string().required('Please select client'),
    }),

    onSubmit: async (values: IBidTheaterConfigFormValues) => {
      const transformedPublishers = publisher.map((item) => ({
        publisherId: item.publisherId,
        publisherName: item.publisherName,
      }));

      const transformedCategories = category.map((item) => ({
        categoryId: item.categoryId,
        categoryName: item.categoryName,
      }));

      const transformedClient = client.length === 1 ? client[0].clientId : client.map((item) => item.clientId);

      const updatedValues = {
        ...values,
        bidtheatrePublisherConfigs: transformedPublishers,
        bidtheatreCategoriesConfigs: transformedCategories,
        client: transformedClient,
      };

      try {
        if (!initialValues?.id) {
          await createBidTheatreConfig({ values: updatedValues, companyId }).unwrap();
        } else {
          await updateBidTheatreConfig({
            values: updatedValues as unknown as IBidTheaterConfigFormValues,
            id: initialValues.id,
          }).unwrap();
        }

        toast.success(initialValues ? 'Updated' : 'Created');
      } catch (error) {
        console.error('Error during API call:', error);
        toast.error('Error during API call');
      }
    },
  });

  const isLoadingFinished = (): boolean => !categoryDataLoading && !categoryDataFetching;

  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 3,
          }}>
          <img src="/images/bidtheatre/bidtheatre.png" width={50} />
          <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
            {t(`bidtheatre`)}
          </Typography>
        </Box>
        {isLoadingFinished() ? (
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <FormControl fullWidth sx={{ marginTop: '15px' }}>
              <InputLabel id="publishers_input_label_id">Publisher</InputLabel>

              <Select
                labelId="publishers_input_label_id"
                id="publishers_input_id"
                {...getFieldProps('bidtheatrePublisherConfigs')}
                sx={{ whiteSpace: 'normal' }}
                input={<OutlinedInput label="Publisher"></OutlinedInput>}
                value={publisher}
                onChange={(e) => {
                  handleChange(e);
                  const selectedValue: any = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
                  setSelectedPublishers(selectedValue);
                }}
                renderValue={(value: publisher[]) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    {value.map((item) => (
                      <Chip key={item.publisherId} label={item.publisherName} sx={{ margin: 1 }} />
                    ))}
                  </div>
                )}>
                {publishers?.map((item: any) => (
                  <MenuItem key={item.publisherId} value={item}>
                    {item.publisherName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!(touched.bidtheatrePublisherConfigs && errors.bidtheatrePublisherConfigs)}>
                {touched.bidtheatrePublisherConfigs && (errors.bidtheatrePublisherConfigs as ReactNode)}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '15px' }}>
              <InputLabel id="category_input_label_id">Category</InputLabel>
              <Select
                multiple
                {...getFieldProps('bidtheatreCategoriesConfigs')}
                labelId="category_input_label_id"
                id="category_input_id"
                input={<OutlinedInput label="Chip"></OutlinedInput>}
                value={category.map((item) => item.categoryId)}
                onChange={(e) => {
                  handleChange(e);
                  const selectedIds = e.target.value as unknown as string[];
                  const selectedItems: any = selectedIds.map((id) =>
                    categoryList?.find((item) => item.categoryId === id)
                  );
                  setSelectedCategory(selectedItems);
                }}
                renderValue={(value: categories[]) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    {value.map((id) => {
                      const selectedItem = categoryList?.find((item) => item.categoryId === id);
                      return (
                        <Chip
                          key={selectedItem?.categoryId}
                          label={selectedItem?.categoryName || ''}
                          sx={{ margin: 1 }}
                        />
                      );
                    })}
                  </div>
                )}>
                {categoryList?.map((item: any) => (
                  <MenuItem key={item.categoryId} value={item.categoryId}>
                    {item.categoryName}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText error={!!(touched.bidtheatreCategoriesConfigs && errors.bidtheatreCategoriesConfigs)}>
                {touched.bidtheatreCategoriesConfigs && (errors.bidtheatreCategoriesConfigs as ReactNode)}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '15px' }}>
              <InputLabel id="spend_model_input_label_id">{t('Spend Model')}</InputLabel>
              <Select
                labelId="spend_model_input_label_id"
                id="spend_model_input_id"
                {...getFieldProps('spendModel')}
                input={<OutlinedInput label="spendModel"></OutlinedInput>}
                renderValue={(value: string) => <Typography width="100%">{capitalize(value)}</Typography>}>
                {types?.map((el) => (
                  <MenuItem key={el} value={el}>
                    {capitalize(el)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!(touched.spendModel && errors.spendModel)}>
                {touched.spendModel && errors.spendModel}
              </FormHelperText>
            </FormControl>
            <FormControl fullWidth sx={{ marginTop: '15px' }}>
              <InputLabel id="client_input_label_id">Client</InputLabel>

              <Select
                labelId="client_input_label_id"
                id="client_input_id"
                {...getFieldProps('client')}
                sx={{ whiteSpace: 'normal' }}
                input={<OutlinedInput label="Client"></OutlinedInput>}
                value={client.map((c) => c.clientId)}
                onChange={(e) => {
                  handleChange(e);
                  const selectedValue: any = Array.isArray(e.target.value) ? e.target.value : [e.target.value];
                  setSelectedClient(
                    selectedValue.map((clientId: any) => ({
                      clientId,
                      clientName: clients.find((c) => c.clientId == clientId)?.clientName || '',
                    }))
                  );
                }}
                renderValue={(value: string[]) => (
                  <div style={{ whiteSpace: 'normal' }}>
                    {value.map((clientId: any) => (
                      <Chip
                        key={clientId}
                        label={clients.find((c) => c.clientId === clientId)?.clientName || ''}
                        sx={{ margin: 1 }}
                      />
                    ))}
                  </div>
                )}>
                {clients?.map((item: any) => (
                  <MenuItem key={item.clientId} value={item.clientId}>
                    {item.clientName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText error={!!(touched.client && errors.client)}>
                {touched.client && (errors.client as ReactNode)}
              </FormHelperText>
            </FormControl>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'end',
                  mt: 2,
                }}>
                <CommonButton type="submit" disabled={bidTheatreCreationLoading || bidTheatreUpdateLoading}>
                  {initialValues ? t('update') : t('create')}
                </CommonButton>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Loader />
        )}
      </Box>
    </div>
  );
};
export default BidTheatreConfigForm;
