import { deleteCredentials } from '@/auth/store/reducers/auth.slice';
import { clearLS, getCredentialsFromLS } from '@/shared/services/localstorage';
import { useAppDispatch } from '@/store/hooks/redux';
import { ArrowBackTwoTone } from '@mui/icons-material';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import useCurrentPath from '../../store/hooks/location';
import { adminRoutes, adminSideBarLinks } from '../routes/routes';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '@/editor/services/http/users.api';
import useAppInitLanguage from '@/shared/hooks/app-init-hook';
import { IAuthResponse } from '@/auth/models/auth.response';

const drawerWidth = 180;

const AdminLayout = () => {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentPath = useCurrentPath();

  const { t } = useTranslation();

  const credentials: IAuthResponse | null = getCredentialsFromLS();
  const { data: userData, isSuccess: userDataSuccess } = useGetMeQuery(undefined, { skip: !credentials });
  useAppInitLanguage(userDataSuccess);

  React.useEffect(() => {
    if (currentPath === adminRoutes[0].path) navigate('main');
  }, []);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleLogOutBtnClick = () => {
    clearLS();
    dispatch(deleteCredentials());
    navigate('/sign-in');
  };

  const handleMenuItemClick = (path: string) => {
    if (currentPath !== `/dashboard/${path}`) {
      navigate(path);
    }

    setOpen(false);
  };

  const drawer = (
    <div>
      <Toolbar color="secondary">
        <Box component="div" width={drawerWidth}>
          <img style={{ width: '100%' }} src="/Makeads_logo.png"></img>
        </Box>
      </Toolbar>
      <Divider />
      <List sx={{ padding: 0 }}>
        {adminSideBarLinks.map(({ title, icon: Icon, path }, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton selected={currentPath === `/dashboard/${path}`} onClick={() => handleMenuItemClick(path)}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={t(title)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box component="div" sx={{ display: 'flex' }}>
      <AppBar position="fixed" color="secondary" sx={{ width: '100%' }}>
        <Toolbar>
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}>
            <Box component="div" display="flex">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}>
                <MenuIcon />
              </IconButton>
              <IconButton onClick={() => navigate(-1)}>
                <ArrowBackTwoTone sx={{ color: '#fff' }} />
              </IconButton>
              <Box
                component="div"
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography variant="h6" noWrap component="div">
                  Admin
                </Typography>
              </Box>
            </Box>
            <Box component="div">
              <IconButton color="inherit" onClick={handleLogOutBtnClick}>
                <LogoutTwoToneIcon></LogoutTwoToneIcon>
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
            },
          }}>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 2,
        }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
};

export default AdminLayout;
