import {
  AvailablePlatformsEnum,
  PlatformType,
  platformsToCropImage,
} from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { ICarouselCreativeItemFormValuesModel } from '@/editor/shared/components/forms/creatives/models/carousel-creative-form-values';
import {
  ICreativeFormsStatePerPlatform,
  addedMediaOnDynamic,
  removeMediaOnDynamic,
  removeMediaOnSingle,
  setMediaOnCarouselItem,
  setMediaOnSingle,
} from '@/editor/store/reducers/creative-step.slice';
import { setImageCropper } from '@/editor/store/reducers/modals-drawers.slice';
import EmptyImageCard from '@/shared/components/platform-previews/previews/EmptyImageCard';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { ImageListItem, ImageListItemBar } from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useLazyCreateImageOverlayQuery } from '@/editor/services/http/media-library.api';
import ImageBox from './ImageBox';
import useCheckIfSelectedImage from './hooks/useCheckIfSelectedImage';
import ConfirmImageDialog from './ConfirmImageDialog';

export interface IMediaLibraryImageItemProps {
  item: IMediaLibraryImageModel;
  queue: number;
  // drawerState: IMediaLibraryDrawerState;
  // creativeState: ICreativeStepState;
}

const MediaLibraryImageItem: React.FC<IMediaLibraryImageItemProps> = ({ item, queue }): JSX.Element => {
  const dispatch = useAppDispatch();
  const drawerState = useAppSelector((state) => state.editorModalsDrawersReducer.mediaLibraryDrawerState);
  const creativeState = useAppSelector((state) => state.creativeStepSliceReducer);
  const [imageDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [imageOverlayUrl, setImageOverlayUrl] = useState<string | undefined>('');

  const { isImageSelectedByPlatform, isImageSomeWhereSelected } = useCheckIfSelectedImage(
    drawerState,
    creativeState,
    item
  );

  // TODO ***** First make it work, than refactor it to clean, readable and testable solution *****
  // TODO 1. Connect with backend
  // TODO 2. Connect select image with handleImageClick
  // TODO 3. Divide for different ad type
  // TODO 4. Translate
  // TODO 5. Check Mobile
  // TODO 6. Test standard
  // TODO 7. Test update
  // TODO 8. Finish
  // TODO 9. Push to production tested and verified

  const handleImageClick = (platformOption: PlatformType, image: IMediaLibraryImageModel): void => {
    if (isImageSelectedByPlatform(platformOption)) {
      removeImage(platformOption, image);
      return;
    }

    if (ruleForDynamicAndCarousel(platformOption)) {
      toast.error('You can select only 5 images for dynamic and carousel creatives');
      return;
    }
    addingImage(platformOption, image);
  };

  const addingImage = (platformOption: string, image: IMediaLibraryImageModel): void => {
    const platform = platformOption as PlatformType;

    if (!checkDimensionsOfImage(platform, image)) {
      return;
    }

    const creativeType =
      platformOption === AvailablePlatformsEnum.DELTA ||
      platformOption === AvailablePlatformsEnum.BIDTHEATRE ||
      platformOption === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType;

    switch (creativeType) {
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, image);
        break;
      case CreativeTypesEnum.DYNAMIC:
        dispatch(addedMediaOnDynamic({ media: image, platform }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(setMediaOnSingle({ media: image, platform }));
        break;
    }
  };

  const removeImage = (platformOption: string, image: IMediaLibraryImageModel): void => {
    const creativeType =
      platformOption === AvailablePlatformsEnum.DELTA ||
      platformOption === AvailablePlatformsEnum.BIDTHEATRE ||
      platformOption === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType;

    const platform = platformOption as PlatformType;
    switch (creativeType) {
      case CreativeTypesEnum.DYNAMIC:
        dispatch(removeMediaOnDynamic({ platform, media: image }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(removeMediaOnSingle({ platform }));
        break;
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, null);
        break;
    }
  };

  const checkDimensionsOfImage = (platform: PlatformType, image: IMediaLibraryImageModel): boolean => {
    const platformConfigToCrop = platformsToCropImage.find((el) => el.platform === platform);

    if (
      platformConfigToCrop &&
      (image.width !== platformConfigToCrop.width || image.height !== platformConfigToCrop.height)
    ) {
      dispatch(
        setImageCropper({
          isOpened: true,
          image: image,
          platform: platform,
          propertyInternalId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID),
          widthToCrop: platformConfigToCrop.width,
          heightToCrop: platformConfigToCrop.height,
        })
      );

      return false;
    }

    return true;
  };

  const updateCarouselItemImageIfNeeded = (
    platformOption: PlatformType,
    media: IMediaLibraryImageModel | null
  ): void => {
    if (drawerState.carouselItemIndex !== undefined) {
      dispatch(
        setMediaOnCarouselItem({
          media,
          index: drawerState.carouselItemIndex,
          platform: platformOption as PlatformType,
        })
      );
    }
    if (media === null && drawerState.carouselItemIndex !== undefined) {
      toast.error('This images is already selected!');
    }
  };

  const ruleForDynamicAndCarousel = (platform: PlatformType): boolean => {
    const form = creativeState.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
    const medias: IMediaLibraryImageModel[] | ICarouselCreativeItemFormValuesModel[] =
      drawerState.creativeType === CreativeTypesEnum.DYNAMIC
        ? form.dynamic?.creativeMedias
        : form.carousel?.creativeItems;
    return medias?.length >= 5;
  };

  const [createImageOverlay, { isFetching: overlayFetching }] = useLazyCreateImageOverlayQuery();

  const handleImageOverlay = (id: any) => {
    setDialogOpen(true);
    createImageOverlay({ propertyFileId: id.id }).then((res) => {
      setImageOverlayUrl(res.data);
    });
  };

  return (
    <div>
      <ImageListItem sx={{ border: isImageSomeWhereSelected() ? '5px solid #000' : '5px solid transparent' }}>
        {item.preSignedUrl ? (
          <ImageBox
            image={item}
            platform={drawerState.platform!}
            handleImageClick={handleImageClick}
            handleImageOverlay={handleImageOverlay}
            isImageSelectedByPlatform={isImageSelectedByPlatform}
            isImageSomeWhereSelected={isImageSomeWhereSelected}
          />
        ) : (
          <EmptyImageCard />
        )}
        <ImageListItemBar
          sx={{
            display: { xs: 'none', md: 'inline' },
            p: 0,
            '& .MuiImageListItemBar-titleWrap': {
              padding: '4px 8px',
            },
          }}
          title={queue + 1 + ' - ' + item.width + 'x' + item.height}></ImageListItemBar>
      </ImageListItem>
      <ConfirmImageDialog
        open={imageDialogOpen}
        imageIsLoading={overlayFetching}
        imageUrl={imageOverlayUrl as string}
        onClose={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default MediaLibraryImageItem;
