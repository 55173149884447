import React from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import DeltaPreviewResolver from './DeltaPreviewResolver';
import { Box } from '@mui/material';

const DeltaPreviewSummary: React.FC<{
  values: IBasicCreativeFormValues;
  externalCompanyId: string;
}> = ({ values, externalCompanyId }): JSX.Element => {
  return (
    <Box maxWidth="600px">
      <DeltaPreviewResolver
        propertyType={values.propertyEstateType}
        areaInUsage={values.areaInUsage}
        propertyDebt={values.stateDebt}
        headline={values.headline!}
        description={values.description!}
        caption={values.caption!}
        ownershipType={values.ownershipType}
        propertyPriceQuote={values.priceQuote}
        viewDate={values.viewDate}
        companyExternalId={externalCompanyId}
      />
    </Box>
  );
};

export default DeltaPreviewSummary;
