import { DialogTitle, Drawer, IconButton } from '@mui/material';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { setMediaLibraryDrawerState } from '../../store/reducers/modals-drawers.slice';
import MediaLibraryWrapper from './MediaLibraryWrapper';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';

const MediaLibraryDrawer: React.FC = (): JSX.Element => {
  const { mediaLibraryDrawerState } = useAppSelector((state) => state.editorModalsDrawersReducer);

  const dispatch = useAppDispatch();
  const handleCloseDrawer = () => {
    dispatch(
      setMediaLibraryDrawerState({
        isOpened: false,
        creativeType: null,
        carouselItemIndex: undefined,
        platform: null,
      })
    );
  };

  return (
    <Drawer
      anchor="right"
      open={mediaLibraryDrawerState.isOpened}
      onClose={handleCloseDrawer}
      PaperProps={{ sx: { width: { xs: '100%', sm: '80%', md: '80%' } } }}>
      <DialogTitle sx={{ p: 0, display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton onClick={handleCloseDrawer}>
          <ClearTwoToneIcon />
        </IconButton>
      </DialogTitle>
      <MediaLibraryWrapper></MediaLibraryWrapper>
    </Drawer>
  );
};

export default MediaLibraryDrawer;
