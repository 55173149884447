import { Route, Routes } from 'react-router-dom';
import { adminRoutes } from './admin/routes/routes';
import { IRoute } from './shared/models/route.model';
import { authRoutes } from './auth/routes';
import NotFound from './shared/components/NotFound';
import { connectionsRoutes } from './connections/routes/routes';
import { editorRoutes } from './editor/routes/routes';
import { statisticsRoutes } from './statistics/routes/routes';
import { integrationsRoutes } from '@/integrations/routes/routes';

const Router = () => {
  const renderRoutes = (routes: IRoute[]) => {
    return routes.map(({ component: Component, guard: Guard, path, children }, index) => {
      Guard = Guard;
      return (
        <Route
          path={path}
          key={index}
          element={Guard ? <Guard>{Component && <Component />}</Guard> : <div>{Component && <Component />}</div>}>
          {children && children.length > 0 && renderRoutes(children)}
        </Route>
      );
    });
  };

  return (
    <Routes>
      {renderRoutes(adminRoutes)}
      {renderRoutes(authRoutes)}
      {renderRoutes(connectionsRoutes)}
      {renderRoutes(integrationsRoutes)}
      {renderRoutes(editorRoutes)}
      {renderRoutes(statisticsRoutes)}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Router;
