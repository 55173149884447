import { api } from "../../../shared/services/api/api";
import { IAuthRequest } from "../../models/auth.request";
import { IAuthResponse } from "../../models/auth.response";

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<IAuthResponse, IAuthRequest>({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
