import React, { FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import CommonTextField from '@/shared/components/CommonTextField';
import * as yup from 'yup';
import CommonButton from '@/shared/components/CommonButton';
import {
  Autocomplete,
  Box,
  Card,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import {
  useCreateOfficeBoardsConfigMutation,
  useUpdateOfficeBoardsConfigMutation,
} from '../../../services/http/offices-platform-configs.api';
import GenerateShadowInstagram from '@/admin/shared/components/modals/GenerateShadowInstagram';
import boards from '/images/boards/boards_logo.jpg';
import {
  useGetAllMedialistQuery,
  useGetAllAudincesQuery,
  useGetAllFiltersQuery,
} from '@/admin/services/http/boards.api';
import Loader from '@/shared/components/Loader';
import CancelIcon from '@mui/icons-material/Cancel';
import { useTranslation } from 'react-i18next';
import { IOfficeBoardsConfigModel } from '@/shared/models/offices/office-boards-config.model';
import { useParams } from 'react-router-dom';
export interface IOfficeBoardsConfigFormProps {
  initialValues?: IOfficeBoardsConfigModel | null;
  officeId: string;
}
interface IBoardsUiTemplateFormValues {
  mediaList: { mediaListId: string | number; mediaListName: string }[];
  maxCPM: string | number;
  targetShare: string | number;
  audience: { audienceId: string | number; audienceName: string }[];
  filter: { filterId: string | number; filterName: string }[];
}
export interface IOfficeBoardsConfigFormValues {
  doohOfficeBidStrategyConfigs: IBoardsUiTemplateFormValues[];
  doohSchedules: ScheduleItem[];
}
interface ScheduleItem {
  startHours: string;
  endHours: string;
}
interface mediaListItem {
  mediaListId: string | number;
  mediaListName: string | number;
}
const OfficeBoardsConfigForm: FunctionComponent<IOfficeBoardsConfigFormProps> = (
  props: IOfficeBoardsConfigFormProps
): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { initialValues, officeId } = props;
  const {
    data: mediaList,
    isLoading: mediaDataLoading,
    isFetching: mediaDataFetching,
  } = useGetAllMedialistQuery({ id: id! }, { skip: !id });
  const {
    data: audiencelist,
    isLoading: audienceDataLoading,
    isFetching: audienceDataFetching,
  } = useGetAllAudincesQuery({ id: id! }, { skip: !id });
  const { data: filterlist, isLoading: filterDataLoading, isFetching: filterDataFetching } = useGetAllFiltersQuery();
  const [instDialogOpened, setInstDialogOpened] = useState<boolean>(false);
  const [createBoardsConfig, { isLoading: boardsCreationLoading }] = useCreateOfficeBoardsConfigMutation();
  const [updateBoardsConfig, { isLoading: boardsUpdateLoading }] = useUpdateOfficeBoardsConfigMutation();
  const { getFieldProps, getFieldMeta, touched, errors, handleSubmit, setFieldValue, values } = useFormik({
    initialValues: {
      doohSchedules: initialValues?.doohSchedules?.map((el) => ({
        startHours: el.startHours,
        endHours: el.endHours,
      })) || [{ startHours: '', endHours: '' }],
      doohOfficeBidStrategyConfigs: initialValues?.doohOfficeBidStrategyConfigs?.map((el) => ({
        mediaList: el.mediaList?.map((media: any) => media.mediaListId),
        maxCPM: el.maxCPM,
        audience: el.audience,
        targetShare: el.targetShare,
        filter: el.filter,
      })) || [{ mediaList: [], maxCPM: '', audience: [], targetShare: '', filter: [] }],
    },
    validationSchema: yup.object().shape({
      doohOfficeBidStrategyConfigs: yup
        .array()
        .of(
          yup.object().shape({
            mediaList: yup.array().min(1, 'Please select at least one mediaList'),
            maxCPM: yup.string().required('CPM is required'),
          })
        )
        .min(1)
        .required(),
    }),
    onSubmit: async (values: IOfficeBoardsConfigFormValues) => {
      const updatedValues = {
        ...values,
        doohOfficeBidStrategyConfigs: values.doohOfficeBidStrategyConfigs.map((config) => ({
          ...config,
          mediaList: config.mediaList.map((mediaId: any) => {
            const media = mediaList?.find((media: { mediaListId: number | string }) => media.mediaListId === mediaId);
            return { mediaListId: mediaId, mediaListName: media?.mediaListName || '' };
          }),
        })),
      };
      !initialValues?.id
        ? await createBoardsConfig({ values: updatedValues, officeId }).unwrap()
        : await updateBoardsConfig({ values: updatedValues, officeId }).unwrap();
      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });
  const addValues = async () => {
    await setFieldValue('doohOfficeBidStrategyConfigs', [
      ...getFieldMeta('doohOfficeBidStrategyConfigs').value,
      {
        mediaList: [],
        maxCPM: '',
        audience: [],
        targetShare: '',
        filter: [],
      },
    ]);
  };
  const removeValues = async (index: number) => {
    await setFieldValue(
      'doohOfficeBidStrategyConfigs',
      getFieldMeta('doohOfficeBidStrategyConfigs').value.filter((_: IBoardsUiTemplateFormValues, idx: number) => {
        return index !== idx;
      })
    );
  };
  const isLoadingFinished = (): boolean =>
    !mediaDataLoading &&
    !mediaDataFetching &&
    !audienceDataLoading &&
    !audienceDataFetching &&
    !filterDataLoading &&
    !filterDataFetching;
  const handleUpdateConfig = async (index: number, updatedConfig: any) => {
    const currentConfigs = getFieldMeta('doohOfficeBidStrategyConfigs').value;
    currentConfigs[index] = updatedConfig;
    await setFieldValue('doohOfficeBidStrategyConfigs', currentConfigs);
  };

  const hours = [...Array(24).keys()].map((h) => {
    const hourStr = h < 10 ? `0${h}` : `${h}`;
    return { label: `${hourStr}:00`, value: h };
  });

  const startHoursValue = getFieldMeta('doohSchedules[0].startHours').value;
  const endHoursValue = getFieldMeta('doohSchedules[0].endHours').value;
  const currentStartHour = hours.find((hour) => hour.value === parseInt(startHoursValue, 10));
  const currentEndHour = hours.find((hour) => hour.value === parseInt(endHoursValue, 10));

  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 3,
          }}>
          <img src={boards} width={50} />
          <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
            {t('Boards')}
          </Typography>
        </Box>
        {isLoadingFinished() ? (
          <form noValidate onSubmit={(e) => handleSubmit(e)}>
            <Typography variant="h5" sx={{ mt: 2, mb: 2 }} color="secondary">
              Set schedule
            </Typography>
            <Grid sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={{ marginRight: '10px' }}>Start Time:</Typography>
                <FormControl>
                  <Autocomplete
                    className="dateSelect"
                    options={hours}
                    getOptionLabel={(option: any) => option.label}
                    renderInput={(params) => <TextField {...params} label="Start Time" variant="outlined" />}
                    value={currentStartHour as any}
                    onChange={(event, newValue) => {
                      setFieldValue('doohSchedules[0].startHours', newValue ? newValue.value : '');
                    }}
                    freeSolo
                    ListboxProps={{ style: { maxHeight: 200 } }}
                    disableClearable
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Typography sx={{ marginRight: '10px' }}>End Time:</Typography>
                <FormControl>
                  <Autocomplete
                    className="dateSelect"
                    options={hours}
                    getOptionLabel={(option: any) => option.label}
                    renderInput={(params) => <TextField {...params} label="End Time" variant="outlined" />}
                    value={currentEndHour as any}
                    onChange={(event, newValue) => {
                      setFieldValue('doohSchedules[0].endHours', newValue ? newValue.value : '');
                    }}
                    freeSolo
                    ListboxProps={{ style: { maxHeight: 200 } }}
                    disableClearable
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid container spacing={3}>
              {getFieldMeta('doohOfficeBidStrategyConfigs').value?.map(
                (el: IBoardsUiTemplateFormValues, key: number) => {
                  return (
                    <Grid item xs={12} md={12} key={key}>
                      <div>
                        <Card variant="outlined" sx={{ mt: 3, pr: 2, pl: 2, pb: 2 }}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 2,
                              }}>
                              <CardHeader
                                action={
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      width: '100%',
                                      justifyContent: 'flex-end',
                                    }}>
                                    <Typography variant="h5" sx={{ mt: 2, mb: 2 }} color="secondary">
                                      BidStrategy
                                    </Typography>
                                  </Box>
                                }></CardHeader>
                              <IconButton onClick={() => removeValues(key)}>
                                <CancelIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                          <FormControl fullWidth sx={{ marginTop: '15px' }}>
                            <Autocomplete
                              multiple
                              id={`medialist_input_id_${key}`}
                              options={mediaList?.map((option) => option.mediaListName) || []}
                              value={values.doohOfficeBidStrategyConfigs[key].mediaList?.map((mediaId: any) => {
                                const selectedMedia = mediaList?.find(
                                  (media: mediaListItem) => media.mediaListId === mediaId
                                );
                                return selectedMedia?.mediaListName || '';
                              })}
                              onChange={(event, newValue) => {
                                const selectedMediaIds = newValue.map((mediaName: string) => {
                                  const selectedMedia = mediaList?.find(
                                    (media: mediaListItem) => media.mediaListName === mediaName
                                  );
                                  return selectedMedia?.mediaListId || '';
                                });
                                handleUpdateConfig(key, {
                                  ...values.doohOfficeBidStrategyConfigs[key],
                                  mediaList: selectedMediaIds,
                                });
                              }}
                              renderInput={(params) => <TextField {...params} label="Media List" />}
                            />
                            <FormHelperText
                              error={
                                !!(
                                  touched?.doohOfficeBidStrategyConfigs?.[key]?.mediaList &&
                                  (errors.doohOfficeBidStrategyConfigs as any[])?.[key]?.mediaList
                                )
                              }>
                              {touched?.doohOfficeBidStrategyConfigs?.[key]?.mediaList &&
                                (errors.doohOfficeBidStrategyConfigs as any[])?.[key]?.mediaList}
                            </FormHelperText>
                          </FormControl>
                          <FormControl fullWidth sx={{ marginTop: '15px' }}>
                            <Autocomplete
                              id={`audience_input_id_${key}`}
                              options={audiencelist || []}
                              getOptionLabel={(option) => option.audienceName}
                              value={values?.doohOfficeBidStrategyConfigs[key]?.audience[0] || null}
                              onChange={(event, newValue) => {
                                const updatedConfig = {
                                  ...values.doohOfficeBidStrategyConfigs[key],
                                  audience: newValue
                                    ? [{ audienceId: newValue.audienceId, audienceName: newValue.audienceName }]
                                    : [],
                                };
                                handleUpdateConfig(key, updatedConfig);
                              }}
                              renderInput={(params) => <TextField {...params} label="Audience" variant="outlined" />}
                            />
                          </FormControl>
                          <FormControl fullWidth sx={{ marginTop: '15px' }}>
                            <Autocomplete
                              id={`filter_input_id_${key}`}
                              options={filterlist || []}
                              getOptionLabel={(option) => option.filterName}
                              value={values?.doohOfficeBidStrategyConfigs[key]?.filter[0] || null}
                              onChange={(event, newValue) => {
                                const updatedConfig = {
                                  ...values.doohOfficeBidStrategyConfigs[key],
                                  filter: newValue
                                    ? [{ filterId: newValue.filterId, filterName: newValue.filterName }]
                                    : [],
                                };
                                handleUpdateConfig(key, updatedConfig);
                              }}
                              renderInput={(params) => <TextField {...params} label="Filter" variant="outlined" />}
                            />
                          </FormControl>
                          <CommonTextField
                            sx={{ mt: '15px' }}
                            type="number"
                            {...getFieldProps(`doohOfficeBidStrategyConfigs[${key}].maxCPM`)}
                            label="Max CPM"
                            required
                            error={Boolean(
                              touched?.doohOfficeBidStrategyConfigs?.[key]?.maxCPM &&
                                (errors?.doohOfficeBidStrategyConfigs as any[])?.[key]?.maxCPM
                            )}
                            helperText={
                              touched?.doohOfficeBidStrategyConfigs?.[key]?.maxCPM &&
                              (errors?.doohOfficeBidStrategyConfigs as any[])?.[key]?.maxCPM
                            }
                          />
                          <CommonTextField
                            sx={{ mt: '15px' }}
                            type="number"
                            {...getFieldProps(`doohOfficeBidStrategyConfigs[${key}].targetShare`)}
                            label="targetShare"
                            required
                          />
                        </Card>
                      </div>
                    </Grid>
                  );
                }
              )}
            </Grid>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mt: 4,
                }}>
                <CommonButton onClick={() => addValues()} type="button">
                  Add BidStrategy
                </CommonButton>
                <CommonButton type="submit" disabled={boardsCreationLoading || boardsUpdateLoading}>
                  {initialValues ? t('update') : t('create')}
                </CommonButton>
              </Grid>
            </Grid>
          </form>
        ) : (
          <Loader />
        )}
        {instDialogOpened && <GenerateShadowInstagram type="office" closeFn={() => setInstDialogOpened(false)} />}
      </Box>
    </div>
  );
};
export default OfficeBoardsConfigForm;
