import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useCreateMoovlyMutation, useLazyGetMoovlyTemplatesQuery } from '@/editor/services/http/moovly.api';
import {
  Box,
  Button,
  capitalize,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Typography,
} from '@mui/material';
import Loader from '../../../../../shared/components/Loader';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { IMoovlyTemplateApiResponseModel } from '@/shared/models/moovly/moovly-template-api-response.model';
import { IMoovlyVariablesApiResponseModel } from '@/shared/models/moovly/moovly-variables-api-response.model';
import { toast } from 'react-hot-toast';
import MoovlyImageSelectorDialog from './MoovlyImageSelectorDialog';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import ImgVariableRepresentor from './ImgVariableRepresentor';
import { IMoovlyReadyToSendVariablesModel } from '@/shared/models/moovly/moovly-ready-to-send.model';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { Send } from '@mui/icons-material';
import PropertyIdForm from '@/editor/pages/video-library/components/PropertyIdForm';
import { setPropertyInternalId } from '@/editor/store/reducers/property-info.slice';

export interface IMoovlyCreatorTab {
  setView?: Dispatch<SetStateAction<string>>;
}

const MoovlyCreatorTab: FC<IMoovlyCreatorTab> = ({ setView }): JSX.Element => {
  const [compData, setCompData] = useState<IMoovlyTemplateApiResponseModel | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedImgIndex, setSelectedImgIndex] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const propertyInternalId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  const [
    getMovieTemplates,
    {
      data: moovlyTemplates,
      isLoading: isLoadingTemplates,
      isFetching: isFetchingTemplates,
      isUninitialized: isUninitializedTemplates,
      isSuccess: isSuccesTemplates,
      isError: isErrorTemplates,
    },
    { lastArg },
  ] = useLazyGetMoovlyTemplatesQuery();

  const [createMoovly, { isLoading: createMoovlyLoading }] = useCreateMoovlyMutation();
  const { t } = useTranslation();

  const handleSubmitCreation = async () => {
    if (!compData?.variables) return;

    const result: IMoovlyReadyToSendVariablesModel[] = [];

    for (let variable of compData?.variables) {
      if (variable && variable.type === 'text') {
        if (variable.default.length < variable.requirements?.minimumLength!) {
          toast.error(
            `${variable.name.toUpperCase()} should be at least ${variable.requirements?.minimumLength} characters long`
          );
          return;
        }

        if (variable.default.length > variable.requirements?.maximumLength!) {
          toast.error(
            `${variable.name.toUpperCase()} should be at most ${variable.requirements?.maximumLength} characters long`
          );
          return;
        }
      }

      result.push({ id: variable.id, value: variable.default });
    }

    await toast.promise(createMoovly({ values: result, propertyId: propertyInternalId }).unwrap(), {
      loading: t('processing'),
      error: t('somethingWrong'),
      success: <b>{t('producingStarted')}</b>,
    });

    setView && setView('list');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: number) => {
    const variable = { ...compData?.variables[key] } as IMoovlyVariablesApiResponseModel;
    variable.default = e.target.value;
    const variables = [...compData?.variables!];
    variables![key] = variable;
    setCompData({ ...compData!, variables: variables });
  };

  const handleImgSelected = (image: IMediaLibraryImageModel) => {
    const variable = { ...compData?.variables[selectedImgIndex!] } as IMoovlyVariablesApiResponseModel;
    variable.default = image.preSignedUrl;
    const variables = [...compData?.variables!];
    variables![selectedImgIndex!] = variable;
    setCompData({ ...compData!, variables: variables });
    setSelectedImgIndex(null);
  };

  const handleDownloadData = async (propertyExtId: string) => {
    const response = await getMovieTemplates({ propertyExtId }).unwrap();
    dispatch(setPropertyInternalId(response.internalPropertyId));
    console.log(response);
    setCompData(response.templateData);
    setPreviewUrl(response.templateData.preview);
  };

  return (
    <Grid item xs={12}>
      {isLoadingTemplates ? (
        <Loader />
      ) : (
        <div>
          {isErrorTemplates ? (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant="h5" color="error">
                {t('somethingWrong')}
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Paper sx={{ p: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <PropertyIdForm t={t} handleDownloadClick={handleDownloadData} />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider></Divider>
                    </Grid>

                    {compData?.variables
                      .filter((el) => el.type === 'text')
                      .map((el, key) => {
                        return (
                          <Grid item xs={12} key={key}>
                            {el.type === 'text' && (
                              <CommonTextField
                                variant="outlined"
                                size="small"
                                error={el.default.length > el.requirements?.maximumLength!}
                                label={capitalize(el.name)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                                  handleChange(e, el.weight - 1)
                                }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Typography
                                        variant="caption"
                                        color={el.default.length > el.requirements?.maximumLength! ? '#d32f2f' : ''}>
                                        {el.default.length}/{el.requirements?.maximumLength}
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                }}
                                value={el.default}>
                                {el.default}
                              </CommonTextField>
                            )}
                          </Grid>
                        );
                      })}
                  </Grid>
                </Paper>
              </Grid>
              {isSuccesTemplates && (
                <div>
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3 }}>
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={4}>
                          <Typography mb={2} variant="h5">
                            {t('videoPreview')}
                          </Typography>
                          <video width="90%" style={{ borderRadius: '5px' }} height="auto" controls>
                            <source src={moovlyTemplates?.templateData?.preview! + '#t=0.1'} type="video/mp4" />
                          </video>
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <Grid container spacing={2} display={'flex'} justifyItems={'flex-end'}>
                            <Grid item xs={12}>
                              <Typography mb={1} variant="h5">
                                {t('imageSelect')}
                              </Typography>
                            </Grid>

                            {compData?.variables
                              .filter((el) => el.type === 'image')
                              .map((el, key) => {
                                return (
                                  <Grid item xs={6} md={4} key={key}>
                                    {el.type === 'image' && (
                                      <ImgVariableRepresentor
                                        el={el}
                                        imgKey={el.weight - 1}
                                        setSelectedImgIndex={setSelectedImgIndex}
                                      />
                                    )}
                                  </Grid>
                                );
                              })}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Box p={1} component={Paper} sx={{ display: 'flex', justifyContent: 'flex-end', width: 'auto' }}>
                      <Button
                        onClick={handleSubmitCreation}
                        sx={{ mt: 0 }}
                        type="submit"
                        endIcon={createMoovlyLoading ? <div></div> : <Send />}
                        variant="contained"
                        disabled={createMoovlyLoading}>
                        {createMoovlyLoading ? <CircularProgress sx={{ color: '#fff' }} size={21} /> : t('submit')}
                      </Button>
                    </Box>
                  </Grid>
                </div>
              )}
            </Grid>
          )}
        </div>
      )}
      <MoovlyImageSelectorDialog
        open={!!selectedImgIndex}
        closeFn={() => setSelectedImgIndex(null)}
        handleImgSelected={handleImgSelected}></MoovlyImageSelectorDialog>
    </Grid>
  );
};

export default MoovlyCreatorTab;
