import { IRoute } from "../../shared/models/route.model";
import AdvertisementsOverviewAdminTable from "../pages/advertisements/AdvertisementsOverviewAdminTable";

export const ROUTE_ADVERTISEMENTS = Object.freeze({
  AdminAdvertisementsTablePage: "advertisements",
});

export const AdvertisementsRoutes: IRoute[] = [
  {
    path: ROUTE_ADVERTISEMENTS.AdminAdvertisementsTablePage,
    component: AdvertisementsOverviewAdminTable,
  },
];
