import {IBasePaginatedModel} from '../../../shared/models/base.paginated.model';
import {IPaginatedQueryParams} from '../../../shared/models/paginated-query-params';
import {TypeIdsDefinitions, api} from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import {IProductsModel} from "../../../shared/models/product.model";
import { IProductCreateEditFormValues } from '../../pages/products/components/CreateEditProductForm';

const baseUrl = 'admin/product';

export const productsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaginatedProducts: build.query<IBasePaginatedModel<IProductsModel>, IPaginatedQueryParams>({
      query: ({page, pageSize, search}) => ({
        url: baseUrl,
        method: 'GET',
        params: {page, pageSize, search},
      }),
      providesTags: (result) =>
        providesList(
          result?.items ?? [],
          'Products',
          TypeIdsDefinitions.partialListTypeId
        ),
    }),
    addProduct: build.mutation<void, IProductCreateEditFormValues>({
      query: (values: IProductCreateEditFormValues) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['Products'],
    }),
    getProductById: build.query<IProductsModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Products', id: arg.id }],
    }),
    editProduct: build.mutation<void, { values: IProductCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['Products'],
    }),
    getAllProducts: build.query<IProductsModel[], void>({
      query: () => ({
        url: `${baseUrl}/list/all`,
      }),
      providesTags: (result) => providesList(result ?? [], 'Products', TypeIdsDefinitions.listTypeId),
    }),
  }),
});

export const {
  useGetPaginatedProductsQuery,
  useAddProductMutation,
  useGetProductByIdQuery,
  useEditProductMutation,
  useGetAllProductsQuery,
} = productsApi;
