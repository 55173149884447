import { useParams } from 'react-router-dom';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useGetUserProfileQuery, useUpdateUserProfileMutation } from '@/shared/services/api/user-profiles.api';
import UserDetails from '@/shared/components/users/user-profile/UserDetails';
import { useTranslation } from 'react-i18next';

const UserProfilePage: React.FC = (): JSX.Element => {
  const { userId } = useParams();
  const [updateUserProfile, { isSuccess: isSuccess }] = useUpdateUserProfileMutation();
  const { t } = useTranslation();

  const {
    data: profileData,
    isLoading: userProfileLoading,
    isFetching: userProfileLoadingFetching,
  } = useGetUserProfileQuery({ id: userId! }, { skip: !userId });

  const handleSubmit = async (values: FormData) => {
    if (!profileData?.id) {
      return;
    }

    toast.promise(updateUserProfile({ id: userId!, values }), {
      success: t('successfulUpdate'),
      error: t('updateError'),
      loading: t('updating'),
    });
  };

  return (
    <UserDetails
      updateSuccess={isSuccess}
      userProfile={profileData}
      loading={userProfileLoading}
      actionLoading={userProfileLoadingFetching}
      handleSubmitFn={handleSubmit}
    />
  );
};

export default UserProfilePage;
