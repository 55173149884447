import React from 'react';
import { TextField } from '@mui/material';

const CommonTextField: React.FC<any> = (props): JSX.Element => {
  return (
    <TextField
      {...props}
      fullWidth={props.fullWidth ?? true}
      color={props.color ? props.color : 'secondary'}
      variant={props.variant ? props.variant : 'outlined'}
    />
  );
};

export default CommonTextField;
