import { MRT_Row } from 'material-react-table';
import { IOrderOverviewModel } from '@/shared/models/orders/order-overview.model';
import Box from '@mui/material/Box';
import { Button, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CallMissedOutgoing, FacebookOutlined, MoreVert, StackedLineChart } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface IBaseOrderOverviewActionButtonsProps {
  row: MRT_Row<Partial<IOrderOverviewModel> | any>;
}

const BaseOrderOverviewActionButtons: FC<IBaseOrderOverviewActionButtonsProps> = (
  props: IBaseOrderOverviewActionButtonsProps
): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { row } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box sx={{ display: 'flex' }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        <MenuList>
          <MenuItem onClick={() => navigate(`orders/${row.original.id}/details`)}>
            <ListItemIcon>
              <CallMissedOutgoing />
            </ListItemIcon>
            <ListItemText>{t('orderDetails')}</ListItemText>
          </MenuItem>
          <MenuItem onClick={() => navigate(`/statistics/orders/${row.original.id}`)}>
            <ListItemIcon>
              <StackedLineChart />
            </ListItemIcon>
            <ListItemText>Statistikk</ListItemText>
          </MenuItem>
          <Divider />
          {row.original.shareLinks.meta && (
            <MenuItem onClick={() => window.open(row.original.shareLinks.meta, '_blank')}>
              <ListItemIcon>
                <FacebookOutlined />
              </ListItemIcon>
              <ListItemText>Facebook</ListItemText>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};
export default BaseOrderOverviewActionButtons;
