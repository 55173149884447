import { COLORS } from "../../shared/styles/colors";
import { StylesType } from "../../shared/styles/styles";

type SignInStyles =
  | "mainWrapper"
  | "formWrapper"
  | "imageWrapper"
  | "elementsMarginTop";

export const SignInStyles: StylesType<SignInStyles> = {
  mainWrapper: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  formWrapper: {
    width: { xs: "100%", sm: "85%", lg: "50%" },
    padding: "25px",
    border: { xs: `3px solid ${COLORS.turquoise}`, sm: "none" },
    borderRadius: "5px",
  },
  imageWrapper: {
    display: { xs: "flex", sm: "none" },
  },
  elementsMarginTop: {
    marginTop: "15px",
  },
};
