import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { IBudgetItemModel } from '../../../shared/models/budgets/budget-item.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IBudgetItemCreateEditFormValues } from '../../pages/budget-items/components/CreateEditBudgetItemForm';

const baseUrl = 'admin/budget-items';

interface IBudgetBudgetItemsPaginatedQueryParams {
  budgetId: string;
  queryParams: IPaginatedQueryParams;
}

interface IAddBudgetItemAction {
  budgetId: string;
  values: IBudgetItemCreateEditFormValues;
}

export const budgetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBudgetItemsByBudgetId: build.query<
      IBasePaginatedModel<IBudgetItemModel>,
      IBudgetBudgetItemsPaginatedQueryParams
    >({
      query: ({ budgetId, queryParams }) => ({
        url:
          queryParams.search?.length === 0
            ? `admin/budgets/${budgetId}/budget-items?page=${queryParams.page}&pageSize=${queryParams.pageSize}`
            : `admin/budgets/${budgetId}/budget-items?page=${queryParams.page}&pageSize=${queryParams.pageSize}&search=${queryParams.search}`,
      }),
      providesTags: (result) =>
        providesList(result?.items ?? [], 'AdminBudgetItems', TypeIdsDefinitions.partialListTypeId),
    }),
    addBudgetItem: build.mutation<IBudgetItemModel, IAddBudgetItemAction>({
      query: ({ budgetId, values }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: {
          type: values.type,
          budgetId: budgetId,
          spreadBudgetPercentageDefault: values.spreadBudgetPercentageDefault,
        },
      }),
      invalidatesTags: ['AdminBudgetItems'],
    }),
    getBudgetItemById: build.query<IBudgetItemModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'AdminBudgetItems', id: arg.id }],
    }),
    editBudgetItem: build.mutation<IBudgetItemModel, { values: IBudgetItemCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['AdminBudgetItems'],
    }),
    deleteBudgetItem: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdminBudgetItems'],
    }),
  }),
});

export const {
  useAddBudgetItemMutation,
  useDeleteBudgetItemMutation,
  useEditBudgetItemMutation,
  useGetBudgetItemByIdQuery,
  useGetBudgetItemsByBudgetIdQuery,
} = budgetsApi;
