import { api } from '@/shared/services/api/api';

const baseUrl = 'app-init';

export interface IAppInit {
  language: {
    locale: string;
    messages: { [key: string]: string };
  };
}

export const appInitApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAppInit: build.query<IAppInit, void>({
      query: () => `${baseUrl}`,
    }),
  }),
});

export const { useGetAppInitQuery, useLazyGetAppInitQuery } = appInitApi;
