import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import {
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  Box,
  Typography,
  debounce,
  Button,
} from '@mui/material';
import Loader from '@/shared/components/Loader';
import { IMediaLibraryVideoModel } from '@/shared/models/media-library/media-library-video.model';
import VideoListMenu from './VideoListMenu';
import { useUploadVideoMutation } from '@/editor/services/http/media-library.api';
import { useAppSelector } from '@/store/hooks/redux';
import { AddBoxRounded, UploadFileRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface IVideoList {
  isLoadingVideoList: boolean;
  isFetchingVideoList: boolean;
  isUninitialized: boolean;
  videos: IMediaLibraryVideoModel[];
  setView?: Dispatch<SetStateAction<string>>;
  refetch: () => void;
}

const VideoList: React.FC<IVideoList> = ({
  isFetchingVideoList,
  isLoadingVideoList,
  isUninitialized,
  videos,
  setView,
  refetch,
}): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [search, setSearch] = React.useState<string>('');
  const [videoData, setVideoData] = React.useState<IMediaLibraryVideoModel[]>(videos ?? []);
  const [uploadVideo, { isLoading, isSuccess, isError }] = useUploadVideoMutation();
  const propertyInternalId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let vidData: IMediaLibraryVideoModel[] | undefined = search
      ? videos?.filter((el) => el.fileName.toLowerCase().includes(search.trim().toLowerCase()))
      : videos;
    setVideoData(vidData || []);
  }, [videos, search]);

  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const handleUploadClick = () => {
    inputRef?.current?.click();
    handleClose();
  };

  const handleVideoCreatorClick = () => {
    handleClose();
    setView && setView('creator');
  };

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null | undefined = event?.target?.files?.[0];
    if (!file) return;

    const extension = file.name.split('.').pop()?.toLowerCase();
    if (!extension) return;

    await uploadVideo({ propertyId: propertyInternalId, extension, fileName: file.name, file })
      .unwrap()
      .finally(() => {
        refetch();
      });
  };

  const handleRefresh = () => {
    refetch();
    handleClose();
  };

  return (
    <Grid item xs={12}>
      {isLoadingVideoList || isFetchingVideoList ? (
        <Loader />
      ) : (
        <div>
          {!isUninitialized && (
            <Card>
              <CardContent>
                {videoData && videoData?.length > 0 ? (
                  <div>
                    <VideoListMenu
                      handleInputClick={handleUploadClick}
                      handleFileSelected={handleFileSelected}
                      handleSearch={handleSearchChange}
                      isLoading={isLoading}
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      open={open}
                      handleRefresh={handleRefresh}
                      handleVideoCreatorClick={handleVideoCreatorClick}
                      handleClose={handleClose}
                      handleUploadClick={handleUploadClick}
                      inputRef={inputRef}
                      handleSearchChange={handleSearchChange}
                    />
                    <Grid item xs={12}>
                      <List>
                        {videoData.map((el: IMediaLibraryVideoModel, key) => (
                          <ListItem dense={false} divider={true} key={key}>
                            <ListItemAvatar>
                              <video
                                preload="metadata"
                                style={{
                                  width: '100px',
                                }}>
                                <source src={el?.preSignedUrl + '#t=0.1'} type="video/mp4" />
                              </video>
                            </ListItemAvatar>
                            <Box
                              sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <Typography variant="subtitle2">{el.fileName.split('/')[1]}</Typography>
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                  </div>
                ) : (
                  <Grid container item xs={12} display={'flex'} justifyContent={'center'} gap={1}>
                    <Grid item xs={12} mb={3} display={'flex'} justifyContent={'center'}>
                      <Typography variant="h5">{t('noVideosAvailable')}</Typography>
                    </Grid>
                    <Button
                      sx={{
                        ':hover': { cursor: 'pointer', bgcolor: '#000', color: '#fff' },
                      }}
                      variant="outlined"
                      onClick={handleUploadClick}>
                      <UploadFileRounded />
                      Last opp video
                    </Button>
                    <Button
                      sx={{
                        ':hover': { cursor: 'pointer', bgcolor: '#000', color: '#fff' },
                      }}
                      variant="outlined"
                      onClick={handleVideoCreatorClick}>
                      <AddBoxRounded />
                      Lag ny video
                    </Button>
                    <input type="file" accept="video/mp4" hidden ref={inputRef} onInput={handleFileSelected}></input>
                  </Grid>
                )}
              </CardContent>
            </Card>
          )}
        </div>
      )}
    </Grid>
  );
};

export default VideoList;
