import React from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import { useGetAllCompaniesQuery } from '@/admin/services/http/companies.api';
import { useAddOfficeMutation, useEditOfficeMutation, useGetOfficeByIdQuery } from '@/admin/services/http/offices.api';
import CommonTextField from '../../../../shared/components/CommonTextField';

export interface ICreateEditOfficesProps {
  isUpdating: boolean;
}

export interface IOfficeCreateEditFormValues {
  title: string;
  externalId?: string;
  companyId?: string;
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
});

const CreateEdifOfficeForm: React.FC<ICreateEditOfficesProps> = ({ isUpdating }): JSX.Element => {
  const { id } = useParams();
  const [editOffice, { isLoading: editOfficeLoading }] = useEditOfficeMutation();
  const [addOffice, { isLoading: addOfficeLoading }] = useAddOfficeMutation();

  const {
    data: officeData,
    isLoading: officeDataLoading,
    isFetching: officeDataFetching,
  } = useGetOfficeByIdQuery({ id: id ?? '' }, { skip: !isUpdating });

  const {
    data: companiesData,
    isLoading: companiesDataLoading,
    isFetching: companiesDataFetching,
  } = useGetAllCompaniesQuery();

  const navigate = useNavigate();
  const initialValues: IOfficeCreateEditFormValues = {
    title: officeData?.title || '',
    externalId: officeData?.externalId || '',
    companyId: officeData?.companyId || '',
  };

  const handleSubmit = async (values: IOfficeCreateEditFormValues) => {
    try {
      officeData ? await editOffice({ id: id!, values }) : await addOffice(values);
      navigate(-1);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const isLoadingFinished = (): boolean =>
    !officeDataLoading && !officeDataFetching && !companiesDataLoading && !companiesDataFetching;

  return (
    <div>
      {isLoadingFinished() ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IOfficeCreateEditFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                type="text"
                name="title"
                label="Title"
                value={values.title}
                onChange={handleChange}
                required
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <CommonTextField
                sx={{ marginTop: '15px' }}
                type="text"
                name="externalId"
                label="External Id"
                value={values.externalId}
                onChange={handleChange}
                required
                error={Boolean(touched.externalId && errors.externalId)}
                helperText={touched.externalId && errors.externalId}
              />
              <FormControl fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="company_input_label_id">Company</InputLabel>
                <Select
                  name="companyId"
                  labelId="company_input_label_id"
                  id="company_input_id"
                  value={values.companyId}
                  onChange={handleChange}
                  input={<OutlinedInput label="Company"></OutlinedInput>}
                  renderValue={(value: string) => (
                    <Box component="div">{companiesData?.find((el) => el.id === value)?.title}</Box>
                  )}>
                  {companiesData?.map(({ title, id }) => (
                    <MenuItem key={id} value={id}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                sx={{ marginTop: '15px' }}
                type="submit"
                variant="contained"
                disabled={addOfficeLoading || editOfficeLoading}>
                {id ? <Typography>Update</Typography> : <Typography>Submit</Typography>}
              </Button>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEdifOfficeForm;
