import '@/sentry/instrument';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Helmet } from 'react-helmet';
import HelpCrunch from './HelpCrunch';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ProSidebarProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>MakeAds</title>
        </Helmet>
        <App />
        <HelpCrunch />
      </ProSidebarProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
