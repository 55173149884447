import { Stepper, Step, StepLabel, stepConnectorClasses, styled, StepConnector } from '@mui/material';
import Stack from '@mui/material/Stack/Stack';
import { FC } from 'react';
import ColorlibStepIcon from './ColorLibStepIcon';
import { useTranslation } from 'react-i18next';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 14,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(90deg, rgb(255, 167, 55) 31%, rgba(0,0,0,0.45) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: 'linear-gradient(90deg, rgb(219 140 39)  31%,  rgb(255, 167, 55) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const steps = ['Link property', 'Ad settings', 'Create Ad Creatives', 'Confirmation'];

const StepStack: FC<{ activeStep: number }> = ({ activeStep }): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Stack sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
};

export default StepStack;
