import { AdSetFormValuesType, useCreateAdSetsBatchMutation } from '@/editor/services/http/ad-sets.api';
import { useGetAllBudgetsForCustomerQuery } from '@/editor/services/http/budgets.api';
import { CampaignValuesType, useCreateCampaignsMutation } from '@/editor/services/http/campaigns.api';
import { useCreateOrderMutation } from '@/editor/services/http/orders.api';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Loader from '../../../../../shared/components/Loader';
import AdSettingsForm, { IAdSettingsFormValues } from '../../../../shared/components/forms/ad-settings/AdSettingsForm';
import { IBaseStepperComponentPropertiesModel } from '../component-properties.model';
import { useTranslation } from 'react-i18next';
import { setActivePlatforms } from '@/editor/store/reducers/selected-platforms.slice';
import { setAdSetStepValues } from '@/editor/store/reducers/adSet-step.slice';
import { useGetAllProductsForCustomerQuery } from '@/editor/services/http/products.api';

const AdSettingsStep: React.FC<IBaseStepperComponentPropertiesModel> = ({
  handleChangeActiveStep,
  handleBackBtnClicked,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // store selectors
  const platformsState = useAppSelector((state) => state.editorActivePlatformsReducer.slidersState);
  const estateType = useAppSelector((state) => state.propertyInfoSliceReducer.propertyEstateType);

  // block of api calls RTQ
  const { data: budgets, isLoading: budgetsLoading } = useGetAllBudgetsForCustomerQuery({ estateType: estateType });
  const { data: products, isLoading: productsLoading } = useGetAllProductsForCustomerQuery();
  const [createCampaigns, { isLoading: createCampaignsLoading }] = useCreateCampaignsMutation();
  const [createAdSetsBatch, { isLoading: createAdSetsBatchLoading }] = useCreateAdSetsBatchMutation();
  const [createOrder, { isLoading: createOrderLoading }] = useCreateOrderMutation();

  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);

  const { currentData, isError } = useGetRetailPropertyDataQuery(
    { propertyId: propertyExtId ?? '', companyId: companyId },
    { skip: !propertyExtId }
  );

  const [activePlatforms, setComponentActivePlatforms] = useState<PlatformType[]>([]);
  const [platformsBudgets, setPlaformsBudgets] = useState<Record<PlatformType, number>>({
    meta: 0,
    delta: 0,
    snapchat: 0,
    bidtheatre: 0,
    boards: 0,
  });

  useEffect(() => {
    setComponentActivePlatforms(platformsState.filter((el) => el.isActive).map((el) => el.platform));

    const budgetRecord = {} as Record<PlatformType, number>;
    platformsState.forEach((el, key) => {
      budgetRecord[el.platform] = el.value;
    });
    setPlaformsBudgets(budgetRecord);
  }, [platformsState]);

  const formValuesFromStorage: IAdSettingsFormValues = JSON.parse(
    (getValueFromLs(EDITOR_LS_CONSTANTS.ADSET_FORM) as string) ?? null
  );

  const getInitialValues = (): IAdSettingsFormValues => {
    if (formValuesFromStorage !== null) {
      return formValuesFromStorage;
    }

    return {
      title: currentData?.propertyAddress || '',
      places: [],
      startTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment().add(7, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      budgetId: budgets?.[0]?.id || '',
      productId: products?.[0]?.id || '',
      budgetValue: 0,
    };
  };
  const handleCampaignFormSubmitted = async (campaignValues: CampaignValuesType) => {
    const values = activePlatforms.map((_) => campaignValues);
    const res = await createCampaigns({
      platforms: activePlatforms,
      values,
    }).unwrap();

    const strToSave = JSON.stringify(res.map((el) => ({ id: el.id, platform: el.platform })));
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATED_CAMPAIGNS, strToSave);
  };

  const handleAdSetFormSubmitted = async (
    values: Pick<AdSetFormValuesType, 'title' | 'places' | 'startTime' | 'endTime'>
  ) => {
    const requestBody = activePlatforms.map((el) => ({
      ...values,
      platform: el,
      startTime: moment(values.startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(values.endTime).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      spreadingBudgetInPercentage: +platformsBudgets[el as PlatformType],
      locations: values.places?.map((place) => ({
        address: place.address,
        latitude: place.center.lat,
        longitude: place.center.lng,
        radius: place.radius,
      })),
    }));

    const res = await createAdSetsBatch({
      platforms: activePlatforms,
      values: requestBody,
    }).unwrap();

    const strToSave = JSON.stringify(res.map((el) => ({ id: el.id, platform: el.platform })));
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATED_AD_SETS, strToSave);
  };

  const handleOrderSubmitted = async (formikValues: IAdSettingsFormValues) => {
    const orderTitle = getValueFromLs(EDITOR_LS_CONSTANTS.ORDER_TITLE);
    const propertyId = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);

    const selectedBudget = budgets?.find((el) => el.id === formikValues.budgetId);
    let productIdForApi = formikValues.productId;
    let budgetIdForApi = formikValues.budgetId;
    let budgetValue = formikValues.budgetValue
    if (selectedBudget?.budgetItems) {
      productIdForApi = null;
      budgetValue = 0
    }
    else {
      budgetIdForApi = null;
    }

    try {
      const res = await createOrder({
        budgetId: budgetIdForApi,
        productId: productIdForApi!,
        title: orderTitle!,
        propertyId: propertyId!,
        budgetValue: budgetValue!,
      }).unwrap();

      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.ORDER_INTERNAL_ID, res.id);
    } catch (e) {
      console.error(e);
    }
  };

  const handleAllForms = async (formikValues: IAdSettingsFormValues) => {
    if (!activePlatforms || activePlatforms.length === 0) return;
    dispatch(setActivePlatforms(activePlatforms));
    if (
      Object.values(platformsBudgets).some((el) => el === 0) ||
      activePlatforms.map((el) => platformsBudgets[el as PlatformType]).reduce((acc, el) => acc + Number(el), 0) > 100
    ) {
      toast.error(t('Check budget'));
      return;
    }
    const { startTime, endTime, title, places, budgetValue } = formikValues;

    if (products && products.length > 0 && budgetValue) {
      if (budgetValue < (products[0].minimum) || budgetValue > (products[0].maximum)) {
        toast.error(t('Check spend Value'));
        return;
      }
    }

    const allFormsSubmission = new Promise(async (resolve, reject) => {
      try {
        await handleOrderSubmitted(formikValues);
        await handleCampaignFormSubmitted({ title });
        await handleAdSetFormSubmitted({ title, places, startTime, endTime });
        resolve('OK');
      } catch (err) {
        reject(err);
      }
    });

    try {
      await toast.promise(allFormsSubmission, {
        loading: t('Settings creation'),
        success: t('Settings created'),
        error: t('Settings error'),
      });
      handleChangeActiveStep(2);
    } catch (err) {
      return;
    }
  };

  return (
    <div>
      {(budgetsLoading || productsLoading) ? (
        <Loader />
      ) : (
        <div>
          <AdSettingsForm
            initialValues={getInitialValues()}
            budgets={budgets!}
            products={products!}
            onSubmitForm={handleAllForms}
            handleBackBtnClicked={handleBackBtnClicked}
            actionLoading={createCampaignsLoading || createOrderLoading || createAdSetsBatchLoading}
          />
        </div>
      )}
    </div>
  );
};

export default AdSettingsStep;
