import { IBasePaginatedModel } from '@/shared/models/base.paginated.model';
import { IPaginatedQueryParams } from '@/shared/models/paginated-query-params';
import { IRetailDataProvider } from '@/shared/models/retail-data-provider.model';
import { TypeIdsDefinitions, api } from '@/shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IProviderCreateEditFormValues } from '../../pages/retail-providers/components/CreteEditProviderForm';

const baseUrl = 'admin/retail-providers';

export const retailDataProvidersApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllProviders: build.query<IRetailDataProvider[], void>({
      query: () => ({
        url: `${baseUrl}/list/all`,
      }),
      providesTags: (result) => providesList(result ?? [], 'RetailDataProviders', TypeIdsDefinitions.listTypeId),
    }),
    getProviderTypes: build.query<string[], void>({
      query: () => ({
        url: `${baseUrl}/list/types`,
      }),
      // providesTags: (result) =>
      //   providesList(
      //     result ?? [],
      //     "RetailDataProviders",
      //     TypeIdsDefinitions.listTypeId
      //   ),
    }),
    getProvidersPaginated: build.query<IBasePaginatedModel<IRetailDataProvider>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search }) => ({
        url: `${baseUrl}`,
        params: { page, pageSize, search },
      }),
      providesTags: (result) =>
        providesList(result?.items ?? [], 'RetailDataProviders', TypeIdsDefinitions.partialListTypeId),
    }),
    addProvider: build.mutation<void, IProviderCreateEditFormValues>({
      query: (values: IProviderCreateEditFormValues) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: [
        {
          type: 'RetailDataProviders',
          id: TypeIdsDefinitions.partialListTypeId,
        },
      ],
    }),
    getProviderById: build.query<IRetailDataProvider, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'RetailDataProviders', id: arg.id }],
    }),
    editProvider: build.mutation<void, { values: IProviderCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['RetailDataProviders'],
    }),
    deleteProvider: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RetailDataProviders'],
    }),
  }),
});

export const {
  useGetAllProvidersQuery,
  useGetProviderTypesQuery,
  useGetProvidersPaginatedQuery,
  useAddProviderMutation,
  useEditProviderMutation,
  useGetProviderByIdQuery,
  useDeleteProviderMutation,
} = retailDataProvidersApi;
