import { Box } from '@mui/material';
import React from 'react';
import { useAppSelector } from '../../../../../store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';

const SingleSnapChatPreview: React.FC = (): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState.snapchat.single?.media);

  return (
    <Box sx={{ width: '100%' }}>
      {media?.preSignedUrl ? (
        <img
          style={{ width: '100%', height: '100%', minHeight: '450px', objectFit: 'cover' }}
          src={media?.preSignedUrl}
          alt="preview"></img>
      ) : (
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default SingleSnapChatPreview;
