import { IRoleModel } from "../../../shared/models/role.model";
import { api } from "../../../shared/services/api/api";

const baseUrl = "admin/roles";

export const rolesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllRoles: builder.query<IRoleModel[], void>({
      query: () => ({
        url: baseUrl,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: "Roles", id } as const)),
              { type: "Roles", id: "LIST" },
            ]
          : [{ type: "Roles", id: "LIST" }],
    }),
  }),
});

export const { useGetAllRolesQuery } = rolesApi;
