import { FunctionComponent } from 'react';
import {
  AutoCreationConfigType,
  IAutoCreationConfigModel,
} from '../../../../shared/models/companies/autocreation/auto-creation-config.model';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import CommonButton from '../../../../shared/components/CommonButton';
import CommonTextField from '../../../../shared/components/CommonTextField';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import {
  useCreateConfigMutation,
  useUpdateConfigMutation,
} from '../../../services/http/companies-auto-creation-configs.api';
import { useNavigate, useParams } from 'react-router-dom';
import { capitalize } from 'lodash';

interface IAddEditAutoCreationConfigFormProps {
  initialValues: IAutoCreationConfigModel | null;
}

export interface IAddEditAutoCreationConfigFormValues {
  title: string | null;
  description: string | null;
  message: string | null;
  caption: string | null;
  link: string | null;
  isDeltaActive: boolean;
  isMetaActive: boolean;
  isSnapchatActive: boolean;
  isBidtheatreActive: boolean;
  type: AutoCreationConfigType;
}

const types = ['regular', 'after_sold'];

const AddEditAutoCreationConfigForm: FunctionComponent<IAddEditAutoCreationConfigFormProps> = (
  props: IAddEditAutoCreationConfigFormProps
): JSX.Element => {
  const navigate = useNavigate();
  const { autoCreationId, id } = useParams();
  const { initialValues } = props;
  const [creteConfig, { isLoading: creationLoading }] = useCreateConfigMutation();
  const [editConfig, { isLoading: updatingLoading }] = useUpdateConfigMutation();

  const { getFieldProps, touched, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      title: initialValues?.title || '',
      description: initialValues?.description || '',
      message: initialValues?.message || '',
      caption: initialValues?.caption || '',
      link: initialValues?.link || '',
      isDeltaActive: initialValues?.isDeltaActive || false,
      isMetaActive: initialValues?.isMetaActive || false,
      isSnapchatActive: initialValues?.isSnapchatActive || false,
      isBidtheatreActive: initialValues?.isBidtheatreActive || false,
      type: initialValues?.type || 'regular',
    },
    validationSchema: yup.object().shape({
      title: yup.string(),
      description: yup.string(),
      message: yup.string(),
      caption: yup.string(),
      link: yup.string(),
      isDeltaActive: yup.boolean().required('Is delta active flag is required'),
      isMetaActive: yup.boolean().required('Is meta active flag is required'),
      isSnapchatActive: yup.boolean().required('Is snapchat active flag is required'),
      isBidtheatreActive: yup.boolean().required('Is bidtheatre active flag is required'),
      type: yup.string().required('Type is required'),
    }),
    onSubmit: async (values: IAddEditAutoCreationConfigFormValues) => {
      try {
        initialValues
          ? await editConfig({ values, id: autoCreationId! }).unwrap()
          : await creteConfig({ values, companyId: id! }).unwrap();
      } catch (e) {
        return;
      }

      toast.success(initialValues ? 'Successfully Updated' : 'Successfully Created');
      navigate(-1);
    },
  });

  return (
    <div>
      <form noValidate onSubmit={(e) => handleSubmit(e)}>
        <CommonTextField
          type="text"
          {...getFieldProps('title')}
          label="Title"
          onChange={handleChange}
          required
          error={Boolean(touched.title && errors.title)}
          helperText={touched.title && (errors?.title as string)}
        />
        <CommonTextField
          sx={{ mt: '15px' }}
          type="text"
          {...getFieldProps('description')}
          label="Description"
          required
          error={Boolean(touched.description && errors.description)}
          helperText={touched.description && (errors?.description as string)}
        />
        <CommonTextField
          sx={{ mt: '15px' }}
          type="text"
          {...getFieldProps('message')}
          label="Message"
          required
          error={Boolean(touched.message && errors.message)}
          helperText={touched.message && (errors?.message as string)}
        />
        <CommonTextField
          sx={{ mt: '15px' }}
          type="text"
          {...getFieldProps('caption')}
          label="Caption"
          required
          error={Boolean(touched.caption && errors.caption)}
          helperText={touched.caption && (errors?.caption as string)}
        />
        <CommonTextField
          sx={{ mt: '15px' }}
          type="text"
          {...getFieldProps('link')}
          label="Link"
          required
          error={Boolean(touched.link && errors.link)}
          helperText={touched.link && (errors?.link as string)}
        />
        <FormControl fullWidth sx={{ marginTop: '15px' }}>
          <InputLabel id="type_input_label_id">BudgetType</InputLabel>
          <Select
            labelId="type_input_label_id"
            id="type_input_id"
            {...getFieldProps('type')}
            onChange={handleChange}
            input={<OutlinedInput label="Type"></OutlinedInput>}
            renderValue={(value: string) => <Box component="div">{capitalize(value)}</Box>}>
            {types?.map((el) => (
              <MenuItem key={el} value={el}>
                {capitalize(el)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormGroup>
          <FormControlLabel
            {...getFieldProps('isDeltaActive')}
            control={<Checkbox checked={getFieldProps('isDeltaActive').value} />}
            label="Is delta active"
          />
          <FormControlLabel
            {...getFieldProps('isMetaActive')}
            control={<Checkbox checked={getFieldProps('isMetaActive').value} />}
            label="Is meta active"
          />
          <FormControlLabel
            {...getFieldProps('isSnapchatActive')}
            control={<Checkbox checked={getFieldProps('isSnapchatActive').value} />}
            label="Is snapchat active"
          />
          <FormControlLabel
            {...getFieldProps('isBidtheatreActive')}
            control={<Checkbox checked={getFieldProps('isBidtheatreActive').value} />}
            label="Is bidtheatre active"
          />
        </FormGroup>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              mt: 2,
            }}>
            <CommonButton disabled={creationLoading || updatingLoading} type="submit">
              {initialValues ? 'Update' : 'Create'}
            </CommonButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
export default AddEditAutoCreationConfigForm;
