import { FC, useState } from 'react';
import OverviewOrdersTableProvider from '@/shared/components/order-overviews-tables/OverviewOrdersTable';
import { useGetApplicationAdminPaginatedOrdersQuery } from '@/editor/services/http/orders.api';
import AdminOrderOverviewActionButtons from '@/admin/pages/main-page/AdminOrderOverviewActionButtons';
import ExportDialog from '@/shared/components/order-overviews-tables/ExportDialog';
import { useTranslation } from 'react-i18next';

const AdminOrdersOverviewTable: FC = (): JSX.Element => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const { t } = useTranslation();

  const { data, isError, isFetching, isLoading, refetch } = useGetApplicationAdminPaginatedOrdersQuery({
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
    search,
  });

  const handleClickExportBtn = () => {
    setExportModalOpen(true);
  };

  return (
    <div>
      <ExportDialog
        exportUrl="admin/orders/exports/excel"
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
      />
      <OverviewOrdersTableProvider
        data={data ?? null}
        isError={isError}
        refetchFn={refetch}
        isLoading={isLoading}
        isFetching={isFetching}
        tableDescription={t('applicationOrders')}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        actionButtonsRenderFn={AdminOrderOverviewActionButtons}
        exportBtnClick={handleClickExportBtn}
      />
    </div>
  );
};

export default AdminOrdersOverviewTable;
