import { IOfficeCreateEditFormValues } from '@/admin/pages/offices/components/CreateEditOfficeForm';
import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { IOfficeModel } from '../../../shared/models/offices/office.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { ICompanyCreateEditFormValues } from '../../pages/companies/components/CreateEditCompanyForm';
import { IProviderCreateEditFormValues } from '../../pages/retail-providers/components/CreteEditProviderForm';

const baseUrl = 'admin/offices';

export const officesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getOfficesAdmin: build.query<IBasePaginatedModel<IOfficeModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search }) => ({
        url: `${baseUrl}`,
        params: { page, pageSize, search },
      }),
      providesTags: (result) => providesList(result?.items ?? [], 'Offices', TypeIdsDefinitions.partialListTypeId),
    }),
    getAllOfficesAdmin: build.query<IOfficeModel[], void>({
      query: () => ({
        url: `${baseUrl}/list/all`,
        method: 'GET',
      }),
      providesTags: (result) => providesList(result ?? [], 'Offices', TypeIdsDefinitions.listTypeId),
    }),
    addOffice: build.mutation<void, IOfficeCreateEditFormValues>({
      query: (values: IOfficeCreateEditFormValues) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: [
        {
          type: 'Offices',
          id: TypeIdsDefinitions.partialListTypeId,
        },
      ],
    }),
    getOfficeById: build.query<IOfficeModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Offices', id: arg.id }],
    }),
    editOffice: build.mutation<void, { values: IProviderCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['Offices'],
    }),
  }),
});

export const {
  useGetAllOfficesAdminQuery,
  useAddOfficeMutation,
  useEditOfficeMutation,
  useGetOfficeByIdQuery,
  useGetOfficesAdminQuery,
} = officesApi;
