import React from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { Box } from '@mui/material';
import BoardsPreviewResolver from './BoardsPreviewResolver';
const BoardsPreviewSummary: React.FC<{
  values: IBasicCreativeFormValues;
  externalCompanyId: string;
}> = ({ values, externalCompanyId }): JSX.Element => {
  return (
    <Box maxWidth="600px">
      <BoardsPreviewResolver
        caption={values.caption!}
        companyExternalId={externalCompanyId}
        employeeEmail={values.employeeEmail}
        employeeAvatar={values.employeeAvatar}
        employeeName={values.employeeName}
        employeePhone={values.employeePhone}
      />
    </Box>
  );
};
export default BoardsPreviewSummary;
