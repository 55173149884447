import { Box } from '@mui/material';
import { useCallback } from 'react';
import { useAppSelector } from '@/store/hooks/redux';
import CreativeTypesEnum from '../../shared/constants/creatives-types.enum';
import MediaLibraryImages from './images/MediaLibraryImages';
import MediaLibraryVideosWrapper from './videos/MediaLibraryVideosWrapper';

const MediaLibraryWrapper: React.FC = (): JSX.Element => {
  const drawerState = useAppSelector((state) => state.editorModalsDrawersReducer.mediaLibraryDrawerState);

  const resolveComponent = useCallback(() => {
    switch (drawerState.creativeType) {
      case CreativeTypesEnum.VIDEO:
        return <MediaLibraryVideosWrapper />;
      case CreativeTypesEnum.CAROUSEL:
      case CreativeTypesEnum.DYNAMIC:
      case CreativeTypesEnum.SINGLE:
        return <MediaLibraryImages></MediaLibraryImages>;
    }
  }, [drawerState.creativeType]);

  return <Box>{resolveComponent()}</Box>;
};

export default MediaLibraryWrapper;
