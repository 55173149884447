import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppSelector } from '@/store/hooks/redux';
import { useGetMediaLibraryImagesQuery } from '@/editor/services/http/media-library.api';
import { PlatformType } from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '../../../../../shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import {
  initiateCreativeFormsState,
  setCreativeFormValueForPlatform,
  setCreativeItemsOnCarousel,
  setInitialized,
  setMediaOnDynamic,
  setMediaOnSingle,
  setMediaOnVideo,
} from '@/editor/store/reducers/creative-step.slice';
import { useAppDispatch } from '@/store/hooks/redux';
import { AvailablePlatformsEnum, platformsToCropImage } from '@/editor/shared/constants/available-platforms';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import moment from 'moment';

interface FilteredImages {
  platform: AvailablePlatformsEnum;
  images: IMediaLibraryImageModel[];
}

const usePrepareCreatives = () => {
  const dispatch = useAppDispatch();
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);
  const { selectedCreativeType, creativeFormsState } = useAppSelector((state) => state.creativeStepSliceReducer);

  let activePlatforms = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string);

  // const activePlatforms = useAppSelector((state) => state.editorActivePlatformsReducer.activePlatforms);

  // api get request to get images from media library
  const { data: mediaLibraryImageData, isLoading: mediaLibraryImagesLoading } = useGetMediaLibraryImagesQuery(
    { propertyId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID)! },
    { skip: !getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID) }
  );

  const { currentData, isLoading, isFetching, refetch, isUninitialized, isSuccess } = useGetRetailPropertyDataQuery(
    { propertyId: propertyExtId ?? '', companyId: companyId ?? '' },
    { skip: !propertyExtId }
  );

  const initializeForm = () => {
    if (isSuccess) {
      let propertyViewDate = currentData?.propertyViewDate?.date
        ? moment(currentData?.propertyViewDate?.date).format('YYYY-MM-DD H')
        : '';

      dispatch(
        initiateCreativeFormsState({
          initialValues: {
            headline: currentData?.propertyDescription || '',
            caption: currentData?.propertyAddress || '',
            description: currentData?.propertyHeading || '',
            link: currentData?.propertyLink || '',
            ownershipType: currentData?.buildingOwnershipType || '',
            areaInUsage: currentData?.propertySquareMeters ? currentData?.propertySquareMeters : '',
            viewDate: propertyViewDate,
            stateDebt: currentData?.propertyDebt ? parseInt(currentData?.propertyDebt.replace(/ /g, '')) : '',
            priceQuote: currentData?.propertyPrice ? parseInt(currentData?.propertyPrice) : '',
            propertyEstateType: currentData?.propertyEstateType || '',
            bidStateDebt: currentData?.bidPropertyDebt || '',
            bidPriceQuote: currentData?.bidPropertyPrice || '',
            propertyDescription2: currentData?.propertyDescription2 || '',
            nativeAd: currentData?.nativeAd,
            employeeName: currentData?.employeeName || '',
            employeeEmail: currentData?.employeeEmail || '',
            employeePhone: currentData?.employeePhone || '',
            employeeAvatar: currentData?.employeeAvatar || '',
          },
        })
      );
    }
  };

  useEffect(() => {
    if (firstRender) {
      initializeForm();

      const filteredByRequirements: FilteredImages[] = platformsToCropImage.map((requirements) => ({
        platform: requirements.platform,
        images:
          mediaLibraryImageData?.filter(
            (image) => image.width === requirements.width && image.height === requirements.height
          ) ?? [],
      }));

      // Try to find parent/source of cropped images, so selection will be all the same on each platform.
      // Ex. Snapchat -> 3 cropped, Meta - will have 3 original images of snapchat cropped images etc. So uncropped(source) -> cropped
      const firstImagesFromFiltered = filteredByRequirements.find((el) => el.images.length > 0)?.images;
      const sources: string[] = firstImagesFromFiltered?.filter((el) => el.sourceId).map((el) => el.sourceId!) ?? [];
      const imagesToChoose: IMediaLibraryImageModel[] = [];
      for (const source of sources) {
        imagesToChoose.push(mediaLibraryImageData?.find((el) => el.id === source)!);
      }

      for (const platformId of activePlatforms) {
        const typedPlatform = platformId.toLowerCase() as PlatformType;
        let appropriateImages = filteredByRequirements.find((el) => el.platform === typedPlatform)?.images;

        if (!appropriateImages?.length) {
          appropriateImages = imagesToChoose?.length > 0 ? imagesToChoose : mediaLibraryImageData;
        }

        if (!appropriateImages?.length) return;

        if (
          typedPlatform === AvailablePlatformsEnum.DELTA ||
          typedPlatform === AvailablePlatformsEnum.BIDTHEATRE ||
          typedPlatform === AvailablePlatformsEnum.BOARDS
        ) {
          dispatch(setMediaOnSingle({ media: appropriateImages![0], platform: typedPlatform }));
          continue;
        }

        switch (selectedCreativeType) {
          case CreativeTypesEnum.SINGLE:
            dispatch(setMediaOnSingle({ media: appropriateImages[0]!, platform: typedPlatform }));
            break;
          case CreativeTypesEnum.DYNAMIC:
            dispatch(
              setMediaOnDynamic({
                platform: typedPlatform,
                creativeMedias: appropriateImages!.slice(0, 3),
              })
            );
            break;
          case CreativeTypesEnum.CAROUSEL:
            const { caption, description, link } = creativeFormsState[typedPlatform].carousel!;
            dispatch(
              setCreativeItemsOnCarousel({
                platform: typedPlatform,
                creativeItems: [0, 1, 2].map((i) => ({
                  headline: caption?.slice(0, 34) || '',
                  description: description?.slice(0, 90) || '',
                  link: link?.slice(0, 90) || '',
                  creativeMedia: appropriateImages![i],
                })),
              })
            );
            break;
        }
      }

      setFirstRender(false);
      dispatch(setInitialized(true));
      return;
    }
  }, [mediaLibraryImageData, dispatch, firstRender, selectedCreativeType]);

  return {
    selectedCreativeType,
    creativeFormsState,
    mediaLibraryImageData,
    mediaLibraryImagesLoading,
  };
};

export default usePrepareCreatives;
