import React from 'react';
import { languagesApi as LanguageApi } from '@/admin/services/http/languages.api';
import { Box, Typography } from '@mui/material';
import LanguageEditorContainer from '@/admin/pages/languages/components/LanguageEditorContainer';

const AdminLanguagePage: React.FC = (): JSX.Element => {
  const { data: languagesData } = LanguageApi.useGetAllLanguagesQuery();

  return (
    <Box>
      <Typography color="secondary" variant="h4" sx={{ mb: '10px' }}>
        Language List
      </Typography>
      <LanguageEditorContainer languageData={languagesData} />
    </Box>
  );
};

export default AdminLanguagePage;
