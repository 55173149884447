import React, { useCallback } from 'react';
import CreativeTypesEnum, { CreativeType } from '../../../../../editor/shared/constants/creatives-types.enum';
import { useAppSelector } from '@/store/hooks/redux';
import BasicMetaPreview from './BasicMetaPreview';
import FacebookSinglePreview from './FacebookSinglePreview';
import MetaCarouselPreview from './MetaCarouselPreview';
import MetaDynamicPreview from './MetaDynamicPreview';
import { Box, Grid } from '@mui/material';
import MetaVideoPreview from '@/shared/components/platform-previews/previews/meta/MetaVideoPreview';

const MetaPreviewSummary: React.FC<{ selectedType: CreativeType }> = ({ selectedType }): JSX.Element => {
  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);

  const resolveCurrentPreview = useCallback(() => {
    switch (selectedType) {
      case CreativeTypesEnum.SINGLE:
        return <FacebookSinglePreview />;
      case CreativeTypesEnum.CAROUSEL:
        return <MetaCarouselPreview items={creativeFormsState['meta']['carousel'].creativeItems ?? []} />;
      case CreativeTypesEnum.DYNAMIC:
        return (
          <MetaDynamicPreview
            selectedImage={creativeFormsState['meta']['dynamic'].creativeMedias[0].preSignedUrl ?? ''}
          />
        );
      case 'video':
        return <MetaVideoPreview />;
    }
  }, [selectedType, creativeFormsState]);

  return (
    <Grid container item>
      <Grid item xs={12}>
        <BasicMetaPreview
          headline={creativeFormsState.meta[selectedType]?.headline}
          caption={creativeFormsState.meta[selectedType]?.caption}
          description={creativeFormsState.meta[selectedType]?.description}
          currentPreview={resolveCurrentPreview()!}
          showFooter={selectedType !== CreativeTypesEnum.CAROUSEL}
        />
      </Grid>
    </Grid>
  );
};

export default MetaPreviewSummary;
