import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { IDataTableColumn } from '@/shared/components/DataTable';
import {  useGetPaginatedProductsQuery } from '../../services/http/products.api';
import { ROUTE_PRODUCTS_DECLARATIONS } from '../../routes/products-routes';
import { IProductsModel } from '@/shared/models/product.model';
import { debounce } from '@/shared/hooks/debounce';
import { COLORS } from '@/shared/styles/colors';
import { useTranslation } from 'react-i18next';
import { Delete, Edit} from '@mui/icons-material';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'title',
    name: 'Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'minimum',
    name: 'Minimum',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'maximum',
    name: 'Maximum',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'commission',
    name: 'Commission',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'status',
    name: 'Status',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const ProductDataTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading, isFetching } = useGetPaginatedProductsQuery({
    page,
    pageSize,
    search,
  });
  const { t } = useTranslation();

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  // const [deleteProduct, { isLoading: deleteProductLoading }] = useDeleteProductMutation();

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircleIcon />,
        action: () =>
          navigate(
            `${ROUTES_DECLARATIONS.AdminPanel}/${ROUTE_PRODUCTS_DECLARATIONS.AdminProductsNewPage}`
          ),
      },
    ];
  }, []);

  const mapProducts = (data?: IProductsModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          title: el.title,
          minimum: el.minimum,
          maximum: el.maximum,
          commission: el.commission,
          status: el.status ? 'Active' : 'Inactive',
          createdAt: el.createdAt,
          actions: [
            {
              title: t('items'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/products/${el.id}/product-items`),
            },
            {
              title: t('edit'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/products/${el.id}/edit`),
            },
            {
              title: t('delete'),
              icon: <Delete />,
              // action: () => deleteProduct({ id: el.id }),
              color: COLORS.delete,
            },
          ],
        }))
      : [];
  };

  return (
    <div>
      <AdminTableWrapper
        header={t('products')}
        isLoading={isLoading || isFetching}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapProducts(data?.items as IProductsModel[]),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default ProductDataTable;
