import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/system';
import React from 'react';
import CommonButton from './CommonButton';
import { useTranslation } from 'react-i18next';

export interface IDataTableColumn {
  id: string;
  name: string;
  enableSort?: boolean;
  align?: 'center' | 'inherit' | 'justify' | 'left' | 'right';
}

export interface IDataTableHeadProps {
  columns: IDataTableColumn[];
  order: Order;
  orderBy: keyof any;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof any) => void;
}

export type PaginationOfTableChanges = (value: number) => void;
export type SearchChanges = (value: string) => void;

export interface IGeneralTableProps {
  rows: any[];
  columnData?: IDataTableColumn[];
  totalRows: number;
  handlePageChange: PaginationOfTableChanges;
  handlePageSizeChange: PaginationOfTableChanges;
  handleSearch?: SearchChanges;
}

function descendingComparator<T, K extends keyof T>(a: T, b: T, orderBy: K) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';
type ComparableElement<T extends keyof any> = {
  [key in T]: number | string | Array<any>;
};

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: ComparableElement<Key>, b: ComparableElement<Key>) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const DataTableHead: React.FC<IDataTableHeadProps> = ({ columns, order, orderBy, onRequestSort }): JSX.Element => {
  const createSortHandler = (property: keyof any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, index) => (
          <TableCell key={index} align="left" sortDirection={orderBy === column.id ? order : false}>
            {column.enableSort ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}>
                {column.name}
              </TableSortLabel>
            ) : (
              column.name
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DataTable: React.FC<IGeneralTableProps> = ({
  columnData,
  rows,
  totalRows,
  handlePageChange,
  handlePageSizeChange,
}): JSX.Element => {
  let internalColumnData: IDataTableColumn[] = [
    {
      id: '',
      name: '',
      align: 'inherit',
      enableSort: false,
    },
  ];
  if (!columnData) {
    if (rows.length) {
      internalColumnData.length = 0;
      Object.keys(rows[0]).map((key) => {
        internalColumnData.push({
          id: String(key),
          name: String(key),
          align: 'inherit',
          enableSort: false,
        });
      });
    }
  } else {
    internalColumnData = columnData;
  }

  // const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof any>('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const { t } = useTranslation();

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof any) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    setPage(0);
    if (handlePageSizeChange) {
      handlePageSizeChange(rowsPerPage);
    }
  };

  return (
    <div>
      {rows.length > 0 ? (
        <Paper>
          <TableContainer>
            <Table aria-labelledby="tableTitle" aria-label="enhanced table">
              <DataTableHead
                columns={internalColumnData}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy)).map((row, key) => {
                  return (
                    <TableRow key={key}>
                      {Object.keys(row).map((key, index) => (
                        <TableCell
                          align={internalColumnData[index]?.align ? internalColumnData[index]?.align : 'inherit'}
                          width={`${100 / internalColumnData.length}%`}
                          key={key}>
                          {key === 'actions' ? (
                            <Box
                              component="div"
                              sx={{
                                display: 'flex',
                                gap: '10px',
                              }}>
                              {(row[key] as Array<any>).map((el, index) => {
                                return (
                                  <CommonButton
                                    key={index}
                                    onClick={el.action}
                                    startIcon={el.icon ? el.icon : null}
                                    sx={{ backgroundColor: el.color ?? '' }}>
                                    {el.title}
                                  </CommonButton>
                                );
                              })}
                            </Box>
                          ) : (
                            <div>{row[key] ? <div>{row[key]}</div> : <div>No data</div>}</div>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t('rowsPerPage')}
          />
        </Paper>
      ) : (
        <Box
          component="div"
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Typography variant="h4">No data</Typography>
        </Box>
      )}
    </div>
  );
};

export default DataTable;
