import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IMediaLibraryDrawerState {
  isOpened: boolean;
  creativeType: string | null;
  carouselItemIndex?: number;
  platform: PlatformType | null;
}

export interface ILeaveAdCreationStepsState {
  isOpened: boolean;
  pathNavigateTo: string | null;
}

export interface ICropperImageState {
  isOpened: boolean;
  image: IMediaLibraryImageModel | null;
  platform: string | null;
  propertyInternalId: string | null;
  widthToCrop: number;
  heightToCrop: number;
}

interface IEditorModalsDrawersState {
  mediaLibraryDrawerState: IMediaLibraryDrawerState;
  leaveAdCreationSteps: ILeaveAdCreationStepsState;
  finishingCreationOfAd: boolean;
  imageCropper: ICropperImageState;
}

const initialState: IEditorModalsDrawersState = {
  mediaLibraryDrawerState: { isOpened: false, creativeType: null, platform: null },
  leaveAdCreationSteps: { isOpened: false, pathNavigateTo: null },
  finishingCreationOfAd: false,
  imageCropper: {
    isOpened: false,
    image: null,
    platform: null,
    propertyInternalId: null,
    widthToCrop: 0,
    heightToCrop: 0,
  },
};

const editorModalsDrawersSlice = createSlice({
  name: 'editor-modals-drawers',
  initialState,
  reducers: {
    setMediaLibraryDrawerState(state: IEditorModalsDrawersState, action: PayloadAction<IMediaLibraryDrawerState>) {
      state.mediaLibraryDrawerState = action.payload;
    },
    setLeaveAdCreationSteps(state: IEditorModalsDrawersState, action: PayloadAction<ILeaveAdCreationStepsState>) {
      state.leaveAdCreationSteps = action.payload;
    },
    setFinishingCreationOfAd(state: IEditorModalsDrawersState, action: PayloadAction<boolean>) {
      state.finishingCreationOfAd = action.payload;
    },
    setImageCropper(state: IEditorModalsDrawersState, action: PayloadAction<ICropperImageState>) {
      state.imageCropper = action.payload;
    },
  },
});

export const { setMediaLibraryDrawerState, setLeaveAdCreationSteps, setFinishingCreationOfAd, setImageCropper } =
  editorModalsDrawersSlice.actions;

export default editorModalsDrawersSlice.reducer;
