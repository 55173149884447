import { IRoute } from "../../shared/models/route.model";
import CreateEditProductPage from "../pages/products/CreateEditProductPage";
import ProductsDataTable from "../pages/products/ProductTablePage";

export const ROUTE_PRODUCTS_DECLARATIONS = Object.freeze({
  AdminProductsPage: "products",
  AdminProductsNewPage: "products/new",
  AdminProductsEditPage: "products/:id/edit",
});

export const AdminProductsRoutes: IRoute[] = [
  {
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsPage,
    component: ProductsDataTable,
  },
  {
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsNewPage,
    component: CreateEditProductPage,
  },
  {
    path: ROUTE_PRODUCTS_DECLARATIONS.AdminProductsEditPage,
    component: CreateEditProductPage,
  },
];
