import { setPropertyExtId, setCompanyId } from '@/editor/store/reducers/property-info.slice';
import { generateId } from '@/shared/services/helpers/random-string.helper';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import DownloadIcon from '@mui/icons-material/Download';
import { Button, InputAdornment, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { deleteKeyValuePairFromLS, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useGetRetailPropertyDataQuery } from '@/editor/services/http/retail-data-provider.api';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { IEditorOrderFormPropertiesBaseModel } from '@/editor/shared/components/forms/editor-order-form-properties-base.model';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
export interface IRegisterCreateEditFormValues {
  address: string;
  propertyId: string;
}
export interface IRegisterEditPropertyFormProps
  extends IEditorOrderFormPropertiesBaseModel<IRegisterCreateEditFormValues> {
  loading: boolean;
  handleSubmit: (values: IRegisterCreateEditFormValues) => Promise<void>;
}

const validationSchema = yup.object().shape({
  address: yup.string().required('Address is required'),
  propertyId: yup.string().required('Property id is required'),
});

const RegisterEditPropertyForm: React.FC<IRegisterEditPropertyFormProps> = ({ handleSubmit, loading }): JSX.Element => {
  const dispatch = useAppDispatch();
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);

  const { currentData, isError } = useGetRetailPropertyDataQuery(
    { propertyId: propertyExtId ?? '', companyId: companyId },
    { skip: !propertyExtId }
  );

  const { t } = useTranslation();

  useEffect(() => {
    if (isError) {
      toast.error('Unknonw property id');
      deleteKeyValuePairFromLS(EDITOR_LS_CONSTANTS.PROPERTY_EXTERNAL_ID);
      dispatch(setPropertyExtId(''));
    }
  }, [isError, dispatch]);

  const initialValues: IRegisterCreateEditFormValues = {
    address: currentData?.propertyAddress || '',
    propertyId: currentData?.propertyId || generateId(20),
  };

  const handleClickOnDownloadIcon = async (id: string) => {
    dispatch(setPropertyExtId(id));
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_EXTERNAL_ID, id);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await handleSubmit(values);
        setSubmitting(false);
      }}>
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values, getFieldMeta }) => {
        return (
          <form onSubmit={(e) => handleSubmit(e)}>
            <CommonTextField
              name="propertyId"
              label={t('generatedPropertyId')}
              required
              value={values.propertyId}
              onChange={handleChange}
              error={Boolean(touched.propertyId && errors.propertyId)}
              helperText={touched.propertyId && errors.propertyId}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    onClick={() => handleClickOnDownloadIcon(getFieldMeta('propertyId').value as string)}
                    position="end"
                    sx={{ cursor: 'pointer' }}>
                    <Tooltip title={t('downloadIdTooltip')} placement="right">
                      <DownloadIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <CommonTextField
              sx={{ mt: 2 }}
              name="address"
              label={t('address')}
              required
              value={values.address}
              onChange={handleChange}
              error={Boolean(touched.address && errors.address)}
              helperText={touched.address && errors.address}
            />
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: '16px', float: 'right' }}
              disabled={isSubmitting || loading}>
              {t('register')}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
export default RegisterEditPropertyForm;
