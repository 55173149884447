import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { debounce } from 'lodash';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { IAdvertisementOverviewAdminModel } from '@/shared/models/advertisements/admin/advertisement-overview-admin.model';
import {
  useGetPaginatedAdvertisementsAdminQuery,
  usePutAdvertisementInProgressStatusMutation,
} from '@/admin/services/http/advertisements.api';
import { useTranslation } from 'react-i18next';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'platform',
    name: 'Platform',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'internalStatus',
    name: 'Internal status',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'orderId',
    name: 'Order id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'orderTitle',
    name: 'Order title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'campaignId',
    name: 'Campaign id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'creativeId',
    name: 'Creative id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'adSetId',
    name: 'Adset id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'adReferenceId',
    name: 'Ad reference id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Created at',
    enableSort: false,
    align: 'left',
  },
];

const AdvertisementsOverviewAdminTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { t } = useTranslation();

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  const { data, isLoading } = useGetPaginatedAdvertisementsAdminQuery({
    page,
    pageSize,
    search,
  });

  const [putAdToInProgressStatus] = usePutAdvertisementInProgressStatusMutation();

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const getActions = (el: IAdvertisementOverviewAdminModel) => {
    if (el.internalStatus === 'error') {
      return [
        {
          title: 'Recreate',
          action: () => putAdToInProgressStatus({ id: el.id }),
        },
      ];
    }

    return [];
  };

  const map = (data?: IAdvertisementOverviewAdminModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          platform: el.platform,
          internalStatus: el.internalStatus,
          orderId: el.orderId,
          orderTitle: el.orderTitle,
          campaignId: el.campaignId,
          creativeId: el.creativeId,
          adSetId: el.adSetId,
          adReferenceId: el.adReferenceId,
          createdAt: el.createdAt,
          actions: getActions(el),
        }))
      : [];
  };

  return (
    <div>
      <AdminTableWrapper
        isLoading={isLoading}
        header={t('advertisements')}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: map(data?.items ?? []),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: [],
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default AdvertisementsOverviewAdminTable;
