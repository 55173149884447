import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useAppSelector } from '../../../../../../store/hooks/redux';
import { PlatformType } from '../../../../constants/available-platforms';

const useDetectCarouselItemChanges = (platform: PlatformType) => {
  const formikCtx = useFormikContext();

  const carouselReduxForm = useAppSelector(
    (state) => state.creativeStepSliceReducer.creativeFormsState[platform].carousel
  );

  const mediaIdsString = carouselReduxForm?.creativeItems.map((el) => el.creativeMedia?.id)?.join('');

  useEffect(() => {
    formikCtx.setFieldValue('creativeItems', carouselReduxForm?.creativeItems);
  }, [mediaIdsString]);
};

export default useDetectCarouselItemChanges;
