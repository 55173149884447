import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import {
  useAddProviderMutation,
  useEditProviderMutation,
  useGetProviderByIdQuery,
  useGetProviderTypesQuery,
} from '@/admin/services/http/retail-data-provider.api';
import CommonTextField from '../../../../shared/components/CommonTextField';
import { useTranslation } from 'react-i18next';
import CommonButton from '@/shared/components/CommonButton';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Send } from '@mui/icons-material';

export interface ICreateEditProviderProps {
  isUpdating: boolean;
}

export interface IProviderCreateEditFormValues {
  title: string;
  fetchPropertyDataUrl?: string;
  updatePropertyDataUrl?: string;
  fetchUserDataUrl?: string;
  updateUserDataUrl?: string;
  type?: string;
}

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
});

const CreateEditProviderForm: React.FC<ICreateEditProviderProps> = ({ isUpdating }): JSX.Element => {
  const { providerId } = useParams();
  const { t } = useTranslation();

  const { data: providerTypes } = useGetProviderTypesQuery();

  const {
    data: providerData,
    isLoading: providerDataLoading,
    isFetching: providerDataFetching,
  } = useGetProviderByIdQuery({ id: providerId ?? '' }, { skip: !isUpdating });

  const [addProvider, { isLoading: addProviderLoading }] = useAddProviderMutation();
  const [editProvider, { isLoading: editProviderLoading }] = useEditProviderMutation();

  const navigate = useNavigate();
  const initialValues: IProviderCreateEditFormValues = {
    title: providerData?.title || '',
    fetchPropertyDataUrl: providerData?.fetchPropertyDataUrl || '',
    updatePropertyDataUrl: providerData?.updatePropertyDataUrl || '',
    fetchUserDataUrl: providerData?.fetchUserDataUrl || '',
    updateUserDataUrl: providerData?.updateUserDataUrl || '',
    type: providerData?.type || '',
  };

  const handleSubmit = async (values: IProviderCreateEditFormValues) => {
    if (providerData) {
      try {
        let res = await editProvider({ id: providerId!, values }).unwrap();
        navigate(-1);
        return;
      } catch (e) {
        return;
      }
    }

    try {
      let res = await addProvider(values);
      navigate(-1);
    } catch (e) {
      return;
    }
  };

  return (
    <div>
      {!providerDataLoading && !providerDataFetching ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: IProviderCreateEditFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                type="text"
                name="title"
                label={t('title')}
                value={values.title}
                onChange={handleChange}
                required
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <CommonTextField
                sx={{ mt: 2 }}
                type="text"
                name="fetchPropertyDataUrl"
                label={t('fetchPropertyDataUrl')}
                value={values.fetchPropertyDataUrl}
                onChange={handleChange}
                required
                error={Boolean(touched.fetchPropertyDataUrl && errors.fetchPropertyDataUrl)}
                helperText={touched.fetchPropertyDataUrl && errors.fetchPropertyDataUrl}
              />
              <CommonTextField
                sx={{ mt: 2 }}
                type="text"
                name="updatePropertyDataUrl"
                label={t('updatePropertyDataUrl')}
                value={values.updatePropertyDataUrl}
                onChange={handleChange}
                required
                error={Boolean(touched.updatePropertyDataUrl && errors.updatePropertyDataUrl)}
                helperText={touched.updatePropertyDataUrl && errors.updatePropertyDataUrl}
              />
              <FormControl sx={{ mt: 2 }} fullWidth>
                <InputLabel id="provider-type-label">Provider Type</InputLabel>
                <Select
                  name="type"
                  labelId="provider-type-label"
                  id="property-type"
                  value={values.type}
                  label={t('providerType')}
                  onChange={handleChange}>
                  {providerTypes?.map((value, key) => (
                    <MenuItem key={key} value={value}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <CommonTextField
                sx={{ mt: 2 }}
                type="text"
                name="fetchUserDataUrl"
                label={t('fetchUserDataUrl')}
                value={values.fetchUserDataUrl}
                onChange={handleChange}
                required
                error={Boolean(touched.fetchUserDataUrl && errors.fetchUserDataUrl)}
                helperText={touched.fetchUserDataUrl && errors.fetchUserDataUrl}
              />
              <CommonTextField
                sx={{ mt: 2 }}
                type="text"
                name="updateUserDataUrl"
                label={t('updateUserDataUrl')}
                value={values.updateUserDataUrl}
                onChange={handleChange}
                required
                error={Boolean(touched.updateUserDataUrl && errors.updateUserDataUrl)}
                helperText={touched.updateUserDataUrl && errors.updateUserDataUrl}
              />
              <CommonButton
                sx={{ mt: 2 }}
                type="submit"
                startIcon={providerId ? <RefreshIcon /> : <Send />}
                variant="contained"
                disabled={addProviderLoading || editProviderLoading}>
                {providerId ? <Typography>{t('update')}</Typography> : <Typography>{t('submit')}</Typography>}
              </CommonButton>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEditProviderForm;
