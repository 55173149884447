import React from 'react';
import CreateEditUserForm from './components/CreateEditUserForm';
import { Grid, Paper } from '@mui/material';
import { useParams } from 'react-router-dom';
import EditUserPasswordForm from './components/EditUserPasswordForm';

const CreateEditUser = (): JSX.Element => {
  const { userId } = useParams();

  return (
    <div>
      <Grid container justifyContent={'center'}>
        <Grid item xs={8}>
          <Paper sx={{ p: 2 }}>
            <CreateEditUserForm isUpdating={!!userId}></CreateEditUserForm>
          </Paper>
        </Grid>

        {!!userId && (
          <Grid mt={2} item xs={8}>
            <Paper sx={{ p: 2 }}>
              <EditUserPasswordForm isUpdating={!!userId}></EditUserPasswordForm>
            </Paper>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default CreateEditUser;
