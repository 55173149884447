import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { ISnapChatStatsModel } from '../../../../../../shared/models/advertisements/statistics/snapchat-stats.model';
import { NumberValuesFromObject } from '../../../../../../shared/types/types';

interface ISnapChatSummaryStats {
  snapchatStats: ISnapChatStatsModel;
}

const SnapChatSummaryStats: FC<ISnapChatSummaryStats> = (props: ISnapChatSummaryStats): JSX.Element => {
  const { snapchatStats } = props;

  const values: NumberValuesFromObject<ISnapChatStatsModel, 'impressions' | 'swipes'> = {
    impressions: snapchatStats.impressions,
    swipes: snapchatStats.swipes,
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="meta stats table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Impressions</TableCell>
            <TableCell align="left">Swipes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            {Object.values(values).map((val, index) => (
              <TableCell key={index} align="left">
                {val ? val.toFixed(2) : 0}
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SnapChatSummaryStats;
