import { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { useGetCompanyBrandingQuery } from '../../../services/http/company-branding.api';
import CompanyBrandingTitleForm from './CompanyBrandingTitleForm';
import Loader from '../../../../shared/components/Loader';
import CompanyBrandingFileUploader from './CompanyBrandingFileUploader';
import CompanyBrandingPlatformFiles from './CompanyBrandingPlatformFiles';

const CompanyBranding: FunctionComponent = (): JSX.Element => {
  const { id } = useParams();
  const { data, isLoading } = useGetCompanyBrandingQuery({ id: id! });

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <CompanyBrandingFileUploader brandingId={data?.id!} brandingFile={data?.brandingFile ?? null} />
          <CompanyBrandingTitleForm initialValues={data!}></CompanyBrandingTitleForm>
          <CompanyBrandingPlatformFiles
            brandingId={data?.id!}
            brandingPlatformFiles={data?.brandingPlatformFiles ?? []}></CompanyBrandingPlatformFiles>
        </div>
      )}
    </div>
  );
};

export default CompanyBranding;
