import { api } from '../../../shared/services/api/api';
import { IPropertyModel } from '../../../shared/models/property.model';
import { IRegisterCreateEditFormValues } from '../../pages/create-ads-steps-page/components/register-property-step/RegisterEditPropertyForm';

const baseUrl = 'properties';

export const customerPropertiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    registerProperty: build.mutation<IPropertyModel, IRegisterCreateEditFormValues>({
      query: ({ propertyId, address }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: { propertyId, address },
      }),
      invalidatesTags: ['Properties'],
    }),
  }),
});

export const { useRegisterPropertyMutation } = customerPropertiesApi;
