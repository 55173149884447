import { IRoute } from '@/shared/models/route.model';
import UserGuard from '../guards/UserGuard';
import EditorLayout from '../layout/EditorLayout';
import MainPage from '../pages/main-page/MainPage';
import CreateAdsStepsPage from '../pages/create-ads-steps-page/CreateAdsStepsPage';
import OrderDetailsPage from '../pages/orders/details/OrderDetailsPage';
import UserProfilePage from '../pages/user-profile/UserProfilePage';
import VideoLibrary from '../pages/video-library/VideoLibrary';

export const editorRoutes: IRoute[] = [
  {
    path: '/editor',
    component: EditorLayout,
    guard: UserGuard,
    children: [
      {
        path: '',
        component: MainPage,
      },
      {
        path: 'advertise/add',
        component: CreateAdsStepsPage,
      },
      {
        path: 'orders/:id/details',
        component: OrderDetailsPage,
      },
      {
        path: 'users/:userId/user-profile',
        component: UserProfilePage,
      },
      {
        path: 'videoLibrary',
        component: VideoLibrary,
      },
    ],
  },
];
