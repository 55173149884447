import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-hot-toast';
import { clearLS } from '../localstorage';

// Create instance
const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('accessToken');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

axiosInstance.interceptors.response.use(
  function (response: AxiosResponse<any>) {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      clearLS();
      window.location.href = '/sign-in';
      toast.error('Your session has expired. Please sign in again.');
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
