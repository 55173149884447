import React, { FC, useEffect, useState } from 'react';
import OverviewOrdersTableProvider from '@/shared/components/order-overviews-tables/OverviewOrdersTable';
import { useGetCompanyAdminPaginatedOrdersQuery } from '@/editor/services/http/orders.api';
import BaseOrderOverviewActionButtons from '@/shared/components/order-overviews-tables/BaseOrderOverviewActionButtons';
import ExportDialog from '@/shared/components/order-overviews-tables/ExportDialog';

const OfficeAdminOrderOverviewTable: FC = (): JSX.Element => {
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [columnFilters, setColumnFilters] = useState([]);
  const [search, setSearch] = useState('');
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const { data, isError, isFetching, isLoading, refetch, isUninitialized } = useGetCompanyAdminPaginatedOrdersQuery({
    page: pagination.pageIndex,
    pageSize: pagination.pageSize,
    filters: JSON.stringify(columnFilters ?? []),
    sorting: JSON.stringify(sorting ?? []),
    search,
  });

  useEffect(() => {
    if (!isUninitialized) refetch();
  }, [isUninitialized]);

  const handleClickExportBtn = () => {
    setExportModalOpen(true);
  };

  return (
    <div>
      <ExportDialog
        exportUrl="orders/exports/excel:companyAdmin"
        exportModalOpen={exportModalOpen}
        setExportModalOpen={setExportModalOpen}
      />
      <OverviewOrdersTableProvider
        data={data ?? null}
        isError={isError}
        refetchFn={refetch}
        isLoading={isLoading}
        isFetching={isFetching}
        tableDescription={'Company orders'}
        pagination={pagination}
        setPagination={setPagination}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        sorting={sorting}
        setSorting={setSorting}
        search={search}
        setSearch={setSearch}
        actionButtonsRenderFn={BaseOrderOverviewActionButtons}
        exportBtnClick={handleClickExportBtn}
      />
    </div>
  );
};

export default OfficeAdminOrderOverviewTable;
