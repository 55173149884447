import { IRoute } from "../../shared/models/route.model";
import BudgetsItemsDataTable from "../pages/budget-items/BudgetsItemsTablePage";
import CreateEditBudgetItemPage from "../pages/budget-items/CreateEditBudgetItemPage";

export const ROUTE_BUDGETS_ITEMS_DECLARATIONS = Object.freeze({
  AdminBudgetsItemsByCompanyPage: "budgets/:budgetId/budget-items",
  AdminBudgetsItemsNewPage: "budgets/:budgetId/budget-items/new",
  AdminBudgetsItemsEditPage: "budget-items/:budgetItemId/edit",
});

export const BudgetsItemsRoutes: IRoute[] = [
  {
    path: ROUTE_BUDGETS_ITEMS_DECLARATIONS.AdminBudgetsItemsByCompanyPage,
    component: BudgetsItemsDataTable,
  },
  {
    path: ROUTE_BUDGETS_ITEMS_DECLARATIONS.AdminBudgetsItemsNewPage,
    component: CreateEditBudgetItemPage,
  },
  {
    path: ROUTE_BUDGETS_ITEMS_DECLARATIONS.AdminBudgetsItemsEditPage,
    component: CreateEditBudgetItemPage,
  },
];
