import { IAdvertisementBatchCreationResultModel } from '../../../shared/models/advertisements/advertisement-batch-creation-result.model';
import { api } from '../../../shared/services/api/api';
import { IAdvertisementCompleteValues } from '../../pages/create-ads-steps-page/components/advertisement-step/AdvertisementStep';

const baseUrl = 'advertisements';

export const customerAdvertisementsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAdvertisementsBatch: build.mutation<IAdvertisementBatchCreationResultModel, IAdvertisementCompleteValues[]>({
      query: (ads) => ({
        url: `${baseUrl}/batch`,
        method: 'POST',
        body: {
          items: ads,
        },
      }),
      invalidatesTags: ['CustomerAdvertisements'],
    }),
    generateSharedLinkMeta: build.mutation<string, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}/actions:generateShareLink`,
        method: 'POST',
      }),
    }),
    deleteSharedLinkMeta: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}/actions:deleteShareLink`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CustomersOrders'],
    }),
    stopAdvertisement: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}/actions:stop`,
        method: 'PUT',
      }),
      invalidatesTags: ['CustomersOrders'],
    }),
    activateAdvertisement: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}/actions:activate`,
        method: 'PUT',
      }),
      invalidatesTags: ['CustomersOrders'],
    }),
  }),
});

export const {
  useCreateAdvertisementsBatchMutation,
  useGenerateSharedLinkMetaMutation,
  useStopAdvertisementMutation,
  useActivateAdvertisementMutation,
  useDeleteSharedLinkMetaMutation,
} = customerAdvertisementsApi;
