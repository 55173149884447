import {
  AdvertisementInternalStatus,
  IFullAdvertisementModel,
} from '@/shared/models/advertisements/full-advertisement-model';
import { Chip, ListItem, ListItemText, Tooltip } from '@mui/material';
import { capitalize } from 'lodash';
import { FC, useEffect, useState } from 'react';

interface IStatusBadgeProps {
  advertisement: IFullAdvertisementModel;
}

type BadgeColor = { [k in AdvertisementInternalStatus]: string };

const colors: BadgeColor = {
  finished: '#FFF2CC',
  error: '#FF6969',
  active: '#DDFFBB',
  pending: '#FEFF86',
  paused: '#EAE0DA',
};

const StatusBadge: FC<IStatusBadgeProps> = (props: IStatusBadgeProps): JSX.Element => {
  const { advertisement } = props;

  return (
    <div>
      <ListItem>
        <ListItemText id="status_badge" primary="Status" />
        <Chip
          sx={{ backgroundColor: colors[advertisement.internalStatus] }}
          label={capitalize(advertisement.internalStatus)}
        />
      </ListItem>
    </div>
  );
};

export default StatusBadge;
