import React from 'react';
import Paper from '@mui/material/Paper';
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import LoopIcon from '@mui/icons-material/Loop';
import { ILanguageDefault } from '@/admin/services/http/languages.api';

interface ILanguageEditorPresentationalProps {
  availableLanguages: ILanguageDefault[];
  selectedLanguage: string;
  selectedTranslatedLanguage: string;
  handleChangeSourceLanguage: (event: SelectChangeEvent) => void;
  handleChangeTranslatedLanguage: (event: SelectChangeEvent) => void;
  handleLanguageSwitch: () => void;
  handleTranslations: (word: string) => string;
}

const LanguageEditorPresentational: React.FC<ILanguageEditorPresentationalProps> = ({
  availableLanguages,
  selectedLanguage,
  selectedTranslatedLanguage,
  handleChangeSourceLanguage,
  handleChangeTranslatedLanguage,
  handleLanguageSwitch,
  handleTranslations,
}): JSX.Element => {
  return (
    <div>
      <Box component={Paper} sx={{ display: 'flex', alignItems: 'center', mb: '16px', minHeight: '80px' }}>
        <FormControl sx={{ margin: '0 16px', width: '200px' }}>
          <InputLabel id="demo-simple-select-label">{handleTranslations('sourceLanguage')}</InputLabel>
          <Select
            labelId="source-language-label"
            id="source-language"
            value={selectedLanguage}
            label=" sourceLanguage"
            onChange={handleChangeSourceLanguage}>
            {availableLanguages.map((el) => {
              return (
                <MenuItem key={el.locale} value={el.locale}>
                  {el.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <IconButton onClick={handleLanguageSwitch}>
          <LoopIcon />
        </IconButton>

        <FormControl sx={{ margin: '0 16px', width: '200px' }}>
          <InputLabel id="demo-simple-select-label">{handleTranslations('translatedLanguage')}</InputLabel>
          <Select
            labelId="translated-language-label"
            id="translated-language"
            value={selectedTranslatedLanguage}
            label=" translatedLanguage"
            onChange={handleChangeTranslatedLanguage}>
            {availableLanguages.map((el) => {
              return (
                <MenuItem key={el.locale} value={el.locale}>
                  {el.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default LanguageEditorPresentational;
