import {
  Box,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FunctionComponent, useEffect, useState } from 'react';
import * as yup from 'yup';
import CommonButton from '../../../../shared/components/CommonButton';
import CommonTextField from '../../../../shared/components/CommonTextField';
import { useUploadBrandingFilePlatformMutation } from '../../../services/http/company-branding.api';
import { capitalize } from 'lodash';

interface ICompanyBrandingPlatformFileModalProps {
  file: File;
  brandingId: string;
  deselectFile: () => void;
}

export interface ICompanyBrandingPlatformFileModalFormValues {
  platform: string;
  width: number;
  height: number;
}

const platforms = ['snapchat', 'meta', 'delta'];

const CompanyBrandingPlatformFileModal: FunctionComponent<ICompanyBrandingPlatformFileModalProps> = (
  props: ICompanyBrandingPlatformFileModalProps
): JSX.Element => {
  const { file, deselectFile, brandingId } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>();
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [opened, setOpened] = useState<boolean>(false);

  const [uploadFile, { isLoading: uploadFileLoading }] = useUploadBrandingFilePlatformMutation();

  useEffect(() => {
    setSelectedFile(file);
    const url = URL.createObjectURL(file);
    setFileUrl(url);
    setOpened(true);

    return () => {
      setFileUrl(null);
      setSelectedFile(null);
    };
  }, [file]);

  const handleClose = () => {
    setOpened(false);
    deselectFile();
  };

  const { getFieldProps, touched, errors, handleSubmit, handleChange } = useFormik({
    initialValues: {
      platform: 'snapchat',
      width: 1,
      height: 1,
    }!,
    validationSchema: yup.object().shape({
      platform: yup.string().oneOf(platforms).required('Platform is required'),
      width: yup.number().min(1, 'Width should be min 1').required('Width is required'),
      height: yup.number().min(1, 'Height should be min 1').required('Height is required'),
    }),
    onSubmit: async (values: ICompanyBrandingPlatformFileModalFormValues) => {
      const formData = new FormData();
      formData.append('file', selectedFile!);
      formData.append('height', `${values.height}`);
      formData.append('width', `${values.width}`);
      formData.append('platform', values.platform);
      await uploadFile({ id: brandingId, data: formData }).unwrap();
      handleClose();
    },
  });

  return (
    <Dialog open={opened} onClose={handleClose}>
      <DialogContent>
        <Box>
          <img style={{ width: '100%', maxHeight: '300px', borderRadius: '5px' }} src={fileUrl!} alt="selected img" />
        </Box>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <CommonTextField
            sx={{ mt: 2 }}
            type="number"
            {...getFieldProps('width')}
            label="Width"
            onChange={handleChange}
            required
            error={Boolean(touched.width && errors.width)}
            helperText={touched.width && (errors?.width as string)}
          />
          <CommonTextField
            type="number"
            sx={{ mt: 2 }}
            {...getFieldProps('height')}
            label="Height"
            onChange={handleChange}
            required
            error={Boolean(touched.height && errors.height)}
            helperText={touched.height && (errors?.height as string)}
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="type_input_label_id">Platform</InputLabel>
            <Select
              labelId="type_input_label_id"
              id="type_input_id"
              {...getFieldProps('platform')}
              onChange={handleChange}
              input={<OutlinedInput label="Platform"></OutlinedInput>}
              renderValue={(value: string) => (
                <Typography width="100%">{capitalize(value)}</Typography>
              )}>
              {platforms?.map((el) => (
                <MenuItem key={el} value={el}>
                  {capitalize(el)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid container mt={2}>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <CommonButton type="submit" disabled={uploadFileLoading}>
                Resize
              </CommonButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CompanyBrandingPlatformFileModal;
