const ExternalCompanyIdEnum = Object.freeze({
  EIE: '264',
  EIE_VISMA: '47', // TODO: check with production
  SORMEGLEREN: 'MSSORM',
  PROACTIV: '284',
  BRORBY: '?',
  SMG:'MDAtMXwwMDAwMDAwMjMwM3wyODQ.',
  MSPECS:'MDAtMXwwMDAwMDAwMjQ0N3wyODQ.',
  FREMEIENDOMSMEGLING:'340'
});

export default ExternalCompanyIdEnum;