import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { ICompanyModel } from '../../../shared/models/companies/company.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { ICompanyCreateEditFormValues } from '../../pages/companies/components/CreateEditCompanyForm';
import { IProviderCreateEditFormValues } from '../../pages/retail-providers/components/CreteEditProviderForm';

const baseUrl = 'admin/companies';

export const companiesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllCompanies: build.query<ICompanyModel[], void>({
      query: () => ({
        url: `${baseUrl}/list/all`,
      }),
      providesTags: (result) => providesList(result ?? [], 'Companies', TypeIdsDefinitions.listTypeId),
    }),
    getCompaniesAdmin: build.query<IBasePaginatedModel<ICompanyModel>, IPaginatedQueryParams>({
      query: ({ page, pageSize, search }) => ({
        url: `${baseUrl}`,
        params: {page, pageSize, search},
      }),
      providesTags: (result) => providesList(result?.items ?? [], 'Companies', TypeIdsDefinitions.partialListTypeId),
    }),
    addCompany: build.mutation<void, ICompanyCreateEditFormValues>({
      query: (values: ICompanyCreateEditFormValues) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['Companies'],
    }),
    getCompanyById: build.query<ICompanyModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'Companies', id: arg.id }],
    }),
    editCompany: build.mutation<void, { values: IProviderCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['Companies'],
    }),
    deleteCompany: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Companies'],
    }),
  }),
});

export const {
  useGetAllCompaniesQuery,
  useAddCompanyMutation,
  useDeleteCompanyMutation,
  useEditCompanyMutation,
  useGetCompaniesAdminQuery,
  useGetCompanyByIdQuery,
} = companiesApi;
