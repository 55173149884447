import { Box, Typography } from '@mui/material';
import { memo } from 'react';
import ImagesUploader from './images/ImagesUploader';
import VideosUploader from './videos/VideosUploader';

type ConcreteUploaderType = 'image' | 'video';

export interface IRefetchAction {
  refetchAction: () => void;
  propertyId?: string;
}

export interface IMediaUploaderProps extends IRefetchAction {
  concreteUploaderType?: ConcreteUploaderType;
}

const MediaUploader: React.FC<IMediaUploaderProps> = ({ concreteUploaderType, refetchAction }): JSX.Element => {
  return (
    <div>
      <Box>
        {concreteUploaderType === 'image' ? (
          <ImagesUploader refetchAction={refetchAction} />
        ) : (
          <VideosUploader refetchAction={refetchAction} />
        )}
      </Box>
      {/* <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          border: '1px dashed #aaaaaa',
          padding: '15px',
          height: '100px',
        }}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}>
          <Box>
            <Typography>Last opp bilder</Typography>
          </Box>
          
        </Box>
      </Box> */}
    </div>
  );
};

export default memo(MediaUploader);
