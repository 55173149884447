import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { debounce } from '@/shared/hooks/debounce';
import { useGetPaginatedApplicationLogsQuery } from '../../services/http/application-logs.api';
import { IApplicationLogsModel } from '@/shared/models/application-logs.model';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'exceptionMessage',
    name: 'Exception message',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'advertisementId',
    name: 'Advertisement Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
];

const BudgetsItemsDataTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');

  const { data, isLoading } = useGetPaginatedApplicationLogsQuery({
    page,
    pageSize,
    search,
  });

  const navigate = useNavigate();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const map = (data?: IApplicationLogsModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          exceptionMessage: el.exceptionMessage + '. ' + el.additionalData?.['context'] ?? '',
          advertisementId: el.advertisementId,
          createdAt: el.createdAt,
          // actions: [
          //   {
          //     title: 'Details',
          //     action: () => alert(JSON.stringify(el.additionalData)),
          //   },
          // ],
        }))
      : [];
  };

  return (
    <div>
      <AdminTableWrapper
        header="Application Logs"
        isLoading={isLoading}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: map(data?.items),
          totalRows: data?.totalCount!,
          handlePageChange: (e) => setPage(e),
          handlePageSizeChange: (e) => setPageSize(e),
        }}
        wrapperHeaderActions={{
          actions: [],
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default BudgetsItemsDataTable;
