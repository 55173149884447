import { IRoute } from "../../shared/models/route.model";
import CreateEditProductItemPage from "../pages/product-items/CreateEditProductItemPage";
import ProductsItemsDataTable from "../pages/product-items/ProductsItemsTablePage";

export const ROUTE_PRODUCTS_ITEMS_DECLARATIONS = Object.freeze({
  AdminProductsItemsByCompanyPage: "products/:productId/product-items",
  AdminProductsItemsNewPage: "products/:productId/product-items/new",
  AdminProductsItemsEditPage: "product-items/:productItemId/edit",
});

export const ProductsItemsRoutes: IRoute[] = [
  {
    path: ROUTE_PRODUCTS_ITEMS_DECLARATIONS.AdminProductsItemsByCompanyPage,
    component: ProductsItemsDataTable,
  },
  {
    path: ROUTE_PRODUCTS_ITEMS_DECLARATIONS.AdminProductsItemsNewPage,
    component: CreateEditProductItemPage,
  },
  {
    path: ROUTE_PRODUCTS_ITEMS_DECLARATIONS.AdminProductsItemsEditPage,
    component: CreateEditProductItemPage,
  },
];
