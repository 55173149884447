import React from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import AdminBaseFormWrapper from '../../shared/components/forms/AdminBaseFormWrapper';
import AddEditAutoCreationConfigForm from './components/AddEditAutoCreationConfigForm';
import { useGetSingleAutoCreationSettingsByIdQuery } from '../../services/http/companies-auto-creation-configs.api';
import Loader from '../../../shared/components/Loader';

const AddEditAutoCreationConfigPage = (): JSX.Element => {
  const { autoCreationId } = useParams();
  const { data, isLoading } = useGetSingleAutoCreationSettingsByIdQuery(
    { id: autoCreationId! },
    { skip: !autoCreationId }
  );

  return (
    <div>
      <AdminBaseFormWrapper>
        <Grid item xs={6}>
          {isLoading ? (
            <Loader />
          ) : (
            <AddEditAutoCreationConfigForm initialValues={data ?? null}></AddEditAutoCreationConfigForm>
          )}
        </Grid>
      </AdminBaseFormWrapper>
    </div>
  );
};

export default AddEditAutoCreationConfigPage;
