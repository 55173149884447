import React from 'react';
import { Grid } from '@mui/material';
import OrdersTableWrapper from './components/OrdersTableWrapper';

const MainPage: React.FC = (): JSX.Element => {
  return (
    <Grid container sx={{ p: 4 }}>
      <Grid item xs={12}>
        <OrdersTableWrapper />
      </Grid>
    </Grid>
  );
};

export default MainPage;
