import { Divider, Typography } from '@mui/material';
import React from 'react';
import DataTable, { IGeneralTableProps } from '../../../../shared/components/DataTable';
import { Box } from '@mui/system';
import Loader from '../../../../shared/components/Loader';
import CommonTextField from '../../../../shared/components/CommonTextField';
import CommonButton from '../../../../shared/components/CommonButton';
import { useTranslation } from 'react-i18next';

type IAction = () => void;

interface IAdminTableWrapperProps {
  header: string;
  isLoading: boolean;
  tableData: IGeneralTableProps;
  wrapperHeaderActions?: IHeaderActions;
}

export interface IHeaderAction {
  action: IAction;
  title?: string;
  icon?: JSX.Element;
}

interface IHeaderActions {
  actions: IHeaderAction[];
  search?: (value: string) => void;
}

const AdminTableWrapper = ({
  header,
  isLoading,
  tableData,
  wrapperHeaderActions,
}: IAdminTableWrapperProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box component="div" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Box component="div" sx={{ display: 'flex', alignItems: 'baseline' }}>
          <Typography variant="h5">{header}</Typography>
          {wrapperHeaderActions &&
            wrapperHeaderActions.actions?.map((el, index) => {
              return (
                <CommonButton startIcon={el.icon} sx={{ ml: 3 }} key={index} onClick={() => el.action()}>
                  {el.title}
                </CommonButton>
              );
            })}
        </Box>

        <Box component="div" sx={{ display: 'flex' }}>
          {wrapperHeaderActions && wrapperHeaderActions.search && (
            <CommonTextField
              label={t('search')}
              sx={{ marginRight: '10px' }}
              variant="standard"
              onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (wrapperHeaderActions.search) {
                  wrapperHeaderActions?.search(e.target.value);
                }
              }}></CommonTextField>
          )}
        </Box>
      </Box>
      <Divider sx={{ marginBottom: '20px' }} />
      {isLoading ? (
        <Loader />
      ) : (
        <DataTable
          columnData={tableData.columnData}
          rows={tableData.rows}
          totalRows={tableData.totalRows}
          handlePageChange={(numb) => {
            tableData.handlePageChange(numb);
          }}
          handlePageSizeChange={tableData.handlePageSizeChange}
        />
      )}
    </Box>
  );
};

export default AdminTableWrapper;
