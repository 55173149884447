import React, { useEffect } from 'react';
import { AvailablePlatformsEnum, PlatformType } from '../../../constants/available-platforms';
import DeltaFormsResolver from './delta/DeltaFormsResolver';
import MetaFormsResolver from './meta/MetaFormsResolver';
import SnapChatFormsResolver from './snapchat/SnapChatFormsResolver';
import FacebookIcon from '@mui/icons-material/Facebook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SnapChatIcon from '@/shared/icons/SnapChatIcon';
import { capitalize } from 'lodash';
import { Avatar, Button, ButtonGroup, Card, Grid } from '@mui/material';
import BidtheatreFormsResolver from './bidtheatre/BidtheatreFormsResolver';
import { useTranslation } from 'react-i18next';
import BoardsFormsResolver from './boards/BoardsFormsResolver';

export interface ICreativesTypeResolverProps {
  availablePlatforms: PlatformType[];
  creativeType: string;
  updating?: boolean;
  handleUpdate?: (platform: PlatformType, values: any) => Promise<void>;
}

const CreativesTypeResolver: React.FC<ICreativesTypeResolverProps> = ({
  availablePlatforms,
  creativeType,
  updating = false,
}): JSX.Element => {
  const [value, setValue] = React.useState('');

  useEffect(() => {
    if (availablePlatforms.length > 0) {
      setValue(availablePlatforms[0]);
    }
  }, []);

  const handleChange = (newValue: PlatformType): void => {
    setValue(newValue);
  };

  const { t } = useTranslation();
  return (
    <Grid component={Card} container sx={{ bgcolor: 'background.paper', display: 'flex' }}>
      <Grid item xs={12} md={2} sx={{ borderRight: '1px solid #EEEEEE' }}>
        <ButtonGroup sx={{ width: '100%' }} orientation="vertical" aria-label="vertical outlined button group">
          {availablePlatforms.includes(AvailablePlatformsEnum.META) && (
            <Button
              sx={{
                p: 3,
                xs: { p: 1 },
                // border: addBorderRadiusOnError(AvailablePlatformsEnum.META),
              }}
              className={value === AvailablePlatformsEnum.META ? 'vertical-btn-selected' : ''}
              size="large"
              startIcon={<FacebookIcon />}
              key="one"
              variant="text"
              onClick={() => handleChange(AvailablePlatformsEnum.META)}>
              {capitalize(AvailablePlatformsEnum.META)}
            </Button>
          )}
          {availablePlatforms.includes(AvailablePlatformsEnum.DELTA) && (
            <Button
              sx={{ p: 3, xs: { p: 1 } }}
              size="large"
              className={value === AvailablePlatformsEnum.DELTA ? 'vertical-btn-selected' : ''}
              startIcon={<NewspaperIcon />}
              key="two"
              variant="text"
              onClick={() => handleChange(AvailablePlatformsEnum.DELTA)}>
              Aviser og nettisder
            </Button>
          )}
          {availablePlatforms.includes(AvailablePlatformsEnum.SNAPCHAT) && (
            <Button
              sx={{ p: 3, xs: { p: 1 } }}
              size="large"
              className={value === AvailablePlatformsEnum.SNAPCHAT ? 'vertical-btn-selected' : ''}
              startIcon={<SnapChatIcon />}
              key="three"
              variant="text"
              onClick={() => handleChange(AvailablePlatformsEnum.SNAPCHAT)}>
              {capitalize(AvailablePlatformsEnum.SNAPCHAT)}
            </Button>
          )}
          {availablePlatforms.includes(AvailablePlatformsEnum.BIDTHEATRE) && (
            <Button
              sx={{ p: 3, xs: { p: 1 } }}
              size="large"
              className={value === AvailablePlatformsEnum.BIDTHEATRE ? 'vertical-btn-selected' : ''}
              startIcon={<Avatar src="/images/bidtheatre/bidtheatre.png" />}
              key="four"
              variant="text"
              onClick={() => handleChange(AvailablePlatformsEnum.BIDTHEATRE)}>
              {capitalize(t(AvailablePlatformsEnum.BIDTHEATRE))}
            </Button>
          )}
          {availablePlatforms.includes(AvailablePlatformsEnum.BOARDS) && (
            <Button
              sx={{ p: 3, xs: { p: 1 } }}
              size="large"
              className={value === AvailablePlatformsEnum.BOARDS ? 'vertical-btn-selected' : ''}
              startIcon={
                <div>
                  <Avatar src="/images/boards/boards_logo.jpg" />
                </div>
              }
              key="five"
              variant="text"
              onClick={() => handleChange(AvailablePlatformsEnum.BOARDS)}>
              {capitalize(t(AvailablePlatformsEnum.BOARDS))}
            </Button>
          )}
        </ButtonGroup>
      </Grid>
      <Grid item xs={12} md={10} pr={3}>
        {availablePlatforms.includes(AvailablePlatformsEnum.META) && (
          <div hidden={value !== AvailablePlatformsEnum.META}>
            <MetaFormsResolver creativeType={creativeType} updating={updating} />
          </div>
        )}

        {availablePlatforms.includes(AvailablePlatformsEnum.DELTA) && (
          <div hidden={value !== AvailablePlatformsEnum.DELTA}>
            <DeltaFormsResolver creativeType={creativeType} updating={updating} />
          </div>
        )}

        {availablePlatforms.includes(AvailablePlatformsEnum.SNAPCHAT) && (
          <div hidden={value !== AvailablePlatformsEnum.SNAPCHAT}>
            <SnapChatFormsResolver creativeType={creativeType} updating={updating} />
          </div>
        )}
        {availablePlatforms.includes(AvailablePlatformsEnum.BIDTHEATRE) && (
          <div hidden={value !== AvailablePlatformsEnum.BIDTHEATRE}>
            <BidtheatreFormsResolver creativeType={creativeType} updating={updating} />
          </div>
        )}
        {availablePlatforms.includes(AvailablePlatformsEnum.BOARDS) && (
          <div hidden={value !== AvailablePlatformsEnum.BOARDS}>
            <BoardsFormsResolver creativeType={creativeType} updating={updating} />
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export default CreativesTypeResolver;
