import { IGenerateShadowInstagramFormValues } from '@/admin/shared/components/modals/GenerateShadowInstagram';
import { api } from '@/shared/services/api/api';

const baseUrl = 'admin/shadow-instagram';

export const generateShadowInstApi = api.injectEndpoints({
  endpoints: (build) => ({
    generateShadowInst: build.mutation<{shadowInstagramId: string}, IGenerateShadowInstagramFormValues>({
      query: (values: IGenerateShadowInstagramFormValues) => ({
        url: `${baseUrl}-ids/actions:generate`,
        method: 'POST',
        body: values,
      }),
    }),
  }),
});

export const {
  useGenerateShadowInstMutation
} = generateShadowInstApi;
