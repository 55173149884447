import React, { useEffect } from 'react';

const HelpCrunch = () => {
  useEffect(() => {
    window.helpcrunchSettings = {
      organization: 'makeads',
      appId: '3966c0a1-c09f-42c4-99f2-5cf9ab739550',
    };

    (function (w, d) {
      var hS = w.helpcrunchSettings;
      if (!hS || !hS.organization) {
        return;
      }
      var widgetSrc = 'https://embed.helpcrunch.com/sdk.js';
      w.HelpCrunch = function () { w.HelpCrunch.q.push(arguments); };
      w.HelpCrunch.q = [];
      
      function r() {
        if (d.querySelector('script[src="' + widgetSrc + '"]')) {
          return;
        }
        var s = d.createElement('script');
        s.async = true;
        s.type = 'text/javascript';
        s.src = widgetSrc;
        (d.body || d.head).appendChild(s);
      }

      if (d.readyState === 'complete' || hS.loadImmediately) {
        r();
      } 
      else{
        w.addEventListener('load', r, false);
      }
    })(window, document);
  }, []);

  return null;
};

export default HelpCrunch;
