import { useEffect, useState } from 'react';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { ISingleCreativeModel } from '@/shared/models/creatives/single-creative.model';
import { IDynamicCreativeModel } from '@/shared/models/creatives/dynamic-creative.model';
import { ICarouselCreativeModel } from '@/shared/models/creatives/carousel-creative.model';
import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';

export function useSelectHeaderImage(order: ISingleOrderWithDetailsModel) {
  const [headerImg, setHeaderImg] = useState<string | null>();

  useEffect(() => {
    const adToUse = order.advertisements.find((el) => el.platform === 'meta') ?? order.advertisements[0];

    let img: string | null = null;
    switch (adToUse.creative.creativeType) {
      case CreativeTypesEnum.SINGLE:
        img = (adToUse.creative as ISingleCreativeModel).media.propertyFile.preSignedUrl;
        break;
      case CreativeTypesEnum.DYNAMIC:
        img = (adToUse.creative as IDynamicCreativeModel).creativeMedias[0]?.propertyFile?.preSignedUrl;
        break;
      case CreativeTypesEnum.CAROUSEL:
        img = (adToUse.creative as ICarouselCreativeModel).creativeItems[0]?.creativeMedia?.propertyFile?.preSignedUrl;
        break;
    }
    setHeaderImg(img);
    return () => setHeaderImg(null);
  }, [order]);
  return headerImg;
}
