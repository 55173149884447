import { FC } from 'react';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

const CampaignPartialForm: FC = (): JSX.Element => {
  const { getFieldMeta, handleChange } = useFormikContext();

  const { t } = useTranslation();

  return (
    <CommonTextField
      name="title"
      label={t('campaignName')}
      value={getFieldMeta('title').value}
      onChange={handleChange}
      required
      error={Boolean(getFieldMeta('title').touched && getFieldMeta('title').error)}
      helperText={getFieldMeta('title').touched && getFieldMeta('title').error}
    />
  );
};

export default CampaignPartialForm;
