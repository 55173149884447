import { Grid, Typography } from '@mui/material';
import React from 'react';
import { IBasicCreativeFormValues } from '@/editor/shared/components/forms/creatives/models/basic-creative-form-values.model';
import { useTranslation } from 'react-i18next';
export interface IBidtheatreSummaryProps {
  currentCreativeForm: IBasicCreativeFormValues;
}
const BidtheatreSummary: React.FC<IBidtheatreSummaryProps> = ({ currentCreativeForm }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('caption')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.caption}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('description')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.description}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('link')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.link}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('ownershipType')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.ownershipType}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('areaInUsage')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.areaInUsage}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('viewDate')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.viewDate}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" sx={{ fontWeight: 700 }}>
          {t('propertyDebt')}:
        </Typography>
        <Typography variant="h6">{currentCreativeForm?.stateDebt}</Typography>
      </Grid>
    </div>
  );
};
export default BidtheatreSummary;
