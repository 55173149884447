import { IRoute } from "../../shared/models/route.model";
import UsersTable from "../pages/users/UsersTablePage";
import CreateEditUser from "../pages/users/create-edit/CreateEditUser";
import AdminUserProfilePage from "../pages/users/user-profile/AdminUserProfilePage";

export const ROUTE_USERS_DECLARATIONS = Object.freeze({
  AdminUsersPage: "users",
  AdminUsersNewPage: "users/new",
  AdminUsersEditPage: "users/:userId/edit",
  AdminUsersDetailsPage: "users/:userId/user-profile",
});

export const AdminUsersRoutes: IRoute[] = [
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersPage,
    component: UsersTable,
  },
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersNewPage,
    component: CreateEditUser,
  },
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersEditPage,
    component: CreateEditUser,
  },
  {
    path: ROUTE_USERS_DECLARATIONS.AdminUsersDetailsPage,
    component: AdminUserProfilePage,
  },
];
