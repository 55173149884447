import { useEffect } from 'react';
import { useAppDispatch } from '@/store/hooks/redux';
import {
  ICreativeSubmissionProgress,
  setcreativeSubmissionProgress,
  setValidPlatform,
} from '@/editor/store/reducers/creative-step.slice';
import { PlatformType } from '@/editor/shared/constants/available-platforms';

interface FormSubmissionHookProps {
  formik: any;
  formSubmissionProgress: ICreativeSubmissionProgress;
  updating?: boolean;
  platform: PlatformType;
}

interface FormSubmissionHookReturn {
  handleSubmit: () => void;
}

const useFormSubmission = ({
  formik,
  formSubmissionProgress,
  updating,
  platform,
}: FormSubmissionHookProps): FormSubmissionHookReturn => {
  const dispatch = useAppDispatch();

  const handleUpdate = () => {
    formik.handleSubmit();

    if (formik.isValid) {
      dispatch(setcreativeSubmissionProgress({ active: true, update: true, platform: platform }));
      dispatch(setValidPlatform({ platform: platform, isValid: formik.isValid }));
    }
  };

  const handleSubmit = () => {
    if (!formSubmissionProgress.active) {
      return;
    }

    if (!formik.isValid) {
      dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: platform }));
    }

    formik.handleSubmit();
    if (formik.touched && formik.isValid) {
      dispatch(setcreativeSubmissionProgress({ active: false, update: false, platform: platform }));
      dispatch(setValidPlatform({ platform: platform, isValid: formik.isValid }));
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [formSubmissionProgress.active, updating]);

  return {
    handleSubmit,
  };
};

export default useFormSubmission;
