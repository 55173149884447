import { IUserProfileModel } from "../../models/user-profile.model";
import { TypeIdsDefinitions, api } from "./api";

const baseUrl = "users";

export const userProfileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<IUserProfileModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}/profiles`,
      }),
      providesTags: (result, error, arg) => [
        { type: "UserProfiles", id: arg.id },
      ],
    }),
    adminCreateUserProfile: build.mutation<
      IUserProfileModel,
      { id: string; values: FormData }
    >({
      query: ({ id, values }) => ({
        url: `${baseUrl}/${id}/profiles`,
        body: values,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfiles", id: arg.id },
      ],
    }),
    updateUserProfile: build.mutation<
      IUserProfileModel,
      { id: string; values: FormData }
    >({
      query: ({ id, values }) => ({
        url: `${baseUrl}/${id}/profiles`,
        body: values,
        method: "POST",
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfiles", id: arg.id },
        { type: "Users", id: TypeIdsDefinitions.listTypeId },
      ],
    }),
  }),
});

export const {
  useAdminCreateUserProfileMutation,
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} = userProfileApi;
