import { StepIconProps } from '@mui/material/StepIcon';
import * as React from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import { styled } from '@mui/material/styles';

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ ownerState }) => ({
  backgroundColor: '#ccc',
  zIndex: 1,
  color: '#000',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage: 'linear-gradient(90deg, #000  31%, #000 100%) ',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    color: '#fff',
  }),
  ...(ownerState.completed && {
    backgroundImage: 'linear-gradient(90deg, rgb(219 140 39)  31%,  rgb(255, 167, 55) 100%) ',
    color: '#fff',
  }),
}));

const ColorlibStepIcon: React.FC<StepIconProps> = (props: StepIconProps): JSX.Element => {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <LinkIcon />,
    2: <SettingsIcon />,
    3: <ImageIcon />,
    4: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
};

export default ColorlibStepIcon;
