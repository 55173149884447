import CommonButton from '@/shared/components/CommonButton';
import {
  ISharedStatsTokenModel,
  ISingleOrderWithDetailsModel,
} from '@/shared/models/orders/single-order-with-details.model';
import axiosInstance from '@/shared/services/api/axios.api';
import { Dialog, DialogActions, DialogContent, Grid, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { truncate } from 'lodash';
import { FC, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import SendEmailWithSharedTokenForm from './SendEmailWithSharedTokenForm';

export interface IStatsiticsSharedTokenProps {
  order: ISingleOrderWithDetailsModel;
}
const StatisticsSharedToken: FC<IStatsiticsSharedTokenProps> = (props: IStatsiticsSharedTokenProps): JSX.Element => {
  const { order } = props;

  const { id } = useParams();
  const [dialogOpened, setDialogOpened] = useState<boolean>(false);
  const [generatedToken, setGeneratedToken] = useState<ISharedStatsTokenModel | null>(null);

  const clickOnGenerateToken = async () => {
    const res = await toast.promise(
      axiosInstance.post<ISharedStatsTokenModel>(
        `${import.meta.env.VITE_API_BASE_URL}/orders/${id}/actions:generateShareToken`
      ),
      {
        loading: 'Generating...',
        success: 'Token & password generated',
        error: 'Something happened',
      }
    );

    setGeneratedToken(res?.data);
  };

  const copyToClipboard = (value: string) => {
    toast.promise(navigator.clipboard.writeText(value), {
      loading: 'Copying to clipboard',
      error: 'Unable to copy to clipboard',
      success: 'Successfully copied to clipboard',
    });
  };

  const buildSharedLink = (id: string, token: string): string => {
    return import.meta.env.VITE_BASE_FE_URL + `/statistics/orders/${id}/shared/${token}`;
  };

  const renderGeneratedModalSingleInfoBlock = (name: string, value: string) => {
    return (
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        border="1px solid #7b68ee"
        borderRadius="5px"
        p="20px"
        m="10px 0">
        <Typography color="secondary" variant="subtitle1">
          {name}: {truncate(value, { length: 30 })}
        </Typography>
        <CommonButton onClick={() => copyToClipboard(value)}>Copy</CommonButton>
      </Grid>
    );
  };

  return (
    <div>
      <ListItem>
        <ListItemText>Shared statistics token</ListItemText>
        <CommonButton variant="outlined" onClick={() => setDialogOpened(true)}>
          Open modal
        </CommonButton>
      </ListItem>
      <Dialog open={dialogOpened}>
        <DialogContent>
          {!generatedToken ? (
            <div>
              {order.orderStatsShareToken?.token ? (
                <Grid container>
                  <Grid item xs={11} display="flex" justifyContent="center">
                    <Tooltip
                      sx={{ cursor: 'pointer' }}
                      placement="top"
                      onClick={() => copyToClipboard(buildSharedLink(id!, order?.orderStatsShareToken?.token ?? ''))}
                      title="Click to copy">
                      <Typography
                        color="secondary"
                        variant="subtitle1"
                        sx={{ ':hover': { color: 'turquoise', cursor: 'pointer' } }}>
                        Link successfully generated. Link to copy to clipboard.
                      </Typography>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <SendEmailWithSharedTokenForm />
                  </Grid>
                </Grid>
              ) : (
                <Typography variant="subtitle2" color="secondary">
                  Order has no generated token & password to share with user.
                </Typography>
              )}
            </div>
          ) : (
            <Grid container>
              {renderGeneratedModalSingleInfoBlock('Token', generatedToken.token)}
              {renderGeneratedModalSingleInfoBlock('Password', generatedToken.password)}
              {renderGeneratedModalSingleInfoBlock('Link to share', buildSharedLink(id!, generatedToken.token))}
              <Grid item xs={12}>
                <SendEmailWithSharedTokenForm />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <CommonButton color="error" onClick={() => setDialogOpened(false)}>
            Close
          </CommonButton>
          <CommonButton onClick={clickOnGenerateToken}>Generate new token & password</CommonButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatisticsSharedToken;
