import { Box } from '@mui/material';
import { useAppSelector } from '@/store/hooks/redux';
import EmptyImageCard from '../EmptyImageCard';
import { A11y, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const FacebookSinglePreview = (): JSX.Element => {
  const media = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState.meta.single.media);

  return (
    <Box component="div" sx={{ maxWidth: '632px', height: '100%' }}>
      {media?.preSignedUrl ? (
        <Swiper modules={[A11y]} slidesPerView={1}>
          <SwiperSlide>
            <img
              src={media?.preSignedUrl ?? ''}
              style={{
                minWidth: '550px',
                maxHeight: '250px',
                objectFit: 'cover',
              }}
              alt="dynamic-image"></img>
          </SwiperSlide>
        </Swiper>
      ) : (
        // <img
        //   style={{
        //     minWidth: '550px',
        //     maxHeight: '250px',
        //     objectFit: 'cover',
        //   }}
        //   src={media?.preSignedUrl}
        //   alt="preview"></img>
        <EmptyImageCard></EmptyImageCard>
      )}
    </Box>
  );
};

export default FacebookSinglePreview;
