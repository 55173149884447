import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import {
  setCreativeFormValueForPlatform,
  setCreativeItemsOnCarousel,
  setMediaOnDynamic,
  setMediaOnSingle,
} from '@/editor/store/reducers/creative-step.slice';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppDispatch } from '@/store/hooks/redux';
import { useEffect } from 'react';

const useRestoreState = (platform: PlatformType, isInitialized: boolean) => {
  const creativeTypeFromLS = getValueFromLs(EDITOR_LS_CONSTANTS.SELECTED_CREATIVE_TYPE) as CreativeType;
  const creativeFormValues = JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.CREATIVES_FORMS[platform]) as string);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const setImageForCreativeType = (
      creativeType: CreativeType,
      creativeFormValues: any,
      platform: PlatformType
    ): void => {
      switch (creativeType) {
        case 'single': {
          dispatch(setMediaOnSingle({ media: creativeFormValues.media, platform: platform }));
          break;
        }
        case 'dynamic': {
          dispatch(
            setMediaOnDynamic({
              platform: platform,
              creativeMedias: creativeFormValues.creativeMedias!,
            })
          );
          break;
        }
        case 'carousel': {
          dispatch(
            setCreativeItemsOnCarousel({
              platform: platform,
              creativeItems: creativeFormValues.creativeItems,
            })
          );
          break;
        }
      }
    };

    if (creativeFormValues !== null && !isInitialized) {
      dispatch(
        setCreativeFormValueForPlatform({
          creativeType: creativeTypeFromLS,
          platform: platform,
          values: creativeFormValues,
        })
      );
      setImageForCreativeType(creativeTypeFromLS, creativeFormValues, platform);
    }

    return;
  }, [isInitialized]);

  return creativeFormValues;
};

export default useRestoreState;
