import UserGuard from '../../editor/guards/UserGuard';
import { IRoute } from '@/shared/models/route.model';
import AuthenticatedStatisticsTemplateResolver from '../templates/AuthenticatedStatisticsTemplateResolver';
import SharedStatisticsTemplateResolver from '../templates/SharedStatisticsTemplateResolver';

export const statisticsRoutes: IRoute[] = [
  {
    path: '/statistics/orders/:id',
    component: AuthenticatedStatisticsTemplateResolver,
    guard: UserGuard,
    children: [],
  },
  {
    path: '/statistics/orders/:id/shared/:token',
    component: SharedStatisticsTemplateResolver,
  },
];
