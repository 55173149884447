import { IMediaLibraryFileModel } from '@/shared/models/media-library/media-library-file.model';
import { toast } from 'react-hot-toast';
import { AvailablePlatformsEnum } from '../../shared/constants/available-platforms';
import {
  default as CreativesTypesEnum,
  default as CreativeTypesEnum,
} from '../../shared/constants/creatives-types.enum';
import { IBasicCreativeFormValues } from '../../shared/components/forms/creatives/models/basic-creative-form-values.model';
import { CreativeFormStateKey, ICreativeFormsState } from '../../store/reducers/creative-step.slice';
import { ICreativeFormsStatePerPlatform } from '../../store/reducers/creative-step.slice';
import { ICarouselCreativeFormValuesModel } from '@/editor/shared/components/forms/creatives/models/carousel-creative-form-values';

const lengthMapper: Partial<Record<keyof IBasicCreativeFormValues, number>> = {
  headline: 1024,
  caption: 40,
  description: 255,
  link: 255,
};

const validateCreatives = (
  creativeFormsState: ICreativeFormsState,
  activePlatforms: string[],
  selectedCreativeType: string
) => {
  const platformKeys = Object.keys(creativeFormsState) as Array<CreativeFormStateKey>;
  const filteredPlatformsToCheck = platformKeys.filter((key) => activePlatforms.includes(key));

  switch (selectedCreativeType) {
    case CreativesTypesEnum.SINGLE:
    case CreativeTypesEnum.VIDEO:
      return validateSingleCreative(creativeFormsState, filteredPlatformsToCheck);
    case CreativesTypesEnum.DYNAMIC:
      return validateDynamicCreative(creativeFormsState, filteredPlatformsToCheck);
    case CreativesTypesEnum.CAROUSEL:
      return validateCarouselCreative(creativeFormsState, filteredPlatformsToCheck);
  }
};

export const validateSingleCreative = (
  creativesState: ICreativeFormsState,
  platformsKeys: (keyof ICreativeFormsState)[]
): boolean => {
  for (const platform of platformsKeys) {
    const typedState = creativesState[platform] as ICreativeFormsStatePerPlatform;

    if (!validateBaseFieldsOfBasicCreative(typedState[CreativesTypesEnum.SINGLE], platform)) {
      return false;
    }

    if (!typedState?.[CreativesTypesEnum.SINGLE]?.media && !typedState?.[CreativesTypesEnum.VIDEO]?.media) {
      toast.error(`Review form of ${platform} please`);
      return false;
    }
    if (AvailablePlatformsEnum.BIDTHEATRE == platform) {
      const { caption, viewDate, propertyEstateType, description, link } = typedState?.[CreativesTypesEnum.SINGLE] || {};

      if (!caption) {
        toast.error(`Caption Required`);
        return false;
      }
      if (!description) {
        toast.error(`Description Required`);
        return false;
      }
      if (!link) {
        toast.error(`Link Required`);
        return false;
      }

      if (caption && caption.length > 40) {
        toast.error(`Caption should not be more than 40 characters`);
        return false;
      }
      if (viewDate && viewDate.length > 45) {
        toast.error(`View date should not be more than 45 characters`);
        return false;
      }
      if (propertyEstateType && propertyEstateType.length > 20) {
        toast.error(`Property estate type should not be more than 20 characters`);
        return false;
      }

      return true
    }
  }

  return true;
};

export const validateDynamicCreative = (
  creativesState: ICreativeFormsState,
  platformsKeys: (keyof ICreativeFormsState)[]
): boolean => {
  for (const platform of platformsKeys) {
      const typedState = creativesState[platform] as ICreativeFormsStatePerPlatform;
    if (!creativesState[platform]) continue;

    const platformsToCheck = platformsKeys.filter((el) => el !== AvailablePlatformsEnum.DELTA);
    if (!validateMultipleImagesCreatives(creativesState, platformsToCheck, CreativesTypesEnum.DYNAMIC)) return false;
     if (AvailablePlatformsEnum.BIDTHEATRE == platform) {
      const { caption, viewDate, propertyEstateType, description, link } = typedState?.[CreativesTypesEnum.SINGLE] || {};

      if (!caption) {
        toast.error(`Caption Required`);
        return false;
      }
      if (!description) {
        toast.error(`Description Required`);
        return false;
      }
      if (!link) {
        toast.error(`Link Required`);
        return false;
      }

      if (caption && caption.length > 40) {
        toast.error(`Caption should not be more than 40 characters`);
        return false;
      }
      if (viewDate && viewDate.length > 45) {
        toast.error(`View date should not be more than 45 characters`);
        return false;
      }
      if (propertyEstateType && propertyEstateType.length > 20) {
        toast.error(`Property estate type should not be more than 20 characters`);
        return false;
      }
      return true
    }
  }

  return true;
};

export const validateCarouselCreative = (
  creativesState: ICreativeFormsState,
  platformsKeys: (keyof ICreativeFormsState)[]
): boolean => {
  const platformsToCheck = platformsKeys.filter((el) => el !== AvailablePlatformsEnum.DELTA);

  if (!validateMultipleImagesCreatives(creativesState, platformsToCheck, CreativesTypesEnum.CAROUSEL)) return false;

  for (const platform of platformsToCheck) {
    if (!creativesState[platform]) continue;

    const typedCreativeState = creativesState[platform].carousel as ICarouselCreativeFormValuesModel;
    for (const [idx, creativeItem] of typedCreativeState.creativeItems.entries()) {
      if (
        !creativeItemValidation(creativeItem.headline, platform, idx, 'headline') ||
        !creativeItemValidation(creativeItem.description, platform, idx, 'description') ||
        !creativeItemValidation(creativeItem.link, platform, idx, 'link') ||
        !creativeItemValidation(creativeItem.creativeMedia, platform, idx, 'creative media')
      ) {
        return false;
      }
    }
  }

  return true;
};

const creativeItemValidation = (
  val: string | IMediaLibraryFileModel | null,
  platform: string,
  idx: number,
  msgPart: string
): boolean => {
  if (!val) {
    toast.error(`Carousel ${msgPart} for ${platform} at position ${idx} is required`);
    return false;
  }

  return true;
};

const validateBaseFieldsOfBasicCreative = (values: IBasicCreativeFormValues, platform: string): boolean => {
  const keys = Object.keys(values) as Array<keyof IBasicCreativeFormValues>;

  for (const key of keys) {
    // if (
    //   platform === AvailablePlatformsEnum.BIDTHEATRE &&
    //   key !== 'ownershipType' &&
    //   key !== 'areaInUsage' &&
    //   key !== 'viewDate' &&
    //   key !== 'bidStateDebt' &&
    //   key !== 'priceQuote' &&
    //   key !== 'propertyEstateType' &&
    //   !values[key]
    // ) {
    //   toast.error(`Field ${key} for ${platform} is required`);

    //   return false;
    // }

    if (lengthMapper[key] && (values[key] as string)?.length > lengthMapper[key]! && AvailablePlatformsEnum.BIDTHEATRE !== platform) {
      toast.error(`Field ${key} for ${platform} is too long`);

      return false;
    }
  }

  return true;
};

const validateMultipleImagesCreatives = (
  creativesState: ICreativeFormsState,
  platformsKeys: (keyof ICreativeFormsState)[],
  selectedCreativeType: string
): boolean => {
  for (const platform of platformsKeys) {
    if (!creativesState[platform]) continue;

    if (!validateBaseFieldsOfBasicCreative(creativesState[platform] as IBasicCreativeFormValues, platform))
      return false;

    let arrayToCheck: any[];
    switch (selectedCreativeType) {
      case CreativesTypesEnum.CAROUSEL:
        arrayToCheck = creativesState[platform]?.carousel?.creativeItems || [];
        break;
      case CreativesTypesEnum.DYNAMIC:
        arrayToCheck = creativesState[platform]?.dynamic?.creativeMedias || [];
        break;
      default:
        arrayToCheck = [];
    }

    if (arrayToCheck?.length < 1) {
      toast.error(`At least 1 media for ${platform} is required`);

      return false;
    }

    return true;
  }

  return true;
};

export default validateCreatives;
