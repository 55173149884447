import React from 'react';
import { ISingleOrderWithDetailsModel } from '@/shared/models/orders/single-order-with-details.model';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { useSelectHeaderImage } from '@/statistics/hooks/select-header-image';

interface ISormeglerenHeaderProps {
  order: ISingleOrderWithDetailsModel;
}

const SormeglerenStatisticsHeader: React.FC<ISormeglerenHeaderProps> = (
  props: ISormeglerenHeaderProps
): JSX.Element => {
  const { order } = props;
  const imageHeader = useSelectHeaderImage(order);

  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: '#1e0e3c',
        minHeight: '10rem',
        display: 'flex',
      }}>
      <Grid
        item
        order={{ xs: 3, md: 1, lg: 1, xl: 1 }}
        md={3}
        xs={12}
        sx={{
          pt: '0!important',
        }}>
        <Box
          height={'10rem'}
          sx={{
            backgroundImage: `url(${imageHeader})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
      <Grid order={{ xs: 1, md: 2 }} item md={7} xs={6} display={'flex'} alignItems={'center'}>
        <Box color="#fff" display={'flex'} justifyContent={'flex-start'} flexDirection={'column'}>
          <Typography variant="caption">Live rapport</Typography>
          <Typography variant="h1">{order.propertyAddress}</Typography>
        </Box>
      </Grid>
      <Grid display={'flex'} alignItems={'center'} order={{ xs: 2, md: 3 }} item md={2} xs={6}>
        <Box
          component="img"
          sx={{
            width: 120,
            maxHeight: { xs: 233, md: 167 },
            maxWidth: { xs: 350, md: 250 },
          }}
          alt="The house from the offer."
          src="/images/sormegleren_logo.png"
        />
      </Grid>
    </Grid>
  );
};

export default SormeglerenStatisticsHeader;
