import FacebookIcon from '@mui/icons-material/Facebook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SnapChatIcon from '../../../shared/icons/SnapChatIcon';
import { IPlatformChoice } from '../../store/reducers/selected-platforms.slice';
import BidTheatreIcon from '../../../shared/icons/bidtheatreIcon';
import BoardsIcon from '@/shared/icons/boardsIcon';

export enum AvailablePlatformsEnum {
  META = 'meta',
  SNAPCHAT = 'snapchat',
  DELTA = 'delta',
  BIDTHEATRE = 'bidtheatre',
  BOARDS = 'boards',
} 

export type PlatformType = 'meta' | 'snapchat' | 'delta' | 'bidtheatre' | 'boards';

const availablePlatforms: IPlatformChoice[] = [
  {
    id: 'meta',
    title: 'Meta',
    icon: FacebookIcon,
  },
  {
    id: 'snapchat',
    title: 'Snapchat',
    icon: SnapChatIcon,
  },
  {
    id: 'delta',
    title: 'OG Display',
    icon: NewspaperIcon,
  },
  {
    id: 'bidtheatre',
    title: 'bidtheatre',
    icon: BidTheatreIcon,
  },
  {
    id: 'boards',
    title: 'boards',
    icon: BoardsIcon,
  },
];

export const platformsToCropImage = [{ platform: AvailablePlatformsEnum.SNAPCHAT, width: 1080, height: 1920 }];

export default availablePlatforms;
