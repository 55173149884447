import React, { FC } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import CommonButton from '@/shared/components/CommonButton';
import { useFormik } from 'formik';
import moment from 'moment';
import CommonTextField from '@/shared/components/CommonTextField';
import { DatePicker } from '@mui/x-date-pickers';
import axiosInstance from '@/shared/services/api/axios.api';
import { toast } from 'react-hot-toast';
import { AxiosError } from 'axios';

export interface IOfficeAdminExportDialogProps {
  exportModalOpen: boolean;
  setExportModalOpen: (value: boolean) => void;
  exportUrl: string;
}

const ExportDialog: FC<IOfficeAdminExportDialogProps> = (props: IOfficeAdminExportDialogProps): JSX.Element => {
  const { exportModalOpen, setExportModalOpen, exportUrl } = props;

  const { handleSubmit, getFieldMeta, setFieldValue } = useFormik({
    initialValues: {
      startTime: moment(),
      endTime: moment(),
    },
    onSubmit: async (values) => {
      const arr = [];
      const urlSearchParams = new URLSearchParams();
      if (values.startTime) {
        const obj = { id: 'createdAtStartTime', value: moment(values.startTime).format('YYYY-MM-DD') };
        arr.push(obj);
      }

      if (values.endTime) {
        const obj = { id: 'createdAtEndTime', value: moment(values.endTime).format('YYYY-MM-DD') };
        arr.push(obj);
      }

      const strArr = JSON.stringify(arr);
      urlSearchParams.set('filters', strArr);

      const { data } = await toast.promise(
        axiosInstance.get(exportUrl, {
          params: urlSearchParams,
          responseType: 'blob',
        }),
        {
          loading: 'Generating...',
          success: 'File downloaded',
          error: (err: AxiosError) =>
            err.response?.status === 425 ? <span>No data to export</span> : <span>Error while generating report</span>,
        }
      );

      if (data) {
        const url = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${moment().format('YYYY-MM-DD H:i:s')}.xls`);
        link.click();
      }
    },
  });

  return (
    <Dialog open={exportModalOpen}>
      <DialogTitle>Export settings</DialogTitle>
      <form noValidate onSubmit={(e) => handleSubmit(e)}>
        <DialogContent>
          <Typography variant="subtitle1">Short instructions:</Typography>
          <Typography variant="subtitle1">
            1. If both fields selected - export will happened for orders where created_at between dates
          </Typography>
          <Typography variant="subtitle1">
            2. If only start time - export will happened for orders where created_at greater then start time
          </Typography>
          <Typography variant="subtitle1">
            3. If only end time - export will happened for orders where created_at less then end time
          </Typography>
          <DatePicker
            label="Order created at - start time"
            value={getFieldMeta('startTime').value}
            onChange={(e) => {
              setFieldValue('startTime', e);
            }}
            renderInput={(params) => <CommonTextField sx={{ mt: '1rem' }} {...params} fullWidth error={false} />}
          />
          <DatePicker
            label="Order created at - end time"
            value={getFieldMeta('endTime').value}
            onChange={(e) => {
              setFieldValue('endTime', e);
            }}
            renderInput={(params) => <CommonTextField sx={{ mt: '1rem' }} {...params} fullWidth error={false} />}
          />
        </DialogContent>
        <DialogActions>
          <Box>
            <CommonButton color="error" onClick={() => setExportModalOpen(false)}>
              Close
            </CommonButton>
            <CommonButton type="submit" sx={{ ml: '0.5rem' }}>
              Export
            </CommonButton>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ExportDialog;
