import { PlatformType } from '@/editor/shared/constants/available-platforms';
import { setSingleSliderActiveState, setSlidersInitialState } from '@/editor/store/reducers/selected-platforms.slice';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { Checkbox, Grid, Paper, Slider } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import usePlatformCalculationOnBudget from './hooks/platform-calculation-on-budget.hook';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@mui/icons-material/Facebook';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import SnapChatIcon from '../../../../../shared/icons/SnapChatIcon';
import BidTheatreIcon from '../../../../../shared/icons/bidtheatreIcon';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import BoardsIcon from '@/shared/icons/boardsIcon';
import { IProductBudgetModel } from '@/shared/models/products/product.model';
import CommonTextField from '@/shared/components/CommonTextField';
import { useFormikContext } from 'formik';

export interface ISelectPlatformsProps {
  selectedBudget: IBudgetModel | IProductBudgetModel;
  updating: boolean;
}

export interface ISliderValuesPerPlatform {
  platform: PlatformType;
  value: number;
  isActive: boolean;
}

const SelectPlatforms: React.FC<ISelectPlatformsProps> = ({ selectedBudget, updating }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { possibleSliders } = usePlatformCalculationOnBudget({ selectedBudget });
  const slidersState = useAppSelector((state) => state.editorActivePlatformsReducer.slidersState);
  const [sliders, setSliders] = useState<ISliderValuesPerPlatform[]>([]);
  const { getFieldMeta, handleChange } = useFormikContext();

  const activePlatformsFromLS: PlatformType[] | null =
    getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) !== null
      ? JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string)
      : null;

  useEffect(() => {
    setSliders(possibleSliders ?? []);

    // activePlatformsFromLS === null means that it is initialization
    // possibleSliders?.length !== sliders.length means that budget was changed
    if (activePlatformsFromLS === null || possibleSliders?.length !== sliders.length) {
      storeActivePlatforms(possibleSliders ?? []);
    }
  }, [possibleSliders]);

  // prevent from going over hundred percent
  const recalcSpreadingBudgetValues = (newValue: number, platformId: PlatformType) => {
    const sum = sliders.reduce((a, b) => (b.isActive ? a + b.value : a), 0);

    storeBudgetSpreading();

    if (sum > 100) {
      let excess = sum - 100;
      let activeSliders = sliders.filter((el) => el.isActive);
      let excessPerPlatform = excess / (activeSliders.length - 1);

      const adjusted = sliders.map((el) => {
        if (el.platform === platformId || !el.isActive) return el;
        el.value -= excessPerPlatform;
        return el;
      });

      setSliders(adjusted);
      dispatch(setSlidersInitialState(adjusted));

      return;
    }

    dispatch(setSlidersInitialState(sliders));
  };

  const storeBudgetSpreading = () => {
    let newSpreadingBudgets = sliders.map((el) => {
      if (!el.isActive) return { [el.platform]: 0 };
      const record = { [el.platform]: el.value };
      return record;
    });
  };

  const selectIcon = (platform: PlatformType, isActive: boolean) => {
    switch (platform) {
      case 'meta':
        return (
          <Box>
            <FacebookIcon fontSize="large" />
          </Box>
        );
      case 'snapchat':
        return <SnapChatIcon color={isActive ? '#000' : '#666666'} fontSize="large" />;
      case 'delta':
        return <NewspaperIcon fontSize="large" />;
      case 'bidtheatre':
        return <BidTheatreIcon fontSize="large" />;
      case 'boards':
        return <BoardsIcon fontSize="large" />;
      default:
        return <div></div>;
    }
  };

  const handleSelectPlatform = (id: PlatformType, isActive: boolean) => {
    dispatch(setSingleSliderActiveState({ platform: id, isActive: !isActive }));
    const newSliders = sliders.map((slider) => (slider.platform === id ? { ...slider, isActive: !isActive } : slider));
    storeActivePlatforms(newSliders);
    setSliders(newSliders);
  };

  const storeActivePlatforms = (sliders: ISliderValuesPerPlatform[]): void => {
    const activePlatforms = sliders.filter(({ isActive }) => isActive).map(({ platform }) => platform);
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS, JSON.stringify(activePlatforms));
  };

  const handleSliderChange = (event: any, newValue: number | number[], platform: PlatformType) => {
    const index = sliders.findIndex((el) => el.platform === platform);
    let newSliders: ISliderValuesPerPlatform[] = [];
    sliders.forEach((slider, index) => {
      newSliders.push({ ...slider });
    });
    newSliders[index].value = +newValue;
    setSliders(newSliders);
  };
  const spendValue: any = getFieldMeta('budgetValue').value;
  const spendValueError = (selectedBudget as IProductBudgetModel)?.productItems ? (
    spendValue < ((selectedBudget as IProductBudgetModel)?.minimum ) ||
    spendValue > ((selectedBudget as IProductBudgetModel)?.maximum )) : '';

  const error = sliders.reduce((a, b) => (b.isActive ? a + b.value : a), 0) < 100;
  const sum = sliders.reduce((a, b) => (b.isActive ? a + b.value : a), 0);

  return (
    <div>
      {(selectedBudget as IProductBudgetModel)?.productItems ? (
        <>
          <Paper
            sx={{
              mt: 2,
              border: spendValueError ? '1px solid #ffa737' : '1px solid transparent',
              boxShadow: 'none',
            }}>
            {spendValueError ? (
              <Box mb={2} sx={{ display: 'flex', justifyContent: 'center', boxShadow: 'none' }}>
                <Typography
                  sx={{ backgroundColor: '#ffa737', borderRadius: '4px', boxShadow: 'none', px:1 }}
                  variant="h6"
                  color="secondary">
                  Spend value must be between {(selectedBudget as IProductBudgetModel).minimum} and{' '}
                  {(selectedBudget as IProductBudgetModel).maximum}
                </Typography>
              </Box>
            ) : (
              ''
            )}
            <CommonTextField
              sx={{
                boxShadow: 'none',
                p: 0.5,
                border: 'none',
              }}
              type="number"
              id="budgetValue"
              name="budgetValue"
              label={t('spendValue')}
              value={getFieldMeta('budgetValue').value}
              onChange={handleChange}
              error={Boolean(getFieldMeta('budgetValue').touched && getFieldMeta('budgetValue').error)}
              helperText={getFieldMeta('budgetValue').touched && getFieldMeta('budgetValue').error}
            />
          </Paper>

          {!updating ? (
            <Paper
              sx={{ boxShadow: 'none', mt: 2, p: 0.5, border: error ? '1px solid #ffa737' : '1px solid transparent' }}>
              {error ? (
                <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    pl={0.5}
                    pr={0.5}
                    sx={{ backgroundColor: '#ffa737', borderRadius: '4px' }}
                    variant="h6"
                    color="secondary">
                    {t('lowBudget')} {sum}%
                  </Typography>
                </Box>
              ) : (
                <Box mb={2}>
                  <Typography variant="h6" color="secondary">
                    {t('selectPlatformsAndBudgets')}
                  </Typography>
                </Box>
              )}

              <Grid container spacing={3}>
                {sliders.map((slider, index) => (
                  <Grid key={index} item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        onClick={() => handleSelectPlatform(slider.platform, slider.isActive)}
                        aria-label={slider.platform}
                        checked={slider.isActive}
                        icon={selectIcon(slider.platform, slider.isActive)}
                        checkedIcon={selectIcon(slider.platform, slider.isActive)}
                      />
                      <Slider
                        valueLabelDisplay="on"
                        size="small"
                        valueLabelFormat={(value) => `${value}%`}
                        disabled={!slider.isActive}
                        name={slider.platform}
                        onChange={(e, value) => handleSliderChange(e, value as number, slider.platform)}
                        onChangeCommitted={(e, value) => recalcSpreadingBudgetValues(value as number, slider.platform)}
                        value={Math.round(slider.value)}
                        aria-label={slider.platform}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          ) : (
            <div></div>
          )}
        </>
      ) : (
        <>
          {!updating ? (
            <Paper
              sx={{ boxShadow: 'none', mt: 2, p: 0.5, border: error ? '1px solid #ffa737' : '1px solid transparent' }}>
              {error ? (
                <Box mb={2} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Typography
                    pl={0.5}
                    pr={0.5}
                    sx={{ backgroundColor: '#ffa737', borderRadius: '4px' }}
                    variant="h6"
                    color="secondary">
                    {t('lowBudget')} {sum}%
                  </Typography>
                </Box>
              ) : (
                <Box mb={2}>
                  <Typography variant="h6" color="secondary">
                    {t('selectPlatformsAndBudgets')}
                  </Typography>
                </Box>
              )}

              <Grid container spacing={3}>
                {sliders.map((slider, index) => (
                  <Grid key={index} item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        onClick={() => handleSelectPlatform(slider.platform, slider.isActive)}
                        aria-label={slider.platform}
                        checked={slider.isActive}
                        icon={selectIcon(slider.platform, slider.isActive)}
                        checkedIcon={selectIcon(slider.platform, slider.isActive)}
                      />
                      <Slider
                        valueLabelDisplay="on"
                        size="small"
                        valueLabelFormat={(value) => `${value}%`}
                        disabled={!slider.isActive}
                        name={slider.platform}
                        onChange={(e, value) => handleSliderChange(e, value as number, slider.platform)}
                        onChangeCommitted={(e, value) => recalcSpreadingBudgetValues(value as number, slider.platform)}
                        value={Math.round(slider.value)}
                        aria-label={slider.platform}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          ) : (
            <div></div>
          )}
        </>
      )}
    </div>
  );
};

export default SelectPlatforms;
