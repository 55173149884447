import { IRoute } from "../../shared/models/route.model";
import CreateEditProviderPage from "../pages/retail-providers/CreateEditProviderPage";
import RetailProvidersDataTable from "../pages/retail-providers/RetailProvidersTablePage";

export const ROUTE_RETAIL_PROVIDERS_DECLARATIONS = Object.freeze({
  AdminRetailProvidersPage: "providers",
  AdminRetailProvidersNewPage: "providers/new",
  AdminRetailProvidersEditPage: "providers/:providerId/edit",
  AdminRetailProvidersDetailsPage: "providers/:providerId/details",
});

export const RetailDataProvidersRoutes: IRoute[] = [
  {
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersPage,
    component: RetailProvidersDataTable,
  },
  {
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersNewPage,
    component: CreateEditProviderPage,
  },
  {
    path: ROUTE_RETAIL_PROVIDERS_DECLARATIONS.AdminRetailProvidersEditPage,
    component: CreateEditProviderPage,
  },
];
