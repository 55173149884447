import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IGuardPropsModel } from "@/shared/models/guard-props.model";
import {
  checkValidityAndRoleFromSymfonyAccessToken,
  checkTokenPresented,
  setupAccessTokenFromLS,
  SymfonyRolesEnum,
} from "../services/accesstoken.handler";
import { useAppSelector } from "@/store/hooks/redux";

const OnlyUnAuthorizedUserGuard: React.FC<IGuardPropsModel> = ({
  children,
}): JSX.Element => {
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const navigate = useNavigate();

  const checkTokenRole = (token: string): void => {
    let isAdmin = checkValidityAndRoleFromSymfonyAccessToken(token, SymfonyRolesEnum.ADMIN);
    isAdmin ? navigate("/dashboard") : navigate("/editor");
  };

  useEffect(() => {
    let subscription = true;
    if (subscription) {
      if (checkTokenPresented(accessToken)) {
        checkTokenRole(accessToken as string);
        return;
      }

      let credentials = setupAccessTokenFromLS();
      if (credentials) {
        checkTokenRole(credentials.accessToken);
        return;
      }
    }

    return () => {
      subscription = false;
    };
  }, []);
  // use selector to check user
  return children;
};

export default OnlyUnAuthorizedUserGuard;
