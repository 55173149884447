import { IRoute } from "../../shared/models/route.model";
import OfficePlatformConfigsListPage from "../pages/office-platform-configs/OfficePlatformConfigsListPage";
import CreateEditOfficePage from "../pages/offices/CreateEditOfficePage";
import OfficesDataTable from "../pages/offices/OfficesTablePage";

export const ROUTE_OFFICES_DECLARATIONS = Object.freeze({
  AdminRetailOfficesPage: "offices",
  AdminRetailOfficesNewPage: "offices/new",
  AdminRetailOfficesEditPage: "offices/:id/edit",
  AdminRetailOfficesPlatformConfigs: "offices/:id/platform-configs",
});

export const OfficesRoutes: IRoute[] = [
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesPage,
    component: OfficesDataTable,
  },
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesNewPage,
    component: CreateEditOfficePage,
  },
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesEditPage,
    component: CreateEditOfficePage,
  },
  {
    path: ROUTE_OFFICES_DECLARATIONS.AdminRetailOfficesPlatformConfigs,
    component: OfficePlatformConfigsListPage,
  },
];
