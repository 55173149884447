import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { ILinkToUploadMedia } from '@/shared/models/media-library/link-to-upload-media.model';
import axiosInstance from '@/shared/services/api/axios.api';
import { getValueFromLs } from '@/shared/services/localstorage';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import { Box, CircularProgress, IconButton } from '@mui/material';
import axios from 'axios';
import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { IMediaUploaderProps } from '../MediaUploader';
import { useAppSelector } from '@/store/hooks/redux';
import { UploadFileRounded } from '@mui/icons-material';

const MAX_SIZE_MB = 32;
const MAX_DURATION_IN_SECONDS = 120;

const VideosUploader: React.FC<IMediaUploaderProps> = ({ refetchAction }): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [percentageUploading, setPercentageUploading] = useState(0);

  const propertyIdFromStore = useAppSelector((state) => state.propertyInfoSliceReducer.propertyInternalId);
  const propertyIdFromLS = getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID);

  const handleInputClick = () => {
    inputRef?.current?.click();
  };

  const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | null | undefined = event?.target?.files?.[0];
    if (!file) return;

    await checkVideo(file);
    await uploadFile(file);
  };

  async function uploadFile(file: File): Promise<void> {
    let propertyId = propertyIdFromStore ? propertyIdFromStore : propertyIdFromLS;

    const extension = file.name.split('.').pop()?.toLowerCase();
    if (!extension) return;
    if (!propertyId) return;
    setUploadLoading(true);

    const link = (
      await axiosInstance.post<ILinkToUploadMedia>('medias/videos', {
        propertyId,
        extension,
        fileName: file.name,
      })
    ).data;

    console.log(link);

    const config = {
      onUploadProgress: function (progressEvent: any) {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setPercentageUploading(percentCompleted);
      },
    };

    await axios.put(link.linkToUpload, file, config);
    setUploadLoading(false);
    setPercentageUploading(0);
    refetchAction();
  }

  const checkVideo = async (file: File) => {
    if (!file) return;

    const fileSizeInMB = file.size / 1024 / 1024;
    if (fileSizeInMB > MAX_SIZE_MB) {
      toast.error(`File size is ${fileSizeInMB.toFixed(0)} MB`);
      return;
    }

    try {
      const { duration } = await mockVideoFile(file);
      const flooredDuration = Math.floor(duration);
      if (flooredDuration > MAX_DURATION_IN_SECONDS) {
        toast.error(
          `Duration of video is ${flooredDuration} seconds. Max duration is ${MAX_DURATION_IN_SECONDS} seconds`
        );
      }
    } catch (e: any) {
      toast.error(e?.message ?? 'Something happened. Try again later');
    }
  };

  const mockVideoFile = async (file: File): Promise<HTMLVideoElement> => {
    return new Promise((resolve, reject) => {
      try {
        let video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadeddata = function () {
          resolve(video);
        };
        video.onerror = function () {
          reject({ message: 'Invalid video file' });
        };

        video.src = URL.createObjectURL(file);
      } catch (e: any) {
        reject(e);
      }
    });
  };

  return (
    <div>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IconButton disableRipple disableTouchRipple disableFocusRipple onClick={handleInputClick}>
          {!uploadLoading ? (
            <UploadTwoToneIcon color="secondary" fontSize="medium" />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <CircularProgress
                variant="determinate"
                value={percentageUploading}
                size={30}
                sx={{
                  color: '#000',
                  position: 'absolute',
                  top: -4,
                  left: -5,
                  zIndex: 1,
                }}
              />
              <UploadFileRounded color="secondary" />
            </Box>
          )}
        </IconButton>
      </Box>
      <input type="file" accept="video/mp4" hidden ref={inputRef} onInput={handleFileSelected}></input>
    </div>
  );
};

export default VideosUploader;
