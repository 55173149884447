import { IBasePaginatedModel } from '../../../shared/models/base.paginated.model';
import { IBudgetModel } from '../../../shared/models/budgets/budget.model';
import { IPaginatedQueryParams } from '../../../shared/models/paginated-query-params';
import { TypeIdsDefinitions, api } from '../../../shared/services/api/api';
import providesList from '../../../shared/services/helpers/cache.helper';
import { IBudgetCreateEditFormValues } from '../../pages/budgets/components/CreateEditBudgetForm';
import { IProviderCreateEditFormValues } from '../../pages/retail-providers/components/CreteEditProviderForm';

const baseUrl = 'admin/budgets';

interface ICompanyBudgetsPaginatedQueryParams {
  companyId: string;
  queryParams: IPaginatedQueryParams;
}

export const budgetsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBudgetsByCompanyIdAdmin: build.query<IBasePaginatedModel<IBudgetModel>, ICompanyBudgetsPaginatedQueryParams>({
      query: ({ companyId, queryParams: {page, pageSize, search} }) => ({
        url: `admin/companies/${companyId}/budgets`,
        params: {page, pageSize, search},
      }),
      providesTags: (result) =>
        providesList(
          result?.items ?? [],
          'AdminBudgets',
          `${result?.items && result?.items.length > 0 ? result?.items[0].companyId : ''}${
            TypeIdsDefinitions.partialListTypeId
          }`
        ),
    }),
    addBudget: build.mutation<IBudgetModel, IBudgetCreateEditFormValues>({
      query: (values: IBudgetCreateEditFormValues) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: values,
      }),
      invalidatesTags: ['AdminBudgets'],
    }),
    getBudgetById: build.query<IBudgetModel, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
      }),
      providesTags: (result, error, arg) => [{ type: 'AdminBudgets', id: arg.id }],
    }),
    editBudget: build.mutation<IBudgetModel, { values: IProviderCreateEditFormValues; id: string }>({
      query: ({ values, id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: ['AdminBudgets'],
    }),
    deleteBudget: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AdminBudgets'],
    }),
  }),
});

export const {
  useGetBudgetsByCompanyIdAdminQuery,
  useAddBudgetMutation,
  useEditBudgetMutation,
  useGetBudgetByIdQuery,
  useDeleteBudgetMutation,
} = budgetsApi;
