import { FunctionComponent, useState } from 'react';
import { useFormik } from 'formik';
import CommonTextField from '../../../../shared/components/CommonTextField';
import * as yup from 'yup';
import CommonButton from '../../../../shared/components/CommonButton';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import { toast } from 'react-hot-toast';
import { IOfficeMetaConfigModel } from '../../../../shared/models/offices/office-meta-config.model';
import {
  useCreateOfficeMetaConfigMutation,
  useUpdateOfficeMetaConfigMutation,
} from '../../../services/http/offices-platform-configs.api';
import GenerateShadowInstagram from '@/admin/shared/components/modals/GenerateShadowInstagram';
import ShareTwoToneIcon from '@mui/icons-material/ShareTwoTone';

export interface IOfficeMetaConfigFormProps {
  initialValues?: IOfficeMetaConfigModel | null;
  officeId: string;
}

export interface IOfficeMetaConfigFormValues {
  facebookPageId: string;
  instagramActorId: string;
}

const OfficeMetaConfigForm: FunctionComponent<IOfficeMetaConfigFormProps> = (
  props: IOfficeMetaConfigFormProps
): JSX.Element => {
  const { initialValues, officeId } = props;
  const [instDialogOpened, setInstDialogOpened] = useState<boolean>(false);

  const [createMetaConfig, { isLoading: metaCreationLoading }] = useCreateOfficeMetaConfigMutation();
  const [updateMetaConfig, { isLoading: metaUpdateLoading }] = useUpdateOfficeMetaConfigMutation();

  const { getFieldProps, touched, errors, handleSubmit, handleChange, getFieldMeta } = useFormik({
    initialValues: {
      facebookPageId: initialValues?.facebookPageId || '',
      instagramActorId: initialValues?.instagramActorId || '',
    },
    validationSchema: yup.object().shape({
      facebookPageId: yup.string().required('Facebook page id is required'),
      instagramActorId: yup.string().required('Instagram actor id is required'),
    }),
    onSubmit: async (values: IOfficeMetaConfigFormValues) => {
      !initialValues?.id
        ? await createMetaConfig({ values, officeId }).unwrap()
        : await updateMetaConfig({ values, id: initialValues.id }).unwrap();

      toast.success(initialValues ? 'Updated' : 'Created');
    },
  });

  return (
    <div>
      <Box>
        <Box
          sx={{
            width: 100,
            mb: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <FacebookIcon fontSize="large" color="secondary" />
          <Typography variant="h5" color="secondary" sx={{ ml: 2 }}>
            Meta
          </Typography>
        </Box>
        <form noValidate onSubmit={(e) => handleSubmit(e)}>
          <CommonTextField
            sx={{ mt: '15px' }}
            type="text"
            {...getFieldProps('facebookPageId')}
            label="Facebook Page Id"
            onChange={handleChange}
            required
            error={Boolean(touched.facebookPageId && errors.facebookPageId)}
            helperText={touched.facebookPageId && (errors?.facebookPageId as string)}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CommonTextField
              sx={{ mt: '15px' }}
              type="text"
              {...getFieldProps('instagramActorId')}
              value={getFieldMeta('instagramActorId').value}
              label="Instagram Actor Id"
              required
              error={Boolean(touched.instagramActorId && errors.instagramActorId)}
              helperText={touched.instagramActorId && (errors?.instagramActorId as string)}
            />
            <Tooltip placement="top" title="Generate shadow instagram id">
              <IconButton onClick={() => setInstDialogOpened(true)}>
                <ShareTwoToneIcon></ShareTwoToneIcon>
              </IconButton>
            </Tooltip>
          </Box>
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mt: 2,
              }}>
              <CommonButton type="submit" disabled={metaCreationLoading || metaUpdateLoading}>
                {initialValues ? 'Update' : 'Create'}
              </CommonButton>
            </Grid>
          </Grid>
        </form>

        {instDialogOpened && <GenerateShadowInstagram type="office" closeFn={() => setInstDialogOpened(false)} />}
      </Box>
    </div>
  );
};

export default OfficeMetaConfigForm;
