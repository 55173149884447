import { IRoute } from '@/shared/models/route.model';
import AddEditAutoCreationConfigPage from '../pages/companies-auto-creation-configs/AddEditAutoCreationConfigPage';
import CompanyAutoCreationConfigsListPage from '../pages/companies-auto-creation-configs/CompanyAutoCreationConfigsListPage';
import PlatformConfigsListPage from '../pages/companies-platform-configs/PlatformConfigsListPage';
import CompaniesDataTable from '../pages/companies/CompaniesTablePage';
import CreateEditCompanyPage from '../pages/companies/CreateEditCompanyPage';

export const ROUTE_COMPANIES_DECLARATIONS = Object.freeze({
  AdminRetailCompaniesPage: 'companies',
  AdminRetailCompaniesNewPage: 'companies/new',
  AdminRetailCompaniesEditPage: 'companies/:id/edit',
  AdminRetailCompaniesPlatformsConfigs: 'companies/:id/platform-configs',
  AdminRetailCompaniesAutoCreationConfigs: 'companies/:id/auto-creation',
  AdminRetailCompaniesAutoCreationConfigsNew: 'companies/:id/auto-creation/new',
  AdminRetailCompaniesAutoCreationConfigsEdit: 'companies/:id/auto-creation/:autoCreationId/edit',
});

export const CompaniesRoutes: IRoute[] = [
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesPage,
    component: CompaniesDataTable,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesNewPage,
    component: CreateEditCompanyPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesEditPage,
    component: CreateEditCompanyPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesPlatformsConfigs,
    component: PlatformConfigsListPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesAutoCreationConfigs,
    component: CompanyAutoCreationConfigsListPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesAutoCreationConfigsNew,
    component: AddEditAutoCreationConfigPage,
  },
  {
    path: ROUTE_COMPANIES_DECLARATIONS.AdminRetailCompaniesAutoCreationConfigsEdit,
    component: AddEditAutoCreationConfigPage,
  },
];
