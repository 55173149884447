import { IAuthResponse } from '@/auth/models/auth.response';
import { api } from '@/shared/services/api/api';

export interface IConnectUserRequest {
  companyExternalId: string;
  propertyId: string;
  orderId: string;
  provider: string;
}

export const connectionsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    connect: builder.mutation<IAuthResponse, IConnectUserRequest>({
      query: (data) => ({
        url: 'connect-users-from-retail-data',
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useConnectMutation } = connectionsApi;
