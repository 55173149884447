import { api } from '../../../shared/services/api/api';
import { ICampaignModel } from '../../../shared/models/campaign.model';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IAdSettingsFormValues } from '../../shared/components/forms/ad-settings/AdSettingsForm';

const baseUrl = 'campaigns';

export type CampaignValuesType = Pick<IAdSettingsFormValues, 'title'>;

export const customerCampaignsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createCampaign: build.mutation<ICampaignModel, { platform: string; values: CampaignValuesType }>({
      query: ({ values, platform }) => ({
        url: `${baseUrl}`,
        method: 'POST',
        body: {
          platform,
          title: values.title,
        },
      }),
      invalidatesTags: ['CustomerCampaigns'],
    }),
    createCampaigns: build.mutation<ICampaignModel[], { platforms: string[]; values: CampaignValuesType[] }>({
      queryFn: async (_arg, _queryApi, _extraOptions, _fetchWithBQ) => {
        const tasks = [];
        for (let i = 0; i < _arg.platforms.length; i++) {
          const platform = _arg.platforms[i];
          const values = _arg.values[i];
          tasks.push(
            _fetchWithBQ({
              url: `${baseUrl}`,
              method: 'POST',
              body: {
                platform,
                title: values.title,
              },
            })
          );
        }
        const results = await Promise.all(tasks);
        const successes: ICampaignModel[] = results
          .filter((result) => result.data)
          .map((result) => result.data as ICampaignModel);
        const errors: FetchBaseQueryError[] = results
          .filter((result) => result.error != null)
          .map((result) => result.error as FetchBaseQueryError);

        if (errors.length > 0) return { error: errors[0] };

        return { data: successes };
      },
      invalidatesTags: ['CustomerCampaigns'],
    }),
  }),
});

export const { useCreateCampaignMutation, useCreateCampaignsMutation } = customerCampaignsApi;
